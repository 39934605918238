import React from 'react';
import { Button, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';

const SurveyControls = ({ checkedCount, submitSurvey }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'center',
        position: 'sticky',
        top: 20,
        bottom: 0,
        width: '100%',
        backgroundColor: '#a2d4f0',
        px: 12,
        py: 1,
        mb: 3.2,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            pr: 4,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={(checkedCount / 8) * 100}
            sx={{ mr: 5, width: '100%' }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pr: 4,
              minWidth: 300,
            }}
          >
            {checkedCount}/8 Compentencies Selected
          </Typography>
        </Box>
      </Box>
      <Button
        sx={{ fontSize: '0.75rem' }}
        variant="contained"
        onClick={submitSurvey}
      >
        Submit
      </Button>
    </Box>
  );
};

export default SurveyControls;
