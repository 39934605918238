import { Box } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import BasicControls from '../../../../Components/BasicControls';
import BasicSelect from '../../../../Components/Inputs/BasicSelect';
import BasicTextarea from '../../../../Components/Inputs/BasicTextarea';
import BasicTextInput from '../../../../Components/Inputs/BasicTextInput';
import { useHistory } from 'react-router-dom';

const ticketTypeOptions = [
  { value: 'issue', displayText: 'Report an issue' },
  { value: 'feature', displayText: 'Request a feature' },
  { value: 'question', displayText: 'Question' },
];

const priorityOptions = [
  { value: 'low', displayText: 'Low' },
  { value: 'medium', displayText: 'Medium' },
  { value: 'high', displayText: 'High' },
];

const CreateTicket = () => {
  const [ticket, setTicket] = React.useState({
    type: ticketTypeOptions[0].value,
    priority: priorityOptions[0].value,
    status: 'pending',
    description: '',
    title: '',
  });
  const [responseMessage, setResponseMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [responseSeverity, setResponseSeverity] = React.useState('success');
  const [responseStatus, setResponseStatus] = React.useState([-1]);
  const history = useHistory();

  async function postTicket(url = '/', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    setResponseStatus([response.status]);

    return response.json();
  }

  useEffect(() => {
    if (responseStatus[0] === -1) return;

    if (responseStatus[0] !== 200) {
      setResponseMessage('Failed to submit ticket');
      setResponseSeverity('error');
    } else {
      setResponseMessage('Ticket created successfully');
      setResponseSeverity('success');
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }

    setSnackbarOpen(true);
  }, [responseStatus]);

  return (
    <Box sx={{ width: '35%' }}>
      <BasicTextInput
        title={'Ticket Subject Name'}
        placeholder={'Enter Subject Name'}
        value={ticket.title}
        onChange={e => setTicket({ ...ticket, title: e.target.value })}
      />
      <BasicSelect
        options={ticketTypeOptions}
        title="Ticket Type"
        value={ticket.type}
        onChange={e => setTicket({ ...ticket, type: e.target.value })}
      />
      <BasicTextarea
        title={'Description of issue'}
        placeholder={'Enter Description'}
        value={ticket.description}
        textLimit={500}
        onChange={value => setTicket({ ...ticket, description: value })}
      />
      <BasicSelect
        options={priorityOptions}
        title="Priority"
        value={ticket.priority}
        onChange={e => setTicket({ ...ticket, priority: e.target.value })}
      />
      <BasicControls
        onCancel={() => {
          history.push('/support/');
        }}
        onClick={async () => {
          if (!ticket.title || !ticket.description) {
            setResponseMessage(
              'Failed to submit ticket, title and description cannot be empty'
            );
            setResponseSeverity('error');
            setSnackbarOpen(true);
            return;
          }

          await postTicket('/api/support-ticket', ticket);
        }}
        message={responseMessage}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        severity={responseSeverity}
      />
    </Box>
  );
};

export default CreateTicket;
