import React, { useEffect, useRef } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import '../../../font/reporticons/flaticon.css';
import SubmittedMessage from './SubmittedMessage';
import SurveyControls from './SurveyControls';
import SurveyIndicators from './SurveyIndicators';
import SurveyCompetencies from './SurveyCompetencies';

const SurveyPage = ({ isLoading, status, code, setStatus, setIsLoading }) => {
  const [surveyData, setSurveyData] = useState([]);
  const [surveyForm, setSurveyForm] = useState({});
  const checkedCount = useRef(0);

  useEffect(() => {
    const fetchSurveyData = async () => {
      const response = await fetch('/api/survey/indicators');
      // TO-DO: handle cases where fetching the surveys fail
      const data = await response.json();

      setSurveyData(data);
      data.map(value =>
        setSurveyForm(sf => ({ ...sf, [value.indicator_id]: false }))
      );
    };

    fetchSurveyData();
  }, []);

  const submitSurvey = async () => {
    if (checkedCount.current > 8) {
      return;
    }

    const indicators = Object.keys(surveyForm)
      .filter(key => surveyForm[key])
      .map(value => +value);

    if (indicators.length === 0) {
      // TO-DO: handle empty request
      return;
    }

    setIsLoading(true);

    const response = await fetch(`/api/survey/${code}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ indicators }),
    });

    await response.json();

    setStatus('submitted');
    setIsLoading(false);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (status === 'invalid') {
    return (
      <>
        <Typography variant="h3">Error 404</Typography>
        <Typography variant="h6">
          Sorry the page that you requested couldn't be found.
        </Typography>
      </>
    );
  }

  if (status === 'submitted') {
    return <SubmittedMessage />;
  }

  if (status === 'invited') {
    return (
      <>
        <Box
          sx={{
            width: '100%',
            backgroundColor: '#a2d4f0',
            px: 15,
            py: 2,
            mb: 3.2,
          }}
        >
          <Typography variant="h4" sx={{ pl: 1, fontWeight: 700 }}>
            Entry-Level Competency Filter Selection
          </Typography>
        </Box>
        <Box sx={{ px: 15, py: 2, mb: 3.2, maxWidth: 1200 }}>
          <Typography
            variant="subtitle1"
            sx={{ pl: 1, fontWeight: 500, textAlign: 'justify' }}
          >
            Think about the competencies of successful entry-level associates at
            your firm. Just as importantly, think about the competencies that
            they don’t have. Try to include a diverse group of high-performing
            associates in your analysis. Carefully review the definition for
            each competency. Even if a competency sounds familiar, our framework
            is unique, and the way we define it may be different than what you
            might expect.
            <br />
            <br />
            Please select 8 of the 22 competencies below. Once you have selected
            8 competencies, please click submit.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'sticky',
            top: 0,
            background: '#f8fcff',
            zIndex: 2,
          }}
        >
          <SurveyIndicators surveyForm={surveyForm} surveyData={surveyData} />
        </Box>
        <Box sx={{ position: 'sticky', top: 0 }}>
          <SurveyCompetencies
            setSurveyForm={setSurveyForm}
            checkedCount={checkedCount}
            surveyData={surveyData}
            surveyForm={surveyForm}
          />
        </Box>
        <SurveyControls
          checkedCount={checkedCount.current}
          submitSurvey={submitSurvey}
        />
      </>
    );
  }

  return null;
};

export default SurveyPage;
