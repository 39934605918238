import React from 'react';
import _ from 'lodash';

const DimensionPanes = props => {
  // sort alphabetically
  const dimensionsSorted = _.orderBy(props.dimensions, 'name');

  const dimensions = dimensionsSorted.map((dimension, i) => {
    // sort competencies alphabetically
    const competenciesSorted = _.orderBy(dimension.children, 'indicatorName');
    const competencyElements = competenciesSorted.map((competency, j) => {
      return <li key={j + 1}>{competency.indicatorName}</li>;
    });
    const bgColorClass = 'bg-' + dimension.color;
    return (
      <div className={'pane ' + bgColorClass} key={i + 1}>
        <div className="header">
          <h5>{dimension.name}</h5>
        </div>
        <div className="list">
          <ul>{competencyElements}</ul>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="text-block">
        <h4>The Modern Lawyer Competency Framework</h4>
        <p>
          The Modern Lawyer Competency Framework consists of seven dimensions.
          Within the dimensions are 22 competencies that are organized based on
          types of behaviors. These competencies are indicative of behaviors
          exhibited by high performing lawyers.
        </p>
      </div>
      <div className="dimension-panes">{dimensions}</div>
    </>
  );
};

export default DimensionPanes;
