import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#f8fcff',
  lineHeight: '60px',
  padding: 40,
}));

const ElevatedPaper = ({ children }) => {
  return <Item elevation={2}>{children}</Item>;
};

export default ElevatedPaper;
