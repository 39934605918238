import { combineReducers } from 'redux';
import userReducer from './user';
import firmReducer from './firm';
import snackbarReducer from './snackbar';

const rootReducer = combineReducers({
  user: userReducer,
  firm: firmReducer,
  snackbar: snackbarReducer,
});

export default rootReducer;
