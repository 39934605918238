import { Alert, Snackbar } from '@mui/material';
import React from 'react';

const BasicSnackbar = ({
  message,
  snackbarOpen,
  setSnackbarOpen,
  severity,
}) => {
  const handleClose = (event, reason) => {
    setSnackbarOpen(false);
  };

  return (
    <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default BasicSnackbar;
