import { range } from 'lodash';
import React, { Component, Fragment } from 'react';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

class Paginator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };

    this.pageLimit = props.pageLimit; // ? props.pageLimit :  Math.ceil(props.items / props.pageSize);
    this.pageNumbers = range(0, this.pageLimit);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.forceUpdate();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { currentPage, nextText, isSubmitting = false } = this.props;
    let pages = this.pageNumbers.map((num, i) => {
      if (i > this.props.currentPage + 5 || i < this.props.currentPage - 5) {
        return null;
      } else {
        return (
          <li
            className={
              i === this.props.currentPage
                ? 'paginator-item current'
                : 'paginator-item'
            }
            key={i}
          >
            <a
              href="#"
              className="paginator-link"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.onChangePage(i);
              }}
            >
              {num + 1}
            </a>
          </li>
        );
      }
    });

    let prev = () => {
      return (
        <li className="paginator-item">
          <a
            href="#"
            className="paginator-link"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.props.onChangePage(this.props.currentPage - 1);
            }}
          >
            Previous
          </a>
        </li>
      );
    };

    //pages.shift(prev);

    return (
      <Fragment>
        <nav className="paginator-contain">
          <ul className="paginator">
            {this.props.currentPage > 0 ? (
              <li className="paginator-item" key={-1}>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.onChangePage(currentPage - 1);
                  }}
                  className="paginator-link"
                >
                  Previous
                </a>
              </li>
            ) : null}

            {pages}

            {currentPage < this.pageLimit && nextText ? (
              <li className="paginator-item" key={currentPage}>
                <a
                  className="paginator-link"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.onChangePage(currentPage + 1);
                  }}
                >
                  Next
                </a>
              </li>
            ) : null}
          </ul>
          {!nextText && (
            <LoadingButton
              sx={{ float: 'right' }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.props.onChangePage(currentPage + 1);
              }}
              loading={isSubmitting}
              // loadingPosition="start"
              // loadingIndicator="Loading…"
              variant="contained"
            >
              Submit
            </LoadingButton>
          )}
        </nav>
      </Fragment>
    );
  }
}

export default Paginator;
