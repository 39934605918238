import { Assigned } from '../types';
import PlusIcon from '../icons/PlusIcon.svg';

export function Page04_TableOfContents() {
  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>
            <span className="title-bar"></span>
            What is Included in the Report
          </h4>
          <div className="table-of-contents">
            <div className="toc-row">
              <img className="col-left" src={PlusIcon} alt="Plus Icon" />
              <div className="col-right">
                <div className="toc-row-title">
                  <h5>Dimension Overview</h5>
                  <h5>5</h5>
                </div>
                <p>
                  The dimension overview presents a snapshot of your overall
                  scores on each of the seven dimensions of the Modern Lawyer
                  Competency Framework&trade;, providing you with insight into
                  the dimensions you may leverage the most and least.
                </p>
              </div>
            </div>

            <div className="toc-row">
              <img className="col-left" src={PlusIcon} alt="Plus Icon" />
              <div className="col-right">
                <div className="toc-row-title">
                  <h5>Competency Overview</h5>
                  <h5>8</h5>
                </div>
                <p>
                  The competency overview presents your scores on each of the 22
                  competencies in the Modern Lawyer Competency Framework&trade;,
                  organized by dimension. This overview contextualizes your
                  overall dimension scores, providing further insight into your
                  behaviors.
                </p>
              </div>
            </div>

            <div className="toc-row">
              <img className="col-left" src={PlusIcon} alt="Plus Icon" />
              <div className="col-right">
                <div className="toc-row-title">
                  <h5>Key Competencies</h5>
                  <h5>9</h5>
                </div>
                <p>
                  Key competencies highlight the three competencies you embody
                  the most, and the three competencies you embody the least.
                </p>
              </div>
            </div>

            <div className="toc-row">
              <img className="col-left" src={PlusIcon} alt="Plus Icon" />
              <div className="col-right">
                <div className="toc-row-title">
                  <h5>Competency Details</h5>
                  <h5>10</h5>
                </div>
                <p>
                  The competency details section provides a deep dive into how
                  you scored on each competency, including your scores on
                  related sub-competencies. It also contains reflection
                  questions to support introspection and development.
                </p>
              </div>
            </div>

            <div className="toc-row">
              <img className="col-left" src={PlusIcon} alt="Plus Icon" />
              <div className="col-right">
                <div className="toc-row-title">
                  <h5>Building on Results</h5>
                  <h5>32</h5>
                </div>
                <p>
                  Finally, the section on building on results provides prompts
                  and space to support how you plan to leverage and move forward
                  based on your unique competency profile.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
