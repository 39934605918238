/**
 * Parses a CSV string and converts it to an array of objects.
 *
 * @param csv - The CSV string to parse
 * @returns An array of objects, where each object represents a row in the CSV
 */
export function parseCSV(csv: string): object[] {
  // Split the input string into lines by newlines
  const lines = csv.split(/\r?\n/).filter(line => line.trim() !== '');

  // Extract the header row and split into columns
  const headers = parseLine(lines.shift() as string);

  // Process each row and convert to an object
  const result = lines.map(line => {
    const values = parseLine(line);
    return headers.reduce(
      (obj, header, index) => {
        obj[header] = values[index];
        return obj;
      },
      {} as Record<string, string>
    );
  });

  return result;
}

/**
 * Parses a single CSV line, handling escaped values.
 *
 * @param line - The CSV line to parse
 * @returns An array of values for the line
 */
function parseLine(line: string): string[] {
  const result: string[] = [];
  let current = '';
  let insideQuotes = false;

  for (let i = 0; i < line.length; i++) {
    const char = line[i];

    if (char === '"') {
      // If we are inside quotes and see a double quote
      if (insideQuotes && line[i + 1] === '"') {
        current += '"'; // Escaped double quote
        i++; // Skip the next double quote
      } else {
        insideQuotes = !insideQuotes; // Toggle the insideQuotes state
      }
    } else if (char === ',' && !insideQuotes) {
      // If we hit a comma and we're not inside quotes, push the value
      result.push(current);
      current = '';
    } else {
      current += char;
    }
  }

  // Push the last value
  result.push(current);

  return result;
}
