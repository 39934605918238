import { DimensionCategories } from './constants';

import { Competency, Dimension } from './types';

export function getDimensionByCompetency(
  competencyName: string
): Dimension | undefined {
  return DimensionCategories.flatMap(c => c.dimensions).find(d =>
    d.competencies.some(c => c.name === competencyName)
  );
}

export function getCompetency(
  dimension: Dimension,
  competencyName: string
): Competency | undefined {
  return dimension.competencies.find(c => c.name === competencyName);
}

/**
 * override certain dimension names for display purposes
 * @param rawName
 */
export function displayDimensionName(rawName: string): string {
  switch (rawName) {
    case 'Relationships + Teamwork':
      return 'Relationships and Teamwork';
    case 'Self Management':
      return 'Self-Management';

    default:
      return rawName;
  }
}

/**
 * override certain competency names for display purposes
 * @param competency
 */
export function displayCompetencyName(rawName: string): string {
  switch (rawName) {
    case 'Reputation Focused':
      return 'Reputation-Focused';
    case 'Growth Oriented':
      return 'Growth-Oriented';
    case 'Client Focused':
      return 'Client-Focused';

    default:
      return rawName;
  }
}
