export function Execution({ width = 32, height = 32, x = 0, y = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="15.5" fill="#F3F6F9" stroke="#BFD5D9" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.9565 8.42871C22.9565 7.61606 22.334 6.95651 21.5652 6.95651H10.4348C9.66597 6.95651 9.04346 7.61606 9.04346 8.42871V23.5713C9.04346 24.3839 9.66597 25.0435 10.4348 25.0435H21.5652C22.334 25.0435 22.9565 24.3839 22.9565 23.5713V8.42871ZM11.5899 21.1005L12.4844 22.11C12.6044 22.2463 12.7761 22.3186 12.9518 22.3086C13.1275 22.2985 13.2905 22.2059 13.3963 22.057L14.8869 19.9539C15.0841 19.6754 15.0308 19.2792 14.7677 19.0706C14.5045 18.8619 14.1301 18.9183 13.9329 19.1968L12.8684 20.6984L12.4597 20.2374C12.2347 19.9833 11.8571 19.9707 11.617 20.2088C11.3769 20.4469 11.3649 20.8465 11.5899 21.1005ZM16.795 21.2578H19.9751C20.3043 21.2578 20.5714 20.9752 20.5714 20.6269C20.5714 20.2786 20.3043 19.9959 19.9751 19.9959H16.795C16.4659 19.9959 16.1987 20.2786 16.1987 20.6269C16.1987 20.9752 16.4659 21.2578 16.795 21.2578ZM11.5899 16.4736L12.4844 17.4831C12.6044 17.6194 12.7761 17.6918 12.9518 17.6817C13.1275 17.6716 13.2905 17.579 13.3963 17.4301L14.8869 15.327C15.0841 15.0485 15.0308 14.6523 14.7677 14.4437C14.5045 14.235 14.1301 14.2914 13.9329 14.5699L12.8684 16.0715L12.4597 15.6105C12.2347 15.3564 11.8571 15.3438 11.617 15.5819C11.3769 15.82 11.3649 16.2196 11.5899 16.4736ZM16.795 16.6309H19.9751C20.3043 16.6309 20.5714 16.3483 20.5714 16C20.5714 15.6517 20.3043 15.3691 19.9751 15.3691H16.795C16.4659 15.3691 16.1987 15.6517 16.1987 16C16.1987 16.3483 16.4659 16.6309 16.795 16.6309ZM11.5899 11.8467L12.4844 12.8562C12.6044 12.9925 12.7761 13.0649 12.9518 13.0548C13.1275 13.0447 13.2905 12.9521 13.3963 12.8032L14.8869 10.7001C15.0841 10.4216 15.0308 10.0254 14.7677 9.81678C14.5045 9.60814 14.1301 9.66451 13.9329 9.94296L12.8684 11.4446L12.4597 10.9836C12.2347 10.7295 11.8571 10.7169 11.617 10.955C11.3769 11.1931 11.3649 11.5927 11.5899 11.8467ZM16.795 12.004H19.9751C20.3043 12.004 20.5714 11.7214 20.5714 11.3731C20.5714 11.0248 20.3043 10.7422 19.9751 10.7422H16.795C16.4659 10.7422 16.1987 11.0248 16.1987 11.3731C16.1987 11.7214 16.4659 12.004 16.795 12.004Z"
        fill="#00629B"
      />
    </svg>
  );
}
