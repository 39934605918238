import { getFirm } from '../../services/firm';

export const fetchFirmSession = () => async dispatch => {
  console.log('[REDUX action called] [fetchFirmSession]');
  try {
    const firm = await getFirm();
    dispatch({
      type: 'SET_FIRM_SESSION',
      payload: { firm },
    });
  } catch (error) {
    console.log('No firm association for candidate accounts.');
  }
};

export const setFirmSession = firm => async dispatch => {
  console.log('[REDUX action called] [setFirmSession]');
  dispatch({
    type: 'SET_FIRM_SESSION',
    payload: { firm },
  });
};
