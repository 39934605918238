import './styles.scss';

export function Page32_BuildingOnResults() {
  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>
            <span className="title-bar"></span>
            Building on Results
          </h4>

          <div className={'building-on-results'}>
            <p>
              The space below can be used to note ideas, reflections, and goals
              that surfaced while looking at, or debriefing, your report. We
              have included a few sample questions below to guide you in this
              process.
            </p>

            <h6>Are there competencies you plan to leverage? How?</h6>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>

            <h6>
              Are there any areas or competencies you identified where you want
              to enhance your skills or develop new or different approaches?
            </h6>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>

            <h6>
              What support might you need to enable you to accomplish your
              goals?
            </h6>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>
            <div className={'horizontal-line'}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
