import React, { Component, createRef, useEffect } from 'react';
import _ from 'lodash';
import request from '../../RequestUtil.js';
import { styled } from '@mui/material/styles';
import Loader from '../../Components/Loader';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import ContentCopy from '@mui/icons-material/ContentCopy';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dropzone from 'react-dropzone';
import {
  faCloudUploadAlt,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs, Typography } from '@mui/material';
import BasicSnackbar from '../../Components/Utilities/BasicSnackbar';
import { getBaseUrl } from '../../config';
import TabPanel from '../../Components/Tabpanel.js';

const dropzoneRef = createRef();
const textAreaRef = createRef();
const BASE_URL = getBaseUrl();

const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

const Input = styled('input')({
  display: 'none',
});

const AcceptedFiles = ({ file, handleFileChange }) => {
  useEffect(() => {
    handleFileChange(file);
  }, [file, handleFileChange]);

  return (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  );
};

const InviteTabs = props => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setIsBulk(newValue === 1);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Add Single Recipient" sx={{ textTransform: 'none' }} />
          <Tab label="Bulk Upload" sx={{ textTransform: 'none' }} />
        </Tabs>
      </Box>

      {React.Children.map(props.children, (child, index) => {
        return (
          <TabPanel value={value} index={index}>
            {child}
          </TabPanel>
        );
      })}
    </>
  );
};
class ClientInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: true,
      isLoading: false,
      jobs: [],
      selectedJob: null,
      isBulk: false,
      to: '',
      file: null,
      error: '',
      anchorEl: null,
      snackBar: {
        show: false,
        msg: 'Invitations have been sent.',
        severity: 'success',
      },
    };

    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = value => {
    this.setState({ anchorEl: null });
  };

  async componentDidMount() {
    try {
      const response = await fetch('/api/jobs');
      const jobs = await response.json();
      this.setState({
        jobs,
        isPageLoading: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleFileChange = file => {
    this.setState({ file, error: null });
  };

  handleAssessmentChange = e => {
    const { jobs } = this.state;
    const jobId = e.target.value;
    this.setState({
      selectedJob: jobs.find(job => job.id == jobId),
      error: null,
    });
  };

  handleRecipientChange = e => {
    this.setState({
      to_addr: e.target.value,
      error: null,
    });
  };

  handleBulkSubmit = () => {
    const { selectedJob, file } = this.state;

    if (!selectedJob) {
      return this.setState({
        error: 'Please select a job search before submitting',
      });
    }

    if (!file) {
      return this.setState({ error: 'Please select a file before submitting' });
    }

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { id, assessmentId } = selectedJob;
        const formData = new FormData();
        formData.append('assessmentId', assessmentId);
        formData.append('jobId', id);
        formData.append('file', file);

        request({
          method: 'post',
          url: '/api/firm/invite/bulk',
          data: formData,
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
          .then(response => {
            this.setState({
              isLoading: false,
              snackBar: {
                show: true,
                msg: 'Invitations have been sent.',
                severity: 'success',
              },
              file: null,
            });
          })
          .catch(error => {
            console.log(error);
            dropzoneRef.current.value = '';
            this.setState({
              isLoading: false,
              snackBar: {
                show: true,
                msg: _.get(
                  error,
                  'response.data.error',
                  'Something went wrong'
                ),
                severity: 'error',
              },
              error: _.get(
                error,
                'response.data.error',
                'Something went wrong'
              ),
            });
          });
      }
    );
  };

  handleSubmit = () => {
    const { selectedJob, to_addr } = this.state;

    if (!selectedJob) {
      return this.setState({
        error: 'Please select a job search before submitting',
      });
    }

    if (!to_addr) {
      return this.setState({ error: 'Please enter at least 1 email' });
    }

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        const { id, assessmentId } = selectedJob;
        request({
          method: 'post',
          url: '/api/firm/invite',
          data: {
            assessmentId,
            jobId: id,
            emails: to_addr.split(','),
          },
          responseType: 'json',
        })
          .then(response => {
            this.setState({
              snackBar: {
                show: true,
                msg: 'Invitations have been sent.',
                severity: 'success',
              },
              isLoading: false,
              to_addr: '',
            });
            textAreaRef.current.value = '';
          })
          .catch(error => {
            this.setState({
              isLoading: false,
              snackBar: {
                show: true,
                msg: _.get(
                  error,
                  'response.data.error',
                  'Something went wrong'
                ),
                severity: 'error',
              },
              error: _.get(
                error,
                'response.data.error',
                'Something went wrong'
              ),
            });
          });
      }
    );
  };

  render() {
    const {
      selectedJob,
      isPageLoading,
      isLoading,
      jobs,
      isBulk,
      file,
      error,
      anchorEl,
      snackBar,
    } = this.state;

    const open = Boolean(anchorEl);
    if (isPageLoading) {
      return <Loader minHeight={'100vh'} />;
    }

    return (
      <div>
        <h1>Invite to Thine</h1>
        <div>
          <div className="form">
            <Typography
              component={'div'}
              variant="subtitle1"
              sx={{ mb: 2, fontFamily: "'Roboto', sans-serif" }}
            >
              Assign Assessment
            </Typography>
            <div className="element">
              <select onChange={this.handleAssessmentChange}>
                <option value="0">Select a Job Search...</option>
                {jobs
                  .filter(job => !job.parentId)
                  .map((row, i) => (
                    <option value={row.id} key={row.id}>
                      {row.title}
                    </option>
                  ))}
              </select>
            </div>

            {selectedJob && (
              <Button
                disabled={!selectedJob.assessmentLink}
                onClick={async () => {
                  try {
                    const { assessmentLink } = selectedJob;
                    await navigator.clipboard.writeText(assessmentLink);
                    console.log('Copied! ', assessmentLink);
                    this.setState({
                      snackBar: {
                        show: true,
                        msg: 'Copied to Clipboard',
                        severity: 'success',
                      },
                    });
                  } catch (err) {
                    console.log('Failed to copy!');
                  }
                }}
                startIcon={<ContentCopy />}
                variant="outlined"
              >
                Copy Invite Link To Clipboard
              </Button>
            )}

            <InviteTabs setIsBulk={isBulk => this.setState({ isBulk })}>
              <TextareaAutosize
                minRows={4}
                maxRows={8}
                name="email_addr"
                aria-label="maximum height"
                placeholder="Recipient(s) - comma-separate emails for multiple"
                onChange={this.handleRecipientChange}
                style={{ width: '100%' }}
                ref={textAreaRef}
              />
              <Dropzone ref={dropzoneRef} noClick noKeyboard>
                {({ getRootProps, getInputProps, acceptedFiles }) => {
                  return (
                    <Box>
                      <Box
                        sx={{
                          border: '3px dashed #eee',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          pt: 5,
                          pb: 5,
                        }}
                        onClick={openDialog}
                      >
                        <Box
                          {...getRootProps({ className: 'dropzone' })}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCloudUploadAlt}
                            size="6x"
                            color="#1976d2 "
                          />
                          <input {...getInputProps()} />
                          <Box
                            sx={{
                              ml: 2,
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            Drag and drop a .CSV or. xlsx file here
                            <Button sx={{ mt: 1 }} variant="outlined">
                              Upload a file
                            </Button>
                          </Box>
                        </Box>
                        {!!acceptedFiles.length && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'left',
                              flexDirection: 'column',
                              mt: 2,
                              width: '317px',
                            }}
                          >
                            <h4>Files</h4>
                            <ul>
                              <AcceptedFiles
                                file={acceptedFiles[0]}
                                handleFileChange={this.handleFileChange}
                              />
                            </ul>
                          </Box>
                        )}
                      </Box>

                      <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={e => this.handleClick(e)}
                      >
                        <span style={{ marginRight: 5 }}>
                          {' '}
                          Download Template{' '}
                        </span>
                        <FontAwesomeIcon
                          icon={faDownload}
                          size="1x"
                          color="#1976d2 "
                        />
                      </Button>
                      <Menu
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => this.handleClose()}
                      >
                        <a
                          href={`${BASE_URL}/api/firm/template/bulk_invite_candidates_csv`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MenuItem
                            sx={{
                              color: 'rgba(0, 0, 0, 0.87)',
                            }}
                            onClick={() => {
                              this.handleClose();
                            }}
                            disableRipple
                          >
                            CSV
                          </MenuItem>
                        </a>
                        <a
                          href={`${BASE_URL}/api/firm/template/bulk_invite_candidates_xlsx`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <MenuItem
                            sx={{
                              color: 'rgba(0, 0, 0, 0.87)',
                            }}
                            onClick={() => {
                              this.handleClose();
                            }}
                            disableRipple
                          >
                            xlsx
                          </MenuItem>
                        </a>
                      </Menu>
                    </Box>
                  );
                }}
              </Dropzone>
            </InviteTabs>

            {error && (
              <BasicSnackbar
                message={error}
                snackbarOpen={Boolean(error)}
                setSnackbarOpen={() => this.setState({ error: '' })}
                severity={'error'}
              />
            )}

            <Box sx={{ ml: '-10px', mt: 2, float: 'right' }}>
              <button
                className="button"
                onClick={isBulk ? this.handleBulkSubmit : this.handleSubmit}
              >
                {isLoading ? (
                  <Grid container justifyContent="right" alignItems="center">
                    <CircularProgress color="info" size={30} />
                    <Box sx={{ ml: 1 }}>Loading ..</Box>
                  </Grid>
                ) : (
                  'Submit'
                )}
              </button>
            </Box>
          </div>
          {snackBar.show && (
            <BasicSnackbar
              message={snackBar.msg}
              snackbarOpen={Boolean(snackBar.show)}
              setSnackbarOpen={() =>
                this.setState({
                  snackBar: {
                    ...snackBar,
                    show: false,
                  },
                })
              }
              severity={snackBar.severity}
            ></BasicSnackbar>
          )}
        </div>
      </div>
    );
  }
}

export default ClientInvite;
