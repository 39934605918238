import React from 'react';
import { useQuery } from 'react-query';
import { ClientCandidateMapping } from './types';
import { ShowCompletedAssessment } from './ShowCompletedAssessment';

type Assessment = {
  assessmentId: number;
  assessmentTitle: string | null;
  assessmentDescription: string | null;
  assessmentScope: number | null;
  assessmentFor: number | null;
  createdDt: Date | null;
  createdBy: string | null;
  assessmentType: number;
  assessmentWeighted: boolean;
  reportId: number;
  templateLvl: number;
  constId: number | null;
  candidateReportId: number;
  assessmentString: string;
  poolId: number | null;
  candidateSurveyLink: string | null;
  version: number;
  createdAt: Date | null;
  updatedAt: Date | null;
};

function getClientCandidateMapping(client_candidate_mapping_id: string) {
  return fetch(
    `/api/admin/debug/client_candidate_mapping/${client_candidate_mapping_id}`,
    {
      headers: {
        accepts: 'application/json',
      },
      method: 'get',
    }
  ).then(res => res.json() as Promise<ClientCandidateMapping>);
}

export function DebugClientCandidateMapping({
  match: {
    params: { client_candidate_mapping_id },
  },
}: any) {
  const query = useQuery({
    queryKey: ['client_candidate_mapping_id', client_candidate_mapping_id],
    queryFn: () => getClientCandidateMapping(client_candidate_mapping_id),
  });

  if (query.isLoading) return <p>Loading...</p>;
  if (!query.data) return <p>Error: {query.error}</p>;

  return <ShowCompletedAssessment data={query.data} />;
}
