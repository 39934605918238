import React, { Component } from 'react';
import request from '../../RequestUtil.js';
// required props:
/*
question_type
question_answers array of objects (up to 5 items)
method to submit (insert answer)
method to 
*/

import Slider from './QuestionTypes/Slider';
import MultiCheck from './QuestionTypes/MultiCheck';

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: null,
      answer_multi: [],
      options: [],
      position: null,
      exists: false,
    };
    this.getOptions = this.getOptions.bind(this);
    this.handleSlide = this.handleSlide.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.selectAnswer = this.selectAnswer.bind(this);
  }

  getOptions() {
    // pull in question data, answers, and existing answer if already answered
    request({
      method: 'post',
      url: '/api/assessments/user/assigned/take/question',
      data: {
        assign_id: this.props.assign_id,
        question_id: this.props.question_id,
      },
    })
      .then(async response => {
        let newState = this.state;

        if (this.props.question_type === 3) {
          // create new options object with an additional key for items marked as selected
          newState.options = response.data.options.map((opt, i, arr) => {
            let isSelected =
              response.data.answer.data.findIndex(
                ans => ans.answer_id === opt.answer_id
              ) !== -1;
            return {
              ...opt,
              selected: isSelected,
            };
          });
          // console.log(newState.options);
          // console.log(response.data.answer.data);
        } else {
          newState.options = response.data.options;
          if (response.data.answer.complete === true) {
            newState.exists = true;

            newState.answer = response.data.answer.data[0].answer_id;
            newState.position =
              response.data.options.findIndex(
                opt => opt.answer_id === response.data.answer.data[0].answer_id
              ) + 1;
          } else {
            newState.exists = false;
          }
        }
        // newState.options = response.data.options;

        // if (response.data.answer.complete === true) {
        //   // TODO: refactor to handle multi-check array of answers as well
        //   newState.answer = response.data.answer.data[0].answer_id;

        //   let position = newState.options.findIndex(
        //     (opt) => opt.answer_id === newState.answer
        //   );

        //   newState.position = position + 1;

        //   newState.exists = true;
        // } else {
        //   newState.exists = false;
        //   newState.position = 3;
        // }
        this.setState(newState);
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.getOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.question_id !== prevProps.question_id) {
      this.getOptions();
    }
    if (this.state.answer !== prevState.answer) {
      console.log(this.state);
      this.getOptions();
    }
  }

  selectAnswer(value, question_type) {
    let answer_req = {
      assign_id: this.props.assign_id,
      question_id: this.props.question_id,
      answer_id: value,
      exists: this.state.exists || false,
    };
    request({
      method: 'post',
      url: '/api/assessments/user/assigned/take/answers',
      data: {
        items: [answer_req],
        doUpdate: false,
      },
    })
      .then(response => {
        this.setState({ answer: value });
      })
      .catch(err => console.log(err));
  }

  handleSlide(e) {
    let { value } = e.target;
    // console.log(value);
    let correspondingOption = this.state.options[value - 1].answer_id;
    this.selectAnswer(correspondingOption, this.props.question_type);
    this.setState({ position: value });
  }

  handleCheck(answer_id) {
    // here
    let opt_index = this.state.options.findIndex(
      opt => opt.answer_id === answer_id
    );
    let selected = this.state.options[opt_index].selected;

    if (selected === true) {
      // run uncheck api call and rerender
      let uncheck_req = {
        assign_id: this.props.assign_id,
        question_id: this.props.question_id,
        answer_id: answer_id,
      };

      request({
        method: 'delete',
        url: '/api/assessments/user/assigned/take/answer',
        data: uncheck_req,
      })
        .then(response => {
          // console.log(response);
          let newOptions = this.state.options;
          newOptions[opt_index].selected = false;
          this.setState({ newOptions });
        })
        .catch(err => console.log(err));
    } else {
      this.selectAnswer(answer_id, this.props.question_type);
    }
  }

  render() {
    let options_list = [];

    if (this.props.question_type === 1) {
      options_list = this.state.options.map((opt, i, arr) => {
        return (
          <tr key={i}>
            <td>
              <button
                className={
                  opt.answer_id === this.state.answer
                    ? 'button green'
                    : 'button'
                }
                onClick={() => {
                  this.selectAnswer(opt.answer_id, this.props.question_type);
                }}
              >
                Select
              </button>
            </td>
            <td>{opt.answer_title}</td>
            <td>
              <em>{opt.answer_description || null}</em>
            </td>
          </tr>
        );
      });
    }

    // if (this.props.question_type === 2) {
    //   console.log("slide");
    // }

    return (
      <div className="question">
        <div className="question-meta">
          <h2>{this.props.title}</h2>
          <p>{this.props.description}</p>
        </div>

        {/*
                handle the (as of right now) three different question types: multiple-choice, sliding scale, and multi-select checklist
            */}

        {/* ONE: multiple choice */}

        {this.props.question_type === 1 ? (
          <div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{options_list}</tbody>
            </table>
          </div>
        ) : null}

        {/* TWO: sliding scale */}

        {this.props.question_type === 2 ? (
          <Slider
            handleSlide={this.handleSlide}
            position={this.state.position}
          />
        ) : // <div className="question-body">
        //   <div className="sliding-scale">
        //     <div className="scale-contain">
        //       <input
        //         type="range"
        //         min="1"
        //         max="5"
        //         defaultValue={this.state.position}
        //         className="scale"
        //         id="scale"
        //         name="scale"
        //         step="1"
        //         onAfterChange={this.handleSlide}
        //       ></input>
        //       {console.log(typeof this.state.position)}
        //     </div>

        //     <div className="scale-labels">
        //       <span>1</span>
        //       <span>2</span>
        //       <span>3</span>
        //       <span>4</span>
        //       <span>5</span>
        //     </div>
        //   </div>
        // </div>
        null}

        {/* THREE: multi-select checklist */}

        {this.props.question_type === 3 ? (
          <div className="question-body">
            <MultiCheck
              items={this.state.options}
              handleCheck={this.handleCheck}
            />
          </div>
        ) : null}

        <div className="question-controls">
          <button onClick={this.props.prevQuestion} className="button">
            Previous
          </button>
          <button onClick={this.props.nextQuestion} className="button">
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default Question;
