import React, { Component } from 'react';
import wlogo from '../wlogo.svg';
import { ReactComponent as Logo } from '../wlogo.svg';

class LoginSplash extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let msg =
      'Click below to log in or sign up. You will be temporarily redirected to Auth0 to connect.';
    return (
      <div className="landing">
        <div className="mobile-hide">
          <div className="top-bg">
            <Logo />
          </div>
          <div className="splash-contain">
            <div className="splash">
              <div className="card-barebones">
                <h1>Log In</h1>
                <p>{msg}</p>
                <p>
                  <em>
                    If this is your first time using the platform, please select
                    "Sign Up" at the next screen to set a password.
                  </em>
                </p>

                <div className="ctr-dist w100">
                  <a className="button" href="login" aria-role="button">
                    Connect
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fw-hide">
          <div className="m-landing">
            <Logo />
            <div className="splash">
              <div className="card-barebones fh ctr-col">
                <h1>Log In</h1>
                <p>{msg}</p>
                <p>
                  <em>
                    If this is your first time using the platform, please select
                    "Sign Up" at the next screen to set a password.
                  </em>
                </p>

                <a className="button" href="login" aria-role="button">
                  Connect
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginSplash;
