import React, { Component, Fragment } from 'react';
import request from '../RequestUtil.js';
import CandidateSteps from '../Components/CandidateSteps';
import lawSchools from '../lawSchools.json';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import MultipleSelectChip from '../Components/Inputs/MultipleSelectChip';
import TextInput from '../Components/Inputs/TextInput';
import Radio from '../Components/Inputs/Radio';
import moment from 'moment';

import BasicSnackbar from '../Components/Utilities/BasicSnackbar';

const questionComponentMap = {
  select: MultipleSelectChip,
  text: TextInput,
  number: TextInput,
  radio: Radio,
  alert: Alert,
  month: TextInput,
};

function Question(props) {
  const {
    name,
    type,
    title,
    required,
    error,
    sdChecked,
    sdValue,
    sdLabel,
    handleChange,
    handleSDChangeCommon,
  } = props;
  const Component = questionComponentMap[type];

  if (!Component) {
    return <div>Question type is not supported</div>;
  }

  const handleChangeLocal = event => {
    const {
      target: { value },
    } = event;

    // On autofill we get a stringified value.
    return handleChange(
      name,
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <Box sx={{ mt: 2, m: 1 }}>{title}</Box>
      <Grid container spacing={2}>
        <Grid item xs={sdChecked == undefined ? 12 : 8}>
          <Component {...props} onChange={handleChangeLocal} />
        </Grid>
        {sdChecked && (
          <Grid item xs={4}>
            <TextInput
              id={name}
              name={name}
              label={sdLabel || 'Other'}
              value={sdValue}
              type="text"
              onChange={handleSDChangeCommon}
              placeholder=""
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

class Demographics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: '',
      errorMessage: '',
      stage: 0,
      questions: {
        intro_section: {
          name: 'intro_section',
          type: 'alert',
          severity: 'info',
          children: (
            <div>
              <p>
                The below questions ask you for information that allows us to
                continuously ensure there is no adverse impact across different
                demographic groups. This information will not be shared with any
                firm you apply to. For each question, please select the response
                that best describes you. If there are any questions you do not
                feel comfortable responding to, please select “prefer not to
                say.”
                <br />
                <br />
                <strong>
                  You will be redirected to the assessment automatically after
                  clicking the "Submit Survey" button.
                </strong>
              </p>
            </div>
          ),
        },
        field_re: {
          name: 'field_re',
          type: 'select',
          multiple: true,
          title: 'Which categories describe you?',
          subTitle: 'Please select all that apply',
          value: [],
          sdChecked: false,
          sdValue: '',
          meta: {
            question: 'race/ethnicity q1',
            type: 'multiselect, sd option',
          },
          options: [
            {
              label: 'White',
              value: 'WH',
            },
            {
              label: 'Black or African American',
              value: 'BA',
            },
            {
              label: 'Latino/a/x',
              value: 'LTX',
            },
            {
              label: 'American Indian or Alaska Native',
              value: 'AIAL',
            },
            {
              label: 'Asian',
              value: 'AS',
            },
            {
              label: 'Native Hawaiian or Pacific Islander',
              value: 'NHPI',
            },
            {
              label: 'Middle Eastern or North African',
              value: 'MENA',
            },
            {
              label: 'Other',
              value: 'SD',
            },
            {
              label: 'Prefer not to say',
              value: 'NDC',
            },
          ],
        },
        field_sg: {
          name: 'field_sg',
          type: 'select',
          multiple: false,
          title: 'Which categories describe you?',
          subTitle: 'Please select one',
          value: [],
          sdChecked: false,
          sdValue: '',
          sdLabel: 'Self Description',
          meta: {
            question: 'sex/gender q2',
            type: 'select, sd option',
          },
          options: [
            {
              label: 'Male',
              value: 'M',
            },
            {
              label: 'Female',
              value: 'F',
            },
            {
              label: 'Nonbinary / Third gender',
              value: 'NB',
            },
            {
              label: 'Prefer to self-describe',
              value: 'SD',
            },
            {
              label: 'Prefer not to say',
              value: 'NDC',
            },
          ],
        },
        // field_or: {
        //   name: "field_or",
        //   type: "select",
        //   multiple: false,
        //   title: "What is your sexual orientation?",
        //   subTitle: "Please select one",
        //   value: [],
        //   sdChecked: false,
        //   sdValue: "",
        //   sdLabel: "Self Description",
        //   meta: {
        //     question: "orientation q3",
        //     type: "select, sd option",
        //   },
        //   options: [
        //     {
        //       label: "Heterosexual or Straight",
        //       value: "HT",
        //     },
        //     {
        //       label: "Gay or Lesbian",
        //       value: "GL",
        //     },
        //     {
        //       label: "Bisexual",
        //       value: "BS",
        //     },
        //     {
        //       label: "Queer",
        //       value: "QU",
        //     },
        //     {
        //       label: "Asexual",
        //       value: "AS",
        //     },
        //     {
        //       label: "Prefer to self-describe",
        //       value: "SD",
        //     },
        //     {
        //       label: "Prefer not to say",
        //       value: "NDC",
        //     },
        //   ],
        // },
        // field_tr: {
        //   name: "field_tr",
        //   type: "select",
        //   multiple: false,
        //   title: "Do you consider yourself to be transgender?",
        //   subTitle: "Please select one",
        //   value: [],
        //   sdChecked: false,
        //   sdValue: "",
        //   meta: {
        //     question: "transgender status q4",
        //     type: "select, sd option",
        //   },
        //   options: [
        //     {
        //       label: "Yes, transgender, male-to-female",
        //       value: "TMTF",
        //     },
        //     {
        //       label: "Yes, transgender, female-to-male",
        //       value: "TFTM",
        //     },
        //     {
        //       label: "Non-binary / third gender",
        //       value: "NB",
        //     },
        //     {
        //       label: "Yes, transgender, gender non-conforming",
        //       value: "GNC",
        //     },
        //     {
        //       label: "No",
        //       value: "CG",
        //     },
        //     {
        //       label: "Prefer not to say",
        //       value: "NDC",
        //     },
        //   ],
        // },
        field_year: {
          name: 'field_year',
          type: 'number',
          inputProps: {
            min: 1900,
            max: 2030,
            step: 1,
          },
          title: 'What year were you born?',
          subTitle: 'Please select one',
          value: '',
          label: 'YYYY',
          meta: {
            question: 'year born q5',
            type: 'numeric',
          },
        },
        field_ld: {
          name: 'field_ld',
          type: 'select',
          multiple: true,
          title: 'Which categories describe you?',
          subTitle: 'Please select all that apply',
          value: [],
          sdChecked: false,
          sdValue: '',
          meta: {
            question: 'learning disability q6',
            type: 'multi select, sd',
          },
          options: [
            {
              label: 'Autism',
              value: 'ASD',
            },
            {
              label: 'ADHD',
              value: 'ADHD',
            },
            {
              label: 'Dyslexia',
              value: 'DSL',
            },
            {
              label: 'Dyspraxia',
              value: 'DSP',
            },
            {
              label: 'Other',
              value: 'SD',
            },
            {
              label: 'Prefer not to say',
              value: 'NDC',
            },
            {
              label: 'N/A',
              value: 'NA',
            },
          ],
        },
        field_ada: {
          name: 'field_ada',
          type: 'select',
          multiple: true,
          title:
            'Do you identify as having a disability as defined under the Americans with Disabilities Act?',
          subTitle: 'Please select all that apply',
          value: [],
          sdChecked: false,
          sdValue: '',
          meta: { question: 'ada q7', type: 'multi select, sd' },
          options: [
            {
              label: 'Yes, Cognitive',
              value: 'COG',
            },
            {
              label: 'Yes, Emotional',
              value: 'EMOT',
            },
            {
              label: 'Yes, Hearing',
              value: 'AUD',
            },
            {
              label: 'Yes, Mental',
              value: 'MENT',
            },
            {
              label: 'Yes, Physical',
              value: 'PHYS',
            },
            {
              label: 'Yes, Visual',
              value: 'VIS',
            },
            {
              label: 'Yes, self-describe',
              value: 'SD',
            },
            {
              label: 'No',
              value: 'NA',
            },
            {
              label: 'Prefer not to say',
              value: 'NDC',
            },
          ],
        },
        // field_marital: {
        //   name: "field_marital",
        //   type: "select",
        //   multiple: false,
        //   title: "Which of the following best describes your marital status?",
        //   subTitle: "Please select one",
        //   value: [],
        //   sdChecked: false,
        //   sdValue: "",
        //   meta: {
        //     question: "marital status q8",
        //     type: "select",
        //   },
        //   options: [
        //     {
        //       label: "Single, never married",
        //       value: "SN",
        //     },
        //     {
        //       label: "Married",
        //       value: "MR",
        //     },
        //     {
        //       label: "Separated",
        //       value: "SP",
        //     },
        //     {
        //       label: "Previously married",
        //       value: "DV",
        //     },
        //     {
        //       label: "Other",
        //       value: "O",
        //     },
        //     {
        //       label: "Prefer not to say",
        //       value: "NDC",
        //     },
        //   ],
        // },
        // field_dependents_child: {
        //   name: "field_dependents_child",
        //   type: "text",
        //   title:
        //     "How many people did you provide unpaid care to in the past 12 months? This may include dependent children, children under your care, or adults you are taking care of due to a health condition or disability (e.g., physical, mental, emotional, cognitive, behavioral, or developmental disability; a chronic health condition or psychiatric condition, or blindness or deafness). Assistance can include financial care, medical care, or help with everyday activities.",
        //   subTitle: "Number of Children",
        //   value: 0,
        //   label: "Number of Children",
        //   meta: {
        //     question: "financial dependents 2-parter q9.1 q9.2",
        //     type: "int, int",
        //   },
        // },
        // field_dependents_adult: {
        //   name: "field_dependents_adult",
        //   type: "text",
        //   title: "",
        //   subTitle: "Number of Adults",
        //   value: 0,
        //   label: "Number of Adults",
        //   meta: {
        //     question: "financial dependents 2-parter q9.1 q9.2",
        //     type: "int, int",
        //   },
        // },
        field_us_born: {
          name: 'field_us_born',
          type: 'select',
          multiple: false,
          title: 'Were you born in the United States?',
          subTitle: 'Please select one',
          value: [],
          sdChecked: false,
          sdValue: '',
          meta: {
            question: 'born in us q10',
            type: 'select, text',
          },
          options: [
            {
              label: 'Yes',
              value: 'Y',
            },
            {
              label: 'No',
              value: 'N',
            },
            {
              label: 'Prefer not to say',
              value: 'NDC',
            },
          ],
        },
        // field_lang_home: {
        //   name: "field_lang_home",
        //   type: "text",
        //   title: "What languages are currently spoken in your home?",
        //   subTitle: "Please select one",
        //   value: "",
        //   label: "Enter Languages...",
        //   meta: {
        //     question: "languages spoken in the home q11",
        //     type: "text",
        //   },
        // },
        // field_lang_child: {
        //   name: "field_lang_child",
        //   type: "text",
        //   title: "As a child, what languages were spoken in your home?",
        //   subTitle: "Please select one",
        //   value: "",
        //   label: "Enter Languages...",
        //   meta: {
        //     question: "languages spoken in the home as a child q12",
        //     type: "text",
        //   },
        // },
        // field_state: {
        //   name: "field_state",
        //   type: "select",
        //   multiple: false,
        //   title: "What state do you currently live in?",
        //   subTitle: "Please select one",
        //   value: [],
        //   sdChecked: false,
        //   sdValue: "",
        //   meta: { question: "state of residence q13", type: "text" },
        //   options: [
        //     { label: "Alabama", value: "AL" },
        //     { label: "Alaska", value: "AK" },
        //     { label: "Arizona", value: "AZ" },
        //     { label: "Arkansas", value: "AR" },
        //     { label: "California", value: "CA" },
        //     { label: "Colorado", value: "CO" },
        //     { label: "Connecticut", value: "CT" },
        //     { label: "Delaware", value: "DE" },
        //     { label: "District Of Columbia", value: "DC" },
        //     { label: "Florida", value: "FL" },
        //     { label: "Georgia", value: "GA" },
        //     { label: "Hawaii", value: "HI" },
        //     { label: "Idaho", value: "ID" },
        //     { label: "Illinois", value: "IL" },
        //     { label: "Indiana", value: "IN" },
        //     { label: "Iowa", value: "IA" },
        //     { label: "Kansas", value: "KS" },
        //     { label: "Kentucky", value: "KY" },
        //     { label: "Louisiana", value: "LA" },
        //     { label: "Maine", value: "ME" },
        //     { label: "Maryland", value: "MD" },
        //     { label: "Massachusetts", value: "MA" },
        //     { label: "Michigan", value: "MI" },
        //     { label: "Minnesota", value: "MN" },
        //     { label: "Mississippi", value: "MS" },
        //     { label: "Missouri", value: "MO" },
        //     { label: "Montana", value: "MT" },
        //     { label: "Nebraska", value: "NE" },
        //     { label: "Nevada", value: "NV" },
        //     { label: "New Hampshire", value: "NH" },
        //     { label: "New Jersey", value: "NJ" },
        //     { label: "New Mexico", value: "NM" },
        //     { label: "New York", value: "NY" },
        //     { label: "North Carolina", value: "NC" },
        //     { label: "North Dakota", value: "ND" },
        //     { label: "Ohio", value: "OH" },
        //     { label: "Oklahoma", value: "OK" },
        //     { label: "Oregon", value: "OR" },
        //     { label: "Pennsylvania", value: "PA" },
        //     { label: "Rhode Island", value: "RI" },
        //     { label: "South Carolina", value: "SC" },
        //     { label: "South Dakota", value: "SD" },
        //     { label: "Tennessee", value: "TN" },
        //     { label: "Texas", value: "TX" },
        //     { label: "Utah", value: "UT" },
        //     { label: "Vermont", value: "VT" },
        //     { label: "Virginia", value: "VA" },
        //     { label: "Washington", value: "WA" },
        //     { label: "West Virginia", value: "WV" },
        //     { label: "Wisconsin", value: "WI" },
        //     { label: "Wyoming", value: "WY" },
        //     { label: "American Samoa", value: "AS" },
        //     { label: "Guam", value: "GU" },
        //     { label: "Northern Mariana Islands", value: "MP" },
        //     { label: "Puerto Rico", value: "PR" },
        //     { label: "United States Minor Outlying Islands", value: "UM" },
        //     { label: "Virgin Islands", value: "VI" },
        //   ],
        // },
        role_section: {
          name: 'role_section',
          type: 'alert',
          severity: 'info',
          children: (
            <div>
              <p>
                {' '}
                The below questions ask about your law school or other
                professional experience. For students, information regarding
                your law school and graduation year might be shared with law
                firms.
                <br />
                <br />
                <strong> * Indicates a required field.</strong>
              </p>
            </div>
          ),
          // " The below questions ask about your law school or other professional experience. For students, information regarding your law school and graduation year might be shared with law firms.",
        },
        field_graduated: {
          name: 'field_graduated',
          type: 'select',
          title:
            'If you have graduated, what law school did you graduate from?',
          subTitle: 'Please select one',
          value: '',
          label: 'law school',
          meta: {
            question: 'law school graduated from q14',
            type: 'text',
          },
          options: lawSchools,
        },
        field_gpa: {
          name: 'field_gpa',
          type: 'number',
          inputProps: {
            min: '0.00',
            max: '4.00',
            step: '0.01',
          },
          title: 'What is your law school GPA?',
          subTitle: 'Please select one',
          value: '',
          label: '[0-4]',
          meta: {
            question: 'law school gpa q15',
            type: 'numeric',
          },
        },
        field_role_type: {
          name: 'field_role_type',
          type: 'radio',
          title: 'What type of role are you applying for?',
          subTitle: '',
          value: 'entryLevel',
          label: '',

          options: [
            {
              label: 'Entry Level',
              value: 'entryLevel',
            },
            {
              label: 'Experienced Lawyer',
              value: 'experiencedLawyer',
            },
          ],
        },
        field_attending: {
          name: 'field_attending',
          type: 'select',
          title: 'What law school are you currently enrolled in?',
          subTitle: 'Please select one',
          value: '',
          label: 'law school',
          meta: {
            question: 'law school currently attending q16',
            type: 'text',
          },
          required: state =>
            state.questions.field_role_type.value == 'entryLevel',
          error: false,
          isVisible: state =>
            state.questions.field_role_type.value == 'entryLevel',
          options: lawSchools,
        },
        field_graduation: {
          name: 'field_graduation',
          type: 'month',
          title: 'What is your expected graduation date?',
          subTitle: 'Please select',
          value: '',
          error: false,
          label: '',
          required: state =>
            state.questions.field_role_type.value == 'entryLevel',
          placeholder: '',
          meta: {
            question: '',
            type: 'text',
          },
          inputProps: {
            min: moment().format('YYYY-MM'),
            max: moment().endOf('year').add(5, 'years').format('YYYY-MM'),
          },
          isVisible: state =>
            state.questions.field_role_type.value == 'entryLevel',
        },
        field_emp: {
          name: 'field_emp',
          type: 'select',
          multiple: false,
          error: false,
          title: 'Where are you currently working?',
          subTitle: 'Please select one',
          value: [],
          sdAlways: true, // to make the SD field always visible
          sdChecked: true,
          sdValue: '',
          sdLabel: 'Describe',
          meta: {
            question: 'currently employed q17',
            type: 'text',
          },
          isVisible: state =>
            state.questions.field_role_type.value == 'experiencedLawyer',
          options: [
            {
              label: 'Firm (please list)',
              value: 'FIRM',
            },
            {
              label: 'Clerk (please list)',
              value: 'CLERK',
            },
            {
              label: 'In-house (please list)',
              value: 'INHOUSE',
            },
            {
              label: 'Government',
              value: 'GOVT',
            },
            {
              label: 'Non-legal role',
              value: 'NONLEGAL',
            },
            {
              label: 'Not currently employed',
              value: 'NONE',
            },
          ],
        },
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
  }

  showErrorMessage = (showAlert, errorMessage) => {
    this.setState({ ...this.state, showAlert, errorMessage });
  };

  handleChangeCommon = (name, value) => {
    const { questions } = this.state;
    this.setState({
      showAlert: '',
      questions: {
        ...questions,
        [name]: {
          ...questions[name],
          error: false,
          value,
          sdChecked: questions[name].sdAlways || value?.indexOf('SD') > -1,
        },
      },
    });
  };

  handleSDChangeCommon = e => {
    let { name, value } = e.target;
    this.setState({
      questions: {
        ...this.state.questions,
        [name]: {
          ...this.state.questions[name],
          sdValue: value,
        },
      },
    });
  };

  handleSubmit = () => {
    const { questions } = this.state;

    let requiredAnswered = true;
    const missing = '';
    if (questions.field_role_type.value == 'entryLevel') {
      if (
        questions.field_graduation.value.length < 1 ||
        questions.field_attending.value.length < 1
      ) {
        requiredAnswered = false;
        this.showErrorMessage(
          'error',
          'One or more of the required fields are missing.'
        );
      }
    }

    const data = Object.keys(questions).reduce((acc, questionName) => {
      const question = questions[questionName];
      let value = question.value;

      if (question.sdChecked && question.sdValue) {
        value = value.concat(question.sdValue);
      }

      if (
        question.required &&
        value.length < 1 &&
        questions.field_role_type.value == 'entryLevel'
      ) {
      }

      acc[question.name] = value;
      return acc;
    }, {});

    if (requiredAnswered == true) {
      request({
        method: 'post',
        responseType: 'json',
        url: '/api/demographics',
        data,
      })
        .then(response => {
          this.setState({ stage: 1 });
          window.scrollTo(0, 0);
          this.props.onFinish();
        })
        .catch(err => console.log(err));
    }
  };

  render() {
    const { stage, questions, showAlert } = this.state;

    return (
      <Fragment>
        {stage == 1 ? (
          <div className="card">
            <h2>Thank you for participating!</h2>
            <p>You will be redirected to the dashboard automatically.</p>
          </div>
        ) : null}
        {stage == 0 ? (
          <div>
            <div className="form">
              {Object.keys(questions)
                .filter(questionName => {
                  const isVisible = questions[questionName].isVisible;
                  return isVisible ? isVisible(this.state) : true;
                })
                .map(questionName => {
                  const question = questions[questionName];
                  return (
                    <Question
                      key={question.name}
                      // openAlert={() => {
                      //   this.showErrorMessage(showAlert, question.name);
                      // }}
                      required={question.required || false}
                      error={question.error || false}
                      handleChange={this.handleChangeCommon}
                      handleSDChangeCommon={this.handleSDChangeCommon}
                      {...question}
                    />
                  );
                })}

              <div className="element">
                <button className="button w95" onClick={this.handleSubmit}>
                  Submit Survey
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.showAlert !== '' ? (
          <BasicSnackbar
            message={this.state.errorMessage}
            snackbarOpen={'error'}
            setSnackbarOpen={() => this.setState({ showAlert: '' })}
            severity={'error'}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default Demographics;
