import React from 'react';
import { newColorMap } from './constants';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import variablePie from 'highcharts/modules/variable-pie.js';
variablePie(Highcharts);

const DimensionChart = ({ dimensions }) => {
  let dimensionSlices = dimensions.map(dimension => {
    return {
      name: dimension.name,
      color: newColorMap[dimension.color],
      sizeBy: 'radius',
      y: dimensions.length / 100,
      z: Math.floor(dimension.individualScore),
      zLabel: Math.floor(dimension.individualScore),
      popScore: Math.floor(dimension.populationScore),
    };
  });

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'variablepie',
      margin: '0',
      animation: false,
      height: 320,
    },
    title: {
      text: '',
    },
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        'Individual score: {point.zLabel} <br/<br/>' +
        'Population average: {point.popScore}',
    },
    plotOptions: {
      series: {
        animation: false,
      },
      variablepie: {
        size: 310,
        sizeBy: 'radius',
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          // alignTo: 'connectors',
          // format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
      },
    },
    series: [
      {
        minPointSize: 0,
        maxPointSize: 150,
        zMin: 0,
        zMax: 100,
        size: 310,
        innerSize: '0%',
        name: 'dimensions',
        data: dimensionSlices,
        states: {
          hover: {
            enabled: false,
            halo: null,
          },
        },
      },
      {
        showInLegend: false,
        type: 'variablepie',
        size: '100%',
        innerSize: '100%',
        minPointSize: 0,
        borderSize: 1,
        borderColor: '#CCCCCC',
        dataLabels: {
          enabled: true,
          format: '100',
          connectorPadding: 0,
          connectorWidth: 0,
          distance: 0,
          borderRadius: 10,
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          borderColor: '#FFFFFF',
          // shadow: true,
          style: {
            fontWeight: 'bold',
            fontColor: '#7A8D98',
            fontSize: '6px',
          },
        },
        enableMouseTracking: false,
        data: [
          {
            y: 100,
            z: 100,
          },
        ],
        colors: ['rgba(0,0,0,0)'],
      },
      {
        showInLegend: false,
        type: 'variablepie',
        size: '100%',
        innerSize: '75%',
        minPointSize: 0,
        dataLabels: {
          enabled: true,
          format: '75',
          connectorPadding: 0,
          connectorWidth: 0,
          distance: 0,
          borderRadius: 10,
          backgroundColor: '#FFFFFF',
          borderWidth: 1,
          borderColor: '#FFFFFF',
          // shadow: true,
          align: 'left',
          style: {
            fontWeight: 'bold',
            fontColor: '#7A8D98',
            fontSize: '6px',
          },
        },
        enableMouseTracking: false,
        data: [
          {
            y: 100,
            z: 75,
          },
        ],
        borderSize: 1,
        borderColor: '#CCCCCC',
        colors: ['rgba(0,0,0,0)'],
      },
      {
        showInLegend: false,
        type: 'variablepie',
        size: '100%',
        innerSize: '50%',
        minPointSize: 0,
        dataLabels: {
          enabled: true,
          format: '50',
          connectorPadding: 0,
          connectorWidth: 0,
          distance: 0,
          borderRadius: 10,
          backgroundColor: '#FFFFFF',
          borderWidth: 1,
          borderColor: '#FFFFFF',
          // shadow: true,
          style: {
            fontWeight: 'bold',
            fontColor: '#7A8D98',
            fontSize: '6px',
          },
        },
        enableMouseTracking: false,
        data: [
          {
            y: 100,
            z: 50,
          },
        ],
        borderSize: 1,
        borderColor: '#CCCCCC',
        colors: ['rgba(0,0,0,0)'],
      },
      {
        showInLegend: false,
        type: 'variablepie',
        size: '100%',
        innerSize: '25%',
        minPointSize: 0,
        dataLabels: {
          enabled: true,
          format: '25',
          connectorPadding: 0,
          connectorWidth: 0,
          distance: 0,

          borderRadius: 10,
          backgroundColor: '#FFFFFF',
          borderWidth: 1,
          borderColor: '#FFFFFF',
          // shadow: true,
          style: {
            fontWeight: 'bold',
            fontColor: '#7A8D98',
            fontSize: '6px',
          },
        },
        enableMouseTracking: false,
        data: [
          {
            y: 100,
            z: 25,
          },
        ],
        borderSize: 1,
        borderColor: '#CCCCCC',
        colors: ['rgba(0,0,0,0)'],
      },
    ],
  };

  return (
    <div
      style={{
        minWidth: '340px',
        maxWidth: '340px',
        padding: '10px',
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default DimensionChart;
