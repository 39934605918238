import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      align: 'start',
      pointStyle: true,
    },
    title: {
      display: true,
      text: 'Score Distribution',
      position: 'top',
      align: 'start',
      padding: {
        bottom: 50,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const labels = [
  '1-10',
  '11-20',
  '21-30',
  '31-40',
  '41-50',
  '51-60',
  '61-70',
  '71-80',
  '81-90',
  '91-100',
];

export default function BarChart({ scores }) {
  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: scores,
        backgroundColor: [
          '#da0a00',
          '#da0a00',
          '#da0a00',
          '#eaaa00',
          '#eaaa00',
          '#eaaa00',
          '#eaaa00',
          '#eaaa00',
          '#008b57',
          '#008b57',
          '#008b57',
        ],
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
