import Box from '@mui/material/Box';
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

import { useState, useEffect, useMemo } from 'react';

const StyledRadio = props => {
  if (props.checked) {
    return (
      <Radio
        {...props}
        sx={{
          '.MuiSvgIcon-root': {
            color: '#00629b!important',
            width: '1em!important',
            height: '1em!important',
          },
        }}
      />
    );
  } else {
    return (
      <Radio
        {...props}
        sx={{
          '.MuiSvgIcon-root': {
            color: '#cccccc!important',
            width: '1em!important',
            height: '1em!important',
          },
        }}
      />
    );
  }
};

const ScaleButtons = props => {
  const [answered, setAnswered] = useState(props.answered);
  const [answerUpdated, setAnswerUpdated] = useState(false);
  const [answerValue, setAnswerValue] = useState(null);

  //const isDesktop = window.innerWidth > 960;
  // console.log('props', answerValue, props);

  const question_id = props.question.question_id;

  useEffect(() => {
    if (props.answered && answerValue == null) {
      // was receiving default 0 position on first render,
      // 0 is still a valid value but this will listen for prop update
      setAnswerValue(props.answeredIndex);
    }
  }, [props.answered]);

  let answers = [
    {
      label: '',
      value: 0,
    },
    {
      label: '',
      value: 1,
    },
    {
      label: '',
      value: 2,
    },
    {
      label: '',
      value: 3,
    },
    {
      label: '',
      value: 4,
    },
  ];

  const handleChange = event => {
    const { value } = event.target;
    props.handleSlide(value);
    setAnswerValue(value);
    setAnswerUpdated(true);
  };

  switch (props.section) {
    case 1:
      answers[0].label = 'Strongly Disagree';
      answers[1].label = 'Disagree';
      answers[2].label = 'Neither Agree nor Disagree';
      answers[3].label = 'Agree';
      answers[4].label = 'Strongly Agree';

      break;
    case 2:
      answers[0].label = 'Rarely';
      answers[1].label = 'Occasionally';
      answers[2].label = 'Sometimes';
      answers[3].label = 'Often';
      answers[4].label = 'Always';
      break;
    default:
      answers[0].label = props.labelLeft;
      answers[1].label = '';
      answers[2].label = '';
      answers[3].label = '';
      answers[4].label = props.labelRight;
      break;
  }

  const labelStyle = {
    width: '16vw',
    margin: '1vw!important',
    'text-align': 'center',
  };

  // console.log('props', props);

  /**
   * this awkwardly placed function is meant to check if a given value should be checked
   * we're doing this bc I don't trust the answerValue to be a number (vs null or string)
   */
  const isChecked = useMemo(
    () => value => {
      return answerValue !== null && Number(answerValue) === value;
    },
    [answerValue]
  );

  return (
    <Box
      className={props.answered ? '' : 'unanswered'}
      sx={{
        width: '100vw',
        padding: '1',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <FormControl>
        <FormLabel id={`question-${question_id}`} style={{ display: 'none' }}>
          {props.question.title}
        </FormLabel>
        <RadioGroup
          aria-labelledby={`question-${question_id}`}
          onChange={handleChange}
          value={answerValue}
          sx={{ width: '100vw' }}
          name={`question-${question_id}-group`}
          row
        >
          {/*  */}
          <FormControlLabel
            labelPlacement="bottom"
            value={0}
            control={<StyledRadio checked={isChecked(0)} />}
            label={answers[0].label}
            sx={labelStyle}
          />
          <FormControlLabel
            labelPlacement="bottom"
            value={1}
            control={<StyledRadio checked={isChecked(1)} />}
            label={answers[1].label}
            sx={labelStyle}
          />
          <FormControlLabel
            labelPlacement="bottom"
            value={2}
            control={<StyledRadio checked={isChecked(2)} />}
            label={answers[2].label}
            sx={labelStyle}
          />
          <FormControlLabel
            labelPlacement="bottom"
            value={3}
            control={<StyledRadio checked={isChecked(3)} />}
            label={answers[3].label}
            sx={labelStyle}
          />
          <FormControlLabel
            labelPlacement="bottom"
            value={4}
            control={<StyledRadio checked={isChecked(4)} />}
            label={answers[4].label}
            sx={labelStyle}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default ScaleButtons;
