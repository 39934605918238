import React from 'react';
import { DimensionBehaviorsSection } from './DimensionBehaviorsSection';
import './styles.scss';
import { DimensionCategories } from '../constants';

export function Page06_DimensionBehaviors() {
  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>Dimension Behaviors</h4>

          <p style={{ fontSize: '10px' }}>
            The following is an overview of the typical behaviors of lawyers
            that are high in each dimension.
          </p>

          {DimensionCategories.map((dimension, index) => {
            return (
              <DimensionBehaviorsSection
                key={index}
                category={dimension}
                index={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
