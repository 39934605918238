import request from '../RequestUtil.js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BasicSnackBar from './../Components/Utilities/BasicSnackbar';
import Loader from './../Components/Loader';
import LateralDefault from '../Reports/LateralDefault';
import LateralV2 from '../Reports/LateralV2';
import OCIClient from '../Reports/OCIClient';
import SkillsReport from '../Reports/SkillsReport';
import DevelopmentalFullReport from '../Reports/DevelopmentalFullReport';
import DevelopmentalCoachingReport from '../Reports/DevelopmentalCoachingReport';
import OCIAlt from '../Reports/OCIAlt';
import FileStoreReport from '../Reports/FileStoreReport';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getClientCandidateAssissmentMapping } from '../services/candidate_mapping';

import { getJobs } from '../services/jobs.js';
import { AccelerateReport } from '../Reports/Components/Accelerate/AccelerateReport';

class ReportDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      template_name: '',
      report_data: null,
      isLocked: true,
      isLoaded: false,
      jobDetails: null,
      sendMessageResponse: {
        open: false,
        message: '',
        severity: 'success',
      },
    };
  }

  async componentDidMount() {
    const id = _.get(this, 'props.match.params.id', null);

    try {
      if (id) {
        const mappingResponse = await getClientCandidateAssissmentMapping(id);
        const mappingDetails = _.get(mappingResponse, 'rows.0', null);

        const jobDetailsResponse = await getJobs({
          id: _.get(mappingDetails, 'jobId', ''),
        });
        const jobDetails = _.get(jobDetailsResponse, '0', null);

        if (jobDetails && !jobDetails.isServerRenderedReport) {
          const response = await request({
            method: 'post',
            url: '/api/scoring/assigned',
            responseType: 'json',
            data: { id },
          });

          this.setState({
            report_data: response.data,
            report_id: response.data.assessment_meta.report_id,
          });
        }

        this.setState({
          isLoaded: true,
          jobDetails,
          isLocked: _.get(mappingDetails, 'isReportLocked', true),
        });
      }
    } catch (error) {
      this.setState({
        isLoaded: true,
        sendMessageResponse: {
          ...this.state.sendMessageResponse,
          open: true,
          message: 'Something Went Wrong!',
          severity: 'error',
        },
      });
    }
  }

  handleTabChange = async (event, newValue) => {
    this.setState({
      tab: newValue,
    });
  };

  getReportComponent = () => {
    const id = _.get(this, 'props.match.params.id', null);
    const { user, firm } = this.props;
    const { isLocked, jobDetails } = this.state;
    const isCandidateView = user.perms.includes(3);
    const scoreType = _.get(firm, 'scoreType', 'numeric');
    const { report_data, report_id, tab } = this.state;

    if (jobDetails && jobDetails.isServerRenderedReport) {
      return <FileStoreReport id={id} />;
    }

    switch (report_id) {
      case 10:
        return <OCIAlt data={report_data} scoreType={scoreType} />;
      case 1:
        return <OCIClient data={report_data} scoreType={scoreType} />;
      case 2:
        return <LateralDefault data={report_data} scoreType={scoreType} />;
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return <SkillsReport data={report_data} scoreType={scoreType} />;
      case 8:
        return <LateralV2 data={report_data} scoreType={scoreType} />;
      case 9:
        if (report_data.assessment_meta.version === 2) {
          return (
            <AccelerateReport
              data={report_data}
              scoreType={scoreType}
              isLockReportEnabled={!isCandidateView}
              isLocked={isLocked}
              clientCandidateAssissmentMappingId={id}
              useZoomer={true}
            />
          );
        } else {
          return (
            <div>
              {!isCandidateView && (
                <Tabs
                  className="flexReverse"
                  value={tab}
                  onChange={this.handleTabChange}
                  style={{ marginBottom: '5px', marginTop: '-30px' }}
                >
                  <Tab label="Coaching View" />
                  <Tab label="Full Report" />
                </Tabs>
              )}
              {tab == 0 ? (
                <DevelopmentalCoachingReport
                  data={report_data}
                  scoreType={scoreType}
                  isLockReportEnabled={isCandidateView ? false : true}
                  isLocked={isLocked}
                  clientCandidateAssissmentMappingId={id}
                />
              ) : (
                <DevelopmentalFullReport
                  data={report_data}
                  scoreType={scoreType}
                />
              )}
            </div>
          );
        }

      default:
        return <div>Unknown Report Type</div>;
    }
  };

  render() {
    const { isLoaded } = this.state;
    let ReportComponent = null;
    if (this.props.user != null) {
      ReportComponent = this.getReportComponent();
    }
    return isLoaded && this.props.user != null ? (
      <div>
        {ReportComponent}
        <BasicSnackBar
          snackbarOpen={this.state.sendMessageResponse.open}
          setSnackbarOpen={value =>
            this.setState({
              sendMessageResponse: {
                ...this.state.sendMessageResponse,
                open: value,
              },
            })
          }
          message={this.state.sendMessageResponse.message}
          severity={this.state.sendMessageResponse.severity}
        />
      </div>
    ) : (
      <Loader minHeight={'100vh'} />
    );
  }
}

const mapStateToProps = state => {
  return {
    // FYI - Firm details will not be present for candidate login
    firm: state.firm,
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(ReportDisplay);
