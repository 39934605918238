import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Typography } from '@mui/material';

export default function BasicSelect({
  options,
  title,
  value,
  onChange,
  style,
  disabled = false,
}) {
  return (
    <Box sx={{ marginBottom: 2, ...style }}>
      <Typography
        variant="subtitle2"
        style={{
          fontWeight: 600,
        }}
        component="div"
      >
        {title}
      </Typography>
      <FormControl
        disabled={disabled}
        sx={{ m: 1, ml: 0, mt: 1, minWidth: 400, width: 500 }}
      >
        <Select
          defaultValue={value}
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map(option => (
            <MenuItem value={option.value} key={option.value}>
              {option.displayText}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
