import { Box, TextField, Typography } from '@mui/material';
import React from 'react';

const BasicTextarea = ({
  title,
  name,
  placeholder,
  value = '',
  onChange,
  textLimit,
  style,
  disabled = false,
  ...props
}) => {
  const [typedCharacters, setTypedCharacters] = React.useState(
    value.length || 0
  );
  const [error, setError] = React.useState({
    msg: '',
    error: false,
  });

  const handleChange = event => {
    const targetValue = event.target.value;
    const targetName = event.target.name;
    if (targetValue.length <= textLimit) {
      onChange(targetValue, targetName);
      setTypedCharacters(targetValue.length);
    }

    if (!targetValue) {
      setError({
        error: true,
        msg: `${title.toLowerCase()} cannot be empty`,
      });
    } else {
      setError({
        error: false,
        msg: '',
      });
    }
  };

  return (
    <Box sx={{ marginBottom: 2, width: 'fit-content', ...style }}>
      <Typography
        variant="subtitle2"
        style={{ fontWeight: 600, mb: 2 }}
        component="div"
      >
        {title}
      </Typography>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': {
            m: 1,
            ml: 0,
            mt: 2,
            minWidth: 400,
            width: 500,
          },
        }}
        noValidate
        autoComplete="off"
      >
        <Box>
          <TextField
            sx={{
              '& textarea': {
                boxShadow: 'none',
              },
            }}
            placeholder={placeholder}
            multiline
            rows={props.rows || 4}
            error={error.error}
            value={value}
            name={name}
            onChange={handleChange}
            disabled={disabled}
          />
        </Box>
        <Typography
          variant="subtitle2"
          style={{ fontWeight: 300, float: 'right', marginRight: 10 }}
          component="div"
        >
          {typedCharacters}/{textLimit} Characters
        </Typography>
        {error.error && (
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ color: '#d32f2f' }}
          >
            {error.msg}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default BasicTextarea;
