import React, { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import BasicSnackbar from '../../../Components/Utilities/BasicSnackbar';
import { withRouter } from 'react-router';

import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import request from '../../../RequestUtil.js';

const defaultExpiryDate = new Date();
class ClientSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.clientId = this.props.match.params.clientId;
    this.state = {
      isLoading: true,
      assessmentTypes: [],
      assessmentPoolsMap: {},
    };
  }

  componentDidMount() {
    this.getJobs();
  }

  getJobs = async () => {
    this.setState({ isLoading: true });
    const assessmentTypeResponse = await axios({
      method: 'get',
      url: '/api/assessment-types',
      responseType: 'json',
    });

    const poolResponse = await axios({
      method: 'get',
      url: `/api/assessment-pools?clientId=${this.clientId}`,
      responseType: 'json',
    });

    const assessmentPools = _.get(poolResponse, 'data', []);
    const assessmentPoolsMap = assessmentPools.reduce((acc, pool) => {
      acc[pool.assessmentType] = pool;
      return acc;
    }, {});

    this.setState({
      isLoading: false,
      assessmentTypes: _.get(assessmentTypeResponse, 'data.rows', []).sort(
        (a, b) => a.id - b.id
      ),
      assessmentPoolsMap,
    });
  };

  updateRowState({ assessment, key, value }) {
    const { assessmentPoolsMap } = this.state;
    const assessmentId = assessment.id;
    const pool = assessmentPoolsMap[assessmentId] || {};

    this.setState({
      assessmentPoolsMap: {
        ...assessmentPoolsMap,
        ...{
          [assessmentId]: {
            ...pool,
            assessmentType: assessment.id,
            [key]: value,
          },
        },
      },
    });
  }

  updateSubscriptions = async () => {
    const { assessmentPoolsMap } = this.state;
    for (const [key, pool] of Object.entries(assessmentPoolsMap)) {
      const { poolId } = pool;
      try {
        if (poolId) {
          await request({
            method: 'put',
            url: `/api/assessment-pools/${poolId}`,
            data: pool,
            responseType: 'json',
          });
        } else {
          await request({
            method: 'post',
            url: `/api/assessment-pools`,
            data: { ...pool, clientId: this.clientId },
            responseType: 'json',
          });
        }

        this.setState({
          alertMessage: 'Update Successful',
          alertSeverity: 'success',
        });
      } catch (error) {
        console.log(error);
        this.setState({
          alertMessage: `Something went wrong, try again later: ${error}`,
          alertSeverity: 'error',
        });
      }
    }
  };

  render() {
    const { assessmentTypes, assessmentPoolsMap } = this.state;
    return (
      <div>
        <h1>Client Subscriptions</h1>
        <div className="card">
          <List
            sx={{
              padding: 4,
              width: '100%',
              maxWidth: 720,
              bgcolor: 'background.paper',
            }}
          >
            {assessmentTypes.map(assessment => {
              const assessmentId = assessment.id;
              const pool = assessmentPoolsMap[assessmentId] || {};

              return (
                <>
                  <ListItem
                    sx={{ padding: 1 }}
                    key={assessmentId}
                    disablePadding
                  >
                    <ListItemIcon>
                      <Checkbox
                        onChange={event => {
                          const { checked } = event.target;
                          this.updateRowState({
                            assessment,
                            key: 'status',
                            value: checked ? 'active' : 'inactive',
                          });
                        }}
                        edge="start"
                        checked={pool.status == 'active'}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': assessment.label }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={assessmentId}
                      primary={assessment.label}
                    />
                    <ListItemText
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      id={assessmentId}
                      primary={
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Expiry Date"
                            value={moment(pool.expiryDate || defaultExpiryDate)}
                            onChange={newValue => {
                              this.updateRowState({
                                assessment,
                                key: 'expiryDate',
                                value: newValue,
                              });
                            }}
                            renderInput={params => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              );
            })}
          </List>
          <Divider></Divider>
          <Box
            sx={{
              width: '100%',
              display: 'inline-flex',
              flexDirection: 'row',
              justifyContent: 'right',
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <Button sx={{ textTransform: 'none' }} onClick={() => {}}>
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: '5px' }}
              sx={{ textTransform: 'none' }}
              onClick={this.updateSubscriptions}
            >
              Submit
            </Button>
          </Box>
        </div>
        <BasicSnackbar
          message={this.state.alertMessage}
          snackbarOpen={this.state.alertSeverity}
          setSnackbarOpen={() =>
            this.setState({ alertMessage: '', alertSeverity: '' })
          }
          severity={this.state.alertSeverity}
        />
      </div>
    );
  }
}

export default withRouter(ClientSubscriptions);
