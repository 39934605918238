import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BasicControls from '../../Components/BasicControls';
import BasicSelect from '../../Components/Inputs/BasicSelect';
import BasicTextInput from '../../Components/Inputs/BasicTextInput';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isValidEmail } from '../../helper/helper.js';

const userStatus = [
  { value: 'active', displayText: 'Active' },
  { value: 'inactive', displayText: 'Inactive' },
];

const UserCreate = ({ firm }) => {
  const [user, setUser] = React.useState({
    emailAddr: '',
    firstName: '',
    lastName: '',
    status: 'active',
    isDailyEmailEnabled: true,
    isCoach: false,
  });

  const [responseMessage, setResponseMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [responseSeverity, setResponseSeverity] = React.useState('success');
  const [responseStatus, setResponseStatus] = React.useState([-1]);
  const history = useHistory();

  async function postUser(url = '/', data = {}) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    setResponseStatus([response.status]);

    return response.json();
  }

  useEffect(() => {
    if (responseStatus[0] === -1) return;

    if (responseStatus[0] !== 200) {
      setResponseMessage('Failed to submit ticket');
      setResponseSeverity('error');
    } else {
      setResponseMessage('User created successfully');
      setResponseSeverity('success');
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }

    setSnackbarOpen(true);
  }, [responseStatus]);

  return (
    <Box sx={{ width: '35%' }}>
      <BasicTextInput
        title={'Enter Email'}
        placeholder={'Enter Email'}
        type="email"
        value={user.emailAddr}
        onChange={e => setUser({ ...user, emailAddr: e.target.value })}
      />
      <BasicTextInput
        title={'Enter First Name'}
        placeholder={'Enter First Name'}
        value={user.firstName}
        onChange={e => setUser({ ...user, firstName: e.target.value })}
      />
      <BasicTextInput
        title={'Enter Last Name'}
        placeholder={'Enter Last Name'}
        value={user.lastName}
        onChange={e => setUser({ ...user, lastName: e.target.value })}
      />
      <BasicSelect
        options={userStatus}
        title="Status"
        value={user.status}
        onChange={e => setUser({ ...user, status: e.target.value })}
      />
      <FormControlLabel
        label="Is daily email enabled ?"
        control={
          <Checkbox
            checked={user.isDailyEmailEnabled}
            onChange={event => {
              setUser({ ...user, isDailyEmailEnabled: event.target.checked });
            }}
            inputProps={{
              'aria-label': 'select for is daily email enabled',
            }}
          />
        }
      />
      {firm.coachFeatureEnabled && (
        <FormControlLabel
          label="Is a coach ?"
          control={
            <Checkbox
              checked={user.isCoach}
              onChange={event => {
                setUser({ ...user, isCoach: event.target.checked });
              }}
              inputProps={{
                'aria-label': 'select this if user is a coach',
              }}
            />
          }
        />
      )}

      <BasicControls
        onCancel={() => history.push('/users')}
        onClick={async () => {
          if (!user.emailAddr || !user.firstName) {
            setResponseMessage(
              'Failed to submit user, email and first name cannot be empty'
            );
            setResponseSeverity('error');
            setSnackbarOpen(true);
            return;
          }
          if (!isValidEmail(user.emailAddr)) {
            setResponseMessage(
              'Failed to submit user, please enter a valid email address'
            );
            setResponseSeverity('error');
            setSnackbarOpen(true);
            return;
          }

          await postUser('/api/portal-users', user);
        }}
        message={responseMessage}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        severity={responseSeverity}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    firm: state.firm,
  };
};

export default connect(mapStateToProps, null)(UserCreate);
