import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';

export default function RadioInput({
  name,
  value,
  options,
  multiple = false,
  title,
  subTitle,
  onChange,
}) {
  return (
    <Box sx={{ m: 1 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{subTitle}</FormLabel>
        <RadioGroup
          row
          aria-label={title}
          value={value}
          onChange={onChange}
          name={name}
        >
          {options.map(option => {
            return (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
