import React, { Component } from 'react';
import update from 'immutability-helper';
import axios from 'axios';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';

import {
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
  TextField,
  InputLabel,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import FilterSelector from '../FilterSelector';
import { getScoringConstants } from '../../../services/scoring-constants';
import { getAssessments } from '../../../services/assessments';

//  Component to create/edit job searches
class ClientJobSearchManage extends Component {
  constructor(props) {
    super(props);

    const availableSearches = this.props.jobsData.filter(row => {
      return (
        row.parentId == null && _.get(row, 'pool.assessmentType', null) === 1
      );
    });

    this.state = {
      type: 1,
      screen: 'form',
      error: null,
      formFields: {
        title: '',
        searchType: '',
        associatedJobId: 0,
        scoringConstantId: '',
        assessmentId: '',
        isServerRenderedReport: true,
      },
      availableSearches: availableSearches,

      jobId: this.props.jobId,
      isCreateFlow: this.props.jobId === 0,
      scoringConstants: [],
      assessments: [],
      isSubmitting: false,
    };
  }

  async componentDidMount() {
    try {
      this.loadJobDetails();
      const scoringConstantsResponse = await getScoringConstants({
        assessmentType: 1,
      });
      const assessmentResponse = await getAssessments({
        assessmentType: 1,
      });

      this.setState({
        scoringConstants: scoringConstantsResponse.rows,
        assessments: assessmentResponse,
      });
    } catch (error) {
      console.log(error);
    }
  }

  loadJobDetails = async () => {
    if (this.state.jobId !== 0) {
      const response = await axios({
        method: 'get',
        url: '/api/jobs/job/' + this.state.jobId,
        responseType: 'json',
      });

      const { title, parentId, scoringConstantId, assessmentId } =
        response.data[0];
      const { assessmentType } = response.data[0].pool;

      this.setState({
        type: assessmentType,
        formFields: {
          ...this.state.formFields,
          title,
          scoringConstantId,
          assessmentId,
          associatedJobId: parentId == null ? 0 : parentId,
          searchType: parentId == null ? 'firm' : 'general',
        },
      });
    }
  };

  handleTitleChange = e => {
    let formFields = { ...this.state.formFields };
    formFields.title = e.target.value;
    this.setState({ formFields, error: null });
  };

  handleIsServerRenderedReportChange = e => {
    const { isCreateFlow } = this.state;

    if (!isCreateFlow) {
      window.alert(
        'This is a risky change, as you are trying to change this configuration for an existing job. This change might lead to situations where some candidates are expected to work on server rendered reports but some candidates are expected to work on client rendered report.'
      );
    }

    this.setState({
      formFields: update(this.state.formFields, {
        isServerRenderedReport: { $set: e.target.checked },
      }),
    });
  };

  handleAssociatedSearchChange = e => {
    let formFields = { ...this.state.formFields };
    formFields.associatedJobId = e.target.value;
    this.setState({ formFields });
  };

  handleNormChange = e => {
    const { isCreateFlow } = this.state;

    if (!isCreateFlow) {
      window.alert(
        `This is a risky change, as you are trying to change norms of the job search. This will recalculate score of all the candidates within this job search.\n\nRescoring is an async process and might take few seconds to minutes to reflect depending on the number of candidates in the job search`
      );
    }

    this.setState({
      error: null,
      formFields: {
        ...this.state.formFields,
        scoringConstantId: e.target.value,
      },
    });
  };

  handleAssessmentChange = e => {
    this.setState({
      error: null,
      formFields: {
        ...this.state.formFields,
        assessmentId: e.target.value,
      },
    });
  };

  handleTypeChange = e => {
    let formFields = { ...this.state.formFields };
    if (e.target.value == 'firm') {
      formFields.associatedJobId = 0;
    }
    formFields.searchType = e.target.value;
    this.setState({ formFields });
  };

  isValidCreateForm = () => {
    const { title, scoringConstantId } = this.state.formFields;

    if (!title) {
      this.setState({
        isSubmitting: false,
        error: 'Job search title cannot be empty',
      });
      return false;
    }

    if (!scoringConstantId) {
      this.setState({
        isSubmitting: false,
        error: 'Please attach job search norms',
      });
      return false;
    }

    return true;
  };

  nextScreen = async () => {
    this.setState(
      {
        isSubmitting: true,
      },
      async () => {
        // create or edit search-- call to create job route
        if (this.state.jobId == 0) {
          // create
          try {
            const validForm = this.isValidCreateForm();
            if (!validForm) return;

            const { scoringConstantId, assessmentId, isServerRenderedReport } =
              this.state.formFields;
            const response = await axios({
              method: 'post',
              url: '/api/jobs/',
              data: {
                assessmentType: 1,
                clientId: this.props.clientId,
                title: this.state.formFields.title,
                parentId:
                  this.state.formFields.associatedJobId == 0
                    ? null
                    : this.state.formFields.associatedJobId,
                scoringConstantId,
                assessmentId,
                isServerRenderedReport,
              },
              responseType: 'json',
            });

            if (this.state.formFields.associatedJobId == 0) {
              // if the created job isnt a general search, go to edit the filters
              this.setState({
                jobId: response.data.id,
                screen: 'filters',
                isSubmitting: false,
              });
            } else {
              // general search, cannot edit filters, go to complete screen
              this.props.toggleAlert({
                alertMessage: 'Job created successfully.',
                alertSeverity: 'success',
              });
              this.setState({
                screen: 'complete',
                isSubmitting: false,
              });
            }
          } catch (error) {
            console.log(error);
            this.props.toggleAlert({
              alertMessage: `There was a problem creating/updating the job. Error: ${error?.response?.data?.error}`,
              alertSeverity: 'error',
            });

            this.setState({
              isSubmitting: false,
            });
          }
        } else {
          // update job, continue on and pass jobId to filters component screen
          // disallow switch of assessment type!
          const { jobId, type } = this.state;
          const {
            title,
            associatedJobId: parentId,
            scoringConstantId,
          } = this.state.formFields;

          try {
            const response = await axios({
              method: 'put',
              url: '/api/jobs/' + jobId,
              data: {
                parentId: parentId == 0 ? null : parentId,
                title,
                scoringConstantId,
              },
              responseType: 'json',
            });

            // if the job is not an entry level job, or the job is a general entry level search, do not show the filter select
            this.setState({
              screen: parentId !== 0 || type !== 1 ? 'complete' : 'filters',
              isSubmitting: false,
            });
          } catch (err) {
            console.log(err);
            this.setState({
              isSubmitting: false,
            });
          }
        }
      }
    );
  };

  prevScreen = () => {
    this.setState({ screen: 'form' });
  };

  render() {
    const {
      screen,
      isCreateFlow,
      scoringConstants,
      assessments,
      formFields,
      error,
      isSubmitting,
    } = this.state;
    const { scoringConstantId, assessmentId } = formFields;
    const availableSearchOptions = this.state.availableSearches.map(
      (search, i) => {
        return (
          <MenuItem key={i + 1} value={search.id}>
            {search.title}
          </MenuItem>
        );
      }
    );

    return (
      <>
        <div>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {isCreateFlow ? 'Add:' : 'Edit:'} Assessment for:{' '}
            {this.props.clientName}
          </Typography>
          {screen == 'form' && (
            <Box sx={{ width: '500px', paddingLeft: 1 }}>
              {this.state.type === 1 && isCreateFlow ? (
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  columnSpacing={1}
                  sx={{ mb: 1 }}
                >
                  <Grid item xs={12}>
                    <InputLabel
                      for="assessmenttype"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Assessment Type:{' '}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      name="assessmenttype"
                      label="Assessment Type"
                      sx={{ width: '400px' }}
                      onChange={this.handleTypeChange}
                    >
                      <MenuItem value="firm" key={1}>
                        Firm
                      </MenuItem>
                      <MenuItem value="general" key={2}>
                        General
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              ) : null}
              {this.state.formFields.searchType == 'general' && (
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  columnSpacing={1}
                  sx={{ mb: 1 }}
                >
                  <Grid item xs={12}>
                    <InputLabel
                      for="associatedsearch"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Associated Job Search
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      name="associatedsearch"
                      sx={{ width: '400px' }}
                      label="Select Associated Job Search"
                      onChange={this.handleAssociatedSearchChange}
                    >
                      {availableSearchOptions}
                    </Select>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                direction="column"
                alignItems="flex-start"
                columnSpacing={1}
                sx={{ mb: 1 }}
              >
                <Grid item xs={12}>
                  <InputLabel for="searchtitle" sx={{ fontWeight: 'bold' }}>
                    Job Search Title
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    title={''}
                    name="searchtitle"
                    placeholder={this.state.formFields.title || ''}
                    onChange={this.handleTitleChange}
                    sx={{
                      width: '400px',
                      input: {
                        '&::placeholder': {
                          color: 'black',
                        },
                      },
                    }}
                  ></TextField>
                </Grid>

                {/* TOOD - we should add a constraint that this scoring version should be for the selected assessment version */}
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  columnSpacing={1}
                  sx={{ mt: 1, mb: 1, ml: 0 }}
                >
                  <Grid item xs={12}>
                    <InputLabel
                      for="scoringConstant"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Associated Norm
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      displayEmpty
                      name="scoringConstant"
                      sx={{ width: '400px' }}
                      value={scoringConstantId}
                      onChange={this.handleNormChange}
                    >
                      {scoringConstants.map(scoringConstant => {
                        const { constId, notes, version } = scoringConstant;

                        return (
                          <MenuItem key={constId} value={constId}>
                            <div>
                              <div>{`Version ${version}`}</div>
                              {notes && <small>{notes}</small>}
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  columnSpacing={1}
                  sx={{ mt: 1, mb: 1, ml: 0 }}
                >
                  <Grid item xs={12}>
                    <InputLabel
                      for="scoringConstant"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Select assessment version
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      displayEmpty
                      name="entryLevelAssessment"
                      sx={{ width: '400px' }}
                      value={assessmentId}
                      onChange={this.handleAssessmentChange}
                      disabled={!isCreateFlow}
                    >
                      {assessments.map((assessment, ind) => (
                        <MenuItem
                          key={assessment.assessmentId}
                          value={assessment.assessmentId}
                        >
                          {assessment.assessmentDescription} - version{' '}
                          {assessment.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel
                    for="searchtitle"
                    sx={{ fontWeight: 'bold', marginTop: 1 }}
                  >
                    Server Rendered Reports
                  </InputLabel>

                  <FormGroup>
                    <FormControlLabel
                      label="Enabled"
                      onClick={this.handleIsServerRenderedReportChange}
                      control={
                        <Switch
                          checked={this.state.formFields.isServerRenderedReport}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>

              {error && <p style={{ color: 'red' }}>{error}</p>}

              <Button
                variant="contained"
                sx={{ m: '20px' }}
                onClick={this.props.backMain}
              >
                Back
              </Button>

              <LoadingButton
                variant="contained"
                sx={{ m: '20px' }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.nextScreen();
                }}
                startIcon={isCreateFlow ? <AddIcon /> : <EditIcon />}
                loading={isSubmitting}
              >
                {isCreateFlow ? <div>Create</div> : <div>Update</div>}
              </LoadingButton>
            </Box>
          )}
          {screen === 'filters' && (
            <FilterSelector
              toggleAlert={this.props.toggleAlert}
              jobId={this.state.jobId}
              clientId={this.props.clientId}
              complete={() => {
                this.setState({ screen: 'complete' });
              }}
            />
          )}
          {screen === 'complete' && (
            <Box>
              The changes have been saved.
              <br />
              <Button onClick={this.props.backMain} variant="contained">
                <ArrowBackIcon /> Go Back
              </Button>
            </Box>
          )}
        </div>
      </>
    );
  }
}

export default ClientJobSearchManage;
