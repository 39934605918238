const initialState = null;

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_USER_SESSION':
      return payload.user;
    default:
      return state;
  }
};

export default user;
