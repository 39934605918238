import { Box, Button } from '@mui/material';
import React from 'react';
import BasicSnackbar from './Utilities/BasicSnackbar';

const BasicControls = ({
  onClick,
  onCancel,
  message,
  snackbarOpen,
  setSnackbarOpen,
  severity,
  style,
  isLoading = false,
}) => {
  return (
    <Box
      sx={{
        mb: 2,
        mt: 2,
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
        ...style,
      }}
    >
      <Button
        variant="outlined"
        onClick={onCancel}
        sx={{
          mr: 2,
          width: '40%',
          maxWidth: '250px',
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={onClick}
        sx={{
          mr: 2,
          width: '40%',
          maxWidth: '250px',
        }}
      >
        {isLoading ? 'Loading...' : 'Submit'}
      </Button>
      <BasicSnackbar
        message={message}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        severity={severity}
      />
    </Box>
  );
};

export default BasicControls;
