import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import BasicControls from '../../../../Components/BasicControls';
import BasicSelect from '../../../../Components/Inputs/BasicSelect';
import BasicTextarea from '../../../../Components/Inputs/BasicTextarea';
import BasicTextInput from '../../../../Components/Inputs/BasicTextInput';
import EditModal from '../../../../Components/EditModal';

const ticketTypeOptions = [
  { value: 'issue', displayText: 'Report an issue' },
  { value: 'feature', displayText: 'Request a feature' },
  { value: 'question', displayText: 'Question' },
];

const priorityOptions = [
  { value: 'low', displayText: 'Low' },
  { value: 'medium', displayText: 'Medium' },
  { value: 'high', displayText: 'High' },
];

const statusOptions = [
  { value: 'pending', displayText: 'Pending' },
  { value: 'inProgress', displayText: 'In Progress' },
  { value: 'completed', displayText: 'Completed' },
  { value: 'closed', displayText: 'Closed' },
  { value: 'onHold', displayText: 'On Hold' },
];

const statusOptionsMap = _.keyBy(statusOptions, 'value');
const UpdateTicketModal = ({
  handleCancel,
  title,
  subTitle,
  id,
  setIsTicketUpdated,
  row,
  isAdmin,
}) => {
  const [ticket, setTicket] = React.useState({
    type: row.type || ticketTypeOptions[0].value,
    priority: row.priority || priorityOptions[0].value,
    status: row.status || 'pending',
    description: row.description || '',
    title: row.title || '',
    id,
    response: row.response || '',
  });
  const [responseMessage, setResponseMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [responseSeverity, setResponseSeverity] = React.useState('success');
  const [responseStatus, setResponseStatus] = React.useState([-1]);

  async function putTicket(url = '/', data = {}) {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    setResponseStatus([response.status]);

    return response.json();
  }

  useEffect(() => {
    if (responseStatus[0] === -1) return;

    if (responseStatus[0] !== 200) {
      setResponseMessage('Failed to submit ticket');
      setResponseSeverity('error');
    } else {
      setResponseMessage('Ticket updated successfully');
      setResponseSeverity('success');
      setIsTicketUpdated([1]);
    }

    setSnackbarOpen(true);
  }, [responseStatus, setIsTicketUpdated]);

  return (
    <EditModal handleCancel={handleCancel} title={title} subTitle={subTitle}>
      <div id="updateTicketModal">
        <BasicTextInput
          title={'Ticket Subject Name'}
          placeholder={'Enter Subject Name'}
          value={ticket.title}
          onChange={e => setTicket({ ...ticket, title: e.target.value })}
          style={{ mb: 3 }}
        />
        <BasicSelect
          options={ticketTypeOptions}
          title="Ticket Type"
          value={ticket.type}
          onChange={e => setTicket({ ...ticket, type: e.target.value })}
          style={{ mb: 3 }}
        />
        <BasicTextarea
          title={'Description of issue'}
          placeholder={'Enter Description'}
          value={ticket.description}
          textLimit={500}
          onChange={value => setTicket({ ...ticket, description: value })}
          style={{ mb: 3 }}
        />
        <BasicSelect
          options={priorityOptions}
          title="Priority"
          value={ticket.priority}
          onChange={e => setTicket({ ...ticket, priority: e.target.value })}
          style={{ mb: 3 }}
        />
        {isAdmin ? (
          <>
            <BasicSelect
              title="Status"
              options={statusOptions}
              value={ticket.status}
              onChange={e => setTicket({ ...ticket, status: e.target.value })}
              style={{ mb: 3 }}
            />
            <BasicTextarea
              title={'Admin Response'}
              placeholder={'Enter admin response'}
              value={ticket.response}
              textLimit={1000}
              onChange={value => setTicket({ ...ticket, response: value })}
              style={{ mb: 3 }}
            />
          </>
        ) : (
          <div>
            <Typography
              variant="subtitle2"
              sx={{ marginTop: '15px', marginBottom: '15px' }}
            >
              <b>Status</b>
            </Typography>
            <Typography sx={{ pl: 3 }}>
              {_.get(statusOptionsMap, [ticket.status, 'displayText'], 'NA')}
            </Typography>
            {ticket.response ? (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{ marginTop: '15px', marginBottom: '15px' }}
                >
                  <b>Thine Support Response</b>
                </Typography>
                <Typography>{ticket.response}</Typography>
              </>
            ) : null}
          </div>
        )}

        <BasicControls
          onCancel={handleCancel}
          onClick={async () => {
            if (!ticket.title || !ticket.description) {
              setResponseMessage(
                'Failed to submit ticket, title and description cannot be empty'
              );
              setResponseSeverity('error');
              setSnackbarOpen(true);
              return;
            }

            await putTicket(`/api/support-ticket/${id}`, ticket);
          }}
          message={responseMessage}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          severity={responseSeverity}
          style={{ mb: 0 }}
        />
      </div>
    </EditModal>
  );
};

export default UpdateTicketModal;
