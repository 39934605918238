import { CategorySection } from './CategorySection';

import { DimensionCategories } from '../constants';

export function Page05_ModernLawyerCompetencyFramework() {
  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>Modern Lawyer Competency Framework&trade;</h4>

          <p style={{ fontSize: '10px' }}>
            The Modern Lawyer Competency Framework&trade; consists of seven
            dimensions, within which are 22 competencies associated with
            high-performing lawyers, organized based on types of behaviors.
            These dimensions are:
          </p>

          {DimensionCategories.map((category, index) => (
            <CategorySection key={index} category={category} />
          ))}
          <div
            style={{
              height: '0px',
              // height:'130px',
              width: '100%',
              backgroundColor: 'red',
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
