import React, { Component } from 'react';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _ from 'lodash';
import '../css/fontawesome-v6/css/all.css';
import '../css/DevelopmentalReport.scss';
import Typography from '@mui/material/Typography';
import Zoomer from '../Components/Zoomer';
// report components
import DimensionPanes from './Components/Developmental/DimensionPanes';
import HighlightPanel from './Components/Developmental/HighlightPanel';
import CandidateDimensions from './Components/Developmental/CandidateDimensions';
import CompetencyOverview from './Components/Developmental/CompetencyOverview';
import CompetencyDetail from './Components/Developmental/CompetencyDetail';
import DimensionsKey from './Components/Developmental/DimensionsKey';
import DimensionChart from './Components/Developmental/DimensionChart';
import NotesBlock from './Components/Developmental/NotesBlock';

import {
  MIN_BAR_WIDTH,
  assetsBase64,
} from './Components/Developmental/constants';

const staticText = {
  intro: [
    "The following report is based on your responses to Thine's Modern Lawyer Competency Index and includes an in-depth analysis of the types of workplace behaviors you gravitate towards or exhibit. The Modern Lawyer Competency Framwork was developed with input from a diverse group of legal professionals and academics and after extensive research into the traits and behaviors of high performing lawyers. The Modern Lawyer Competency Framework is a framework which measures for those professional competencies most relevant to success in a law firm setting.",
    "The Modern Lawyer Competency Framework is organized into seven 'dimensions', with each dimension representing a cluster of behaviors commonly occuring in the workplace. Within the seven dimensions are 22 professional competencies, each of which describes a specific pattern of behavior or characteristic.",
    'The report illustrates how you [present] with regard to each dimension and competency as well as the behaviors commonly associated with each. The information is presented on a scale of low to high, and is intended to illustrate how frequently you engage in a particular behavior or how strongly you exhibit it. The report also contains strategies that you can use to grow in a particular competency. We encourage you to view the report in its entirety as a way of looking for, and learning from patterns in your behaviors. You may find that the report resonates with how you believe you show up in the workplace or be surprised by some of its insights. Take note of any areas which surprise you as they can provide a useful starting point for a conversation.',
    'Every lawyer is unique and embraces each competency to different extents and in different ways throughout their career. No combination of strengths is more valuable than another. As you go through the report, consider your unique strengths and how you can best leverage them as well as where you believe there is the greatest potential for growth and what that could look like.',
  ],

  framework: [
    'It may be helpful to talk through your results with someone who knows you personally, including a trusted colleague, or with a mentor, coach, or other internal or external resource focused on your professional development.',
  ],
  dimensionOverview: [
    'Based on your responses, your tendencies may reflect the following: ',
  ],
  competencyOverview: [
    'The following identifies the two competencies that you are highest in and the two competencies which present the greatest opportunity for growth.',
    'An overview of each of these competencies follows along with a more in-depth analysis including the definition of each competency, a breakdown of the specific elements of that competency (known as sub-competencies), and questions to reflect on. As you review this information, reflect on how this information aligns with your expectations.',
  ],
};

class Developmental extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      isDownloading: false,
      downloadProgress: 0,
    };
  }

  componentDidMount() {
    const fileName =
      this.props.data.user.first_name +
      ' ' +
      this.props.data.user.last_name +
      ' Developmental Report ' +
      moment(this.props.data.user.completed_dt).format('MM-DD-YYYY') +
      '.pdf';
    this.setState({
      isLoaded: true,
      scoreData: this.props.data,
      fileName,
    });
  }

  handleDownload = async () => {
    const pdfSections = document.getElementsByClassName('pdfSections');
    const totalPages = pdfSections.length;
    const updateProgress = page => {
      page = page + 1;
      let percentLoaded = (page / totalPages) * 100;
      this.setState({ downloadProgress: percentLoaded });
    };
    const boxShadows = document.querySelectorAll('.box-shadow-no-export');

    boxShadows.forEach(function (el) {
      el.classList.remove('box-shadow-no-export');
    });

    this.setState({ isDownloading: true });
    const pageWidth = 960;
    const pageHeight = 1357;
    let pdf = new jsPDF('p', 'px', 'a4', true);
    pdf.setFillColor('#ffffff');

    let PDFPagesHtml = [];
    let PDFSinglePageHtml = [];
    for (let i = 0; i < pdfSections.length; i++) {
      PDFPagesHtml.push(PDFSinglePageHtml);
      PDFSinglePageHtml = [];
      PDFSinglePageHtml.push(pdfSections[i]);

      if (i === pdfSections.length - 1 && PDFSinglePageHtml.length > 0) {
        PDFPagesHtml.push(PDFSinglePageHtml);
      }
    }

    for (let i = 0; i < PDFPagesHtml.length; i++) {
      if (i > 1) {
        await pdf.addPage('a4', 'p');
      }
      for (let f = 0; f < PDFPagesHtml[i].length; f++) {
        document
          .getElementById('downloadReport')
          .appendChild(PDFPagesHtml[i][f].cloneNode(true));
        updateProgress(i);
      }
      const html = document.getElementById('downloadReport');
      const canvas = await html2canvas(html, {
        width: pageWidth,
        height: pageHeight,
        x: 0,
        y: 0,
        windowWidth: pageWidth,
        windowHeight: pageHeight,
      });
      let canvasHolder = document.createElement('div');
      canvasHolder.setAttribute('id', 'temporaryCanvas');
      document.body.appendChild(canvasHolder);
      canvasHolder.appendChild(canvas);
      let img = await canvas.toDataURL('image.png');
      const imgProps = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      html.innerHTML = '';
      await pdf.addImage(
        img,
        'PNG',
        0,
        0,
        pdfWidth,
        pdfHeight,
        undefined,
        'FAST'
      );
      document.getElementById('temporaryCanvas').remove();
    }
    // document.getElementById("downloadReport").style.paddingTop = "";
    await pdf.save(this.state.fileName);
    document.getElementById('downloadReport').replaceChildren();
    boxShadows.forEach(function (el) {
      el.classList.add('box-shadow-no-export');
    });

    this.setState({ isDownloading: false, downloadProgress: 0 });
  };

  render() {
    const {
      isLocked,
      isLockReportEnabled,
      clientCandidateAssissmentMappingId,
    } = this.props;
    return (
      <div>
        {this.state.isLoaded ? (
          <div>
            <Zoomer
              isLocked={isLocked}
              isLockReportEnabled={isLockReportEnabled}
              clientCandidateAssissmentMappingId={
                clientCandidateAssissmentMappingId
              }
              download={this.handleDownload}
              downloadProgress={this.state.downloadProgress}
              targetId="dvmt"
            >
              <div id="dvmt" className="reportContainer">
                <div className="pdfSections box-shadow-no-export cover">
                  <div className="container">
                    <img src={assetsBase64.banner} className="banner" />
                    <div className="content">
                      <div className="section">
                        <img src={assetsBase64.logo} />
                      </div>
                      <div className="section">
                        <h1>Modern Lawyer Competency Report</h1>
                        <div className="divider"></div>
                        <h2>
                          <strong>Prepared For:</strong>{' '}
                          {this.props.data.user.first_name}{' '}
                          {this.props.data.user.last_name}
                        </h2>
                      </div>
                      <div className="section">
                        <h3>&copy; 2023 Thine Solutions, Inc.</h3>
                        <h3>
                          <strong>Date:</strong>{' '}
                          {moment(this.props.data.user.completed_dt).format(
                            'MM.DD.YYYY'
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Page 1 */}
                {/* Page intro copy with headers */}
                <div className="pdfSections box-shadow-no-export">
                  <Header pageNum="1" />
                  <div className="page-section">
                    <div className="text-block">
                      <h4>Modern Lawyer Competency Framework</h4>
                      <p>
                        Every lawyer has their own unique set of professional
                        strengths and talents. These strengths, and the
                        behaviors that accompany them, are often referred to as
                        professional competencies. Competencies can be
                        manifested in different ways by different people, and
                        there is no single, correct way that lawyers should show
                        up in the workplace. However, understanding the various
                        elements of professional competencies and how you embody
                        them will empower you to identify those resources that
                        can help accelerate your growth and enable you to be
                        more intentional around the behaviors you exhibit,
                        leading to greater success and increased job
                        satisfaction.
                      </p>

                      <p>
                        This report measures the 22 professional competencies
                        comprising the Modern Lawyer Competency Framework
                        (MLCF). The MLCF was developed in consultation with
                        industrial & organizational psychologists, legal
                        professionals, and academics leveraging extensive
                        research into those workplace behaviors essential to
                        high performing lawyers. Your results are shown on an
                        individual competency basis, measuring for how
                        frequently or strongly you engage in certain behavior,
                        as well as organized into seven "dimensions", or
                        clusters of related competencies.
                      </p>

                      <h5>Recognizing and Reflecting</h5>
                      <p>
                        We encourage you to view the report in its entirety.
                        Look for patterns. Consider how your strengths interact
                        and how you can leverage them at work. You may find
                        certain insights align with how you believe you show up
                        in the workplace and others that surprise you. Take note
                        of any areas that resonate with you, as they can
                        highlight a starting point for further reflection or
                        conversation.
                      </p>

                      <h5>Charting your Path Forward</h5>
                      <p>
                        The assessment and report were developed to stimulate
                        self-awareness and support you in designing and pursuing
                        your personal vision of career success. Every lawyer
                        embraces each competency to different extents and in
                        different ways throughout their career. As you go
                        through the report, consider your unique strengths and
                        how you can best leverage them as well as the areas
                        providing the greatest potential for growth and what
                        that could look like.
                      </p>
                      <h5>
                        Collaborating with Friends, Mentors, Coaches, and Other
                        Resources
                      </h5>
                      <p>
                        It may be helpful to talk through your results with
                        someone who knows you personally, including a trusted
                        colleague, or with a mentor, coach, or other internal or
                        external resource focused on your professional
                        development.
                      </p>
                    </div>
                  </div>
                  <Footer
                    pageNum="1"
                    name={
                      this.props.data.user.first_name +
                      ' ' +
                      this.props.data.user.last_name
                    }
                  />
                </div>

                {/* Page: table of contents */}
                <div className="pdfSections box-shadow-no-export">
                  <Header />
                  <div className="text-block">
                    <h4>What is Included in the Report</h4>
                    <div className="table-of-contents">
                      <div className="toc-row">
                        <span className="col-left">Dimension Overview</span>
                        <span className="col-right">3</span>
                      </div>
                      <div className="toc-row">
                        <span className="col-left">Competency Overview</span>
                        <span className="col-right">4</span>
                      </div>
                      <div className="toc-row">
                        <span className="col-left">Key Competencies</span>
                        <span className="col-right">5</span>
                      </div>
                      <div className="toc-row">
                        <span className="col-left">Core Strengths</span>
                        <span className="col-right">6</span>
                      </div>
                      <div className="toc-row">
                        <span className="col-left">Growth Opportunities</span>
                        <span className="col-right">7</span>
                      </div>
                      <div className="toc-row">
                        <span className="col-left">
                          Building on Your Results
                        </span>
                        <span className="col-right">8</span>
                      </div>
                    </div>
                  </div>
                  <DimensionPanes dimensions={this.props.data.dimensionDefs} />
                  <Footer
                    pageNum="2"
                    name={
                      this.props.data.user.first_name +
                      ' ' +
                      this.props.data.user.last_name
                    }
                  />
                </div>

                {/* Dimension behaviors
                <div className="pdfSections box-shadow-no-export">
                  <Header />
                  <div className="text-block">
                    <h4>Dimension Behaviors</h4>
                    <p>
                      The following section provides an overview of the typical
                      behaviors of lawyers that are high and low in each
                      dimension.
                    </p>
                  </div>
                  <DimensionBehaviors data={this.props.data.dimensionDefs} />

                  <Footer
                    pageNum="3"
                    name={
                      this.props.data.user.first_name +
                      " " +
                      this.props.data.user.last_name
                    }
                  />
                </div> */}

                {/* Page 3 */}
                <div className="pdfSections box-shadow-no-export">
                  <Header />
                  <div
                    className="text-block"
                    style={{ paddingBottom: '0px', paddingTop: '0px' }}
                  >
                    <div className="text-block-bottom">
                      <h4>Dimension Overview</h4>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <DimensionChart
                      dimensions={this.props.data.candidateDimensions}
                    />

                    <p style={{ marginLeft: '48px' }}>
                      This figure illustrates how you present on the various
                      competencies when viewed at the dimension level. The
                      larger the slice, the more you indicated you lean into
                      these types of behaviors at work. The key below provides
                      additional context about each of the Dimensions, and the
                      types of behaviors they encompass.
                    </p>
                  </div>
                  <DimensionsKey
                    dimensions={this.props.data.candidateDimensions}
                  />
                  <CandidateDimensions
                    dimensions={this.props.data.candidateDimensions}
                  />
                  <Footer
                    pageNum="3"
                    name={
                      this.props.data.user.first_name +
                      ' ' +
                      this.props.data.user.last_name
                    }
                  />
                </div>

                {/* Page 4  */}
                <div className="pdfSections box-shadow-no-export">
                  <Header />
                  <div
                    className="text-block"
                    style={{ paddingBottom: '0px', paddingTop: '0px' }}
                  >
                    <h4>Competency Overview</h4>
                    <p>
                      The following illustrates your self indicated level of
                      mastery of each of the 22 competencies categorized as an
                      opportunity, proficiency, or core strength.
                    </p>
                  </div>
                  <CompetencyOverview
                    competencies={this.props.data.highlights}
                    dimensions={this.props.data.dimensionDefs}
                  />

                  <Footer
                    pageNum="4"
                    name={
                      this.props.data.user.first_name +
                      ' ' +
                      this.props.data.user.last_name
                    }
                  />
                </div>

                <div className="pdfSections box-shadow-no-export">
                  <Header />
                  <div className="text-block text-block-bottom">
                    <h4>Key Competencies</h4>
                    <p>
                      The following identifies the two competencies that you are
                      highest in and the two competencies which present the
                      greatest opportunity for growth.
                    </p>

                    <p>
                      An overview of each of these competencies follows along
                      with a more in-depth analysis including the definition of
                      each competency, a breakdown of the specific elements of
                      that competency (known as sub-competencies), and questions
                      to reflect on. As you review this information, reflect on
                      how this information aligns with your expectations.
                    </p>
                  </div>

                  <HighlightPanel
                    label="Your Top Two Core Strengths"
                    data={this.props.data.coreStrengths.slice(0, 2)}
                    blurbText="How have you seen your strengths show up at work? How have your strengths enabled you to succeed? Have your strengths ever been an obstacle at work?"
                  />
                  <HighlightPanel
                    label="Your Top Two Opportunities for Growth"
                    data={this.props.data.growthOpportunities
                      .slice(0, 2)
                      .sort((a, b) => b.individualScore - a.individualScore)}
                    blurbText="Do these opportunities align with your expectations? How have these competencies served you? Are there obstacles that have impacted growth in these areas?"
                  />

                  <Footer
                    pageNum="5"
                    name={
                      this.props.data.user.first_name +
                      ' ' +
                      this.props.data.user.last_name
                    }
                  />
                </div>

                <Highlights
                  strengths={this.props.data.coreStrengths.slice(0, 2)}
                  opportunities={this.props.data.growthOpportunities
                    .slice(0, 2)
                    .sort((a, b) => b.individualScore - a.individualScore)}
                  name={
                    this.props.data.user.first_name +
                    ' ' +
                    this.props.data.user.last_name
                  }
                  startPage={6}
                />

                {/* Plan matrix */}
                {/* <div className="pdfSections box-shadow-no-export">
                  <Header />
                  <div className="text-block text-block-bottom">
                    <h4>Creating a Plan</h4>
                    <p>
                      This report can serve as a jumping off point for
                      professional development. Identify if there are
                      competencies or areas of behaviors that you want to focus
                      on in the coming days, weeks, and months. Use the space
                      below to jot down some of your ideas for how you might
                      approach growing in areas that feel relevant and important
                      to your career path and long-term goals.
                    </p>
                  </div>
                  <PlanMatrix />

                  <Footer
                    pageNum="9"
                    name={
                      this.props.data.user.first_name +
                      " " +
                      this.props.data.user.last_name
                    }
                  />
                </div> */}

                <div className="pdfSections box-shadow-no-export">
                  <Header />
                  <div className="text-block">
                    <h4>Building on Your Results</h4>
                    <p>
                      The space below can be used to free flow or jot down
                      ideas, reflections, and goals that surfaced while looking
                      at or debriefing your Modern Lawyer Competency Report. We
                      have included a few sample questions below to guide you in
                      this process.
                    </p>
                    <p>
                      <p>
                        <strong>
                          Are there competencies you plan to leverage? How?
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Are there any areas or competencies you identified
                          where you want to enhance your skills or develop new
                          or different approaches?
                        </strong>
                      </p>
                      <p>
                        <strong>
                          What support might you need to enable you to
                          accomplish your goals?
                        </strong>
                      </p>
                    </p>
                  </div>
                  <NotesBlock />
                  <Footer
                    pageNum="8"
                    name={
                      this.props.data.user.first_name +
                      ' ' +
                      this.props.data.user.last_name
                    }
                  />
                </div>

                <div id="downloadReport" className="reportContainer"></div>
              </div>
            </Zoomer>
          </div>
        ) : null}
      </div>
    );
  }
}

const Header = () => {
  return (
    <div className="header">
      <img className="logo" src={assetsBase64.logo} />
      <span>Modern Lawyer Competency Report</span>
    </div>
  );
};

const Footer = props => {
  return (
    <div className="footer">
      <span>
        Thine | {props.name} | {props.pageNum}
      </span>
    </div>
  );
};

const ParagraphRender = props => {
  if (!props.sectionName) {
    return null;
  }

  const paragraphs = staticText[props.sectionName].map(p => {
    return <p>{p}</p>;
  });

  return <>{paragraphs}</>;
};

const DimensionDefs = props => {
  const children = props.dimensions.map((dim, i) => {
    const bgClassName = 'bg-' + dim.color;
    const textClassName = 'text-' + dim.color;
    return (
      <div key={i + 1} className="dimension-definition">
        <div className={'icon ' + bgClassName}>
          <span className={dim.icon}></span>
        </div>
        <div className="text">
          <span className={'name ' + textClassName}>{dim.name}:</span>{' '}
          {dim.definition}
        </div>
      </div>
    );
  });

  return <div className="fw-stack">{children}</div>;
};

// const CandidateDimensions = (props) => {
//   const children = props.dimensions.map((dim, i) => {
//     const widthProp =
//       dim.individualScore < 10
//         ? MIN_BAR_WIDTH + "%"
//         : dim.individualScore + "%";
//     const normWidth = dim.populationScore + "%";
//     const bgClassName = "bg-" + dim.color;
//     return (
//       <div key={i + 1} className="dimension-meters">
//         <div className="text-contain">
//           <div className="label" style={{ color: newColorMap[dim.color] }}>
//             {dim.name}
//           </div>
//           <div className="description">{dim.candidateText}</div>
//         </div>
//       </div>
//     );
//   });

//   return <div className="fw-stack">{children}</div>;
// };

const CompetencyBreakdowns = props => {
  if (!props.dimensions) {
    return null;
  }

  const competencies = [];
  props.dimensions.forEach(dimension => {
    dimension.children.forEach(competency => {
      competencies.push(competency);
    });
  });

  competencies.sort((a, b) => b.individualScore - a.individualScore);
  const coreStrengths = competencies.slice(0, 2);
  const growthOpportunities = competencies.slice(-2);

  return (
    <div className="fw-stack">
      <CompetencyGroup
        title="Your Top Two Core Strengths:"
        competencies={coreStrengths}
        blueText="How have you seen your strengths show up at work? How have your strengths enabled you to succeed? Have your strengths ever been an obstacle at work?"
      />
      <CompetencyGroup
        title="Your Top Two Growth Opportunities for Growth: "
        competencies={growthOpportunities}
        blueText="Do these opportunities align with your expectations? How have these competencies served you? Are there obstacles that have impacted growth in these areas?"
      />
    </div>
  );
};

const CompetencyGroup = ({ title, competencies, blueText }) => {
  return (
    <div className="competency-group-container">
      <div className="title">{title}</div>
      <div className="sub-container">
        <div className="left">
          {competencies.map(competency => {
            return (
              <div className="competency">
                <div className="left-block">
                  <div style={{ color: competency.color }}>BG</div>
                  <div className="title">{competency.indicatorName}</div>
                </div>
                <div className="right-block">
                  <div>{competency.individualScore}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="right">{blueText}</div>
      </div>
    </div>
  );
};

const Highlight = props => {
  const { highlight } = props;

  const bgClass = 'bg-' + highlight.color;
  const widthProp =
    highlight.individualScore < 10
      ? MIN_BAR_WIDTH + '%'
      : highlight.individualScore + '%';
  const normWidth = highlight.populationScore + '%';

  const children = highlight.children.map((sub, i) => {
    const childWidthProp =
      sub.individualScore < 10
        ? MIN_BAR_WIDTH + '%'
        : sub.individualScore + '%';
    const childNormWidth = sub.populationScore + '%';
    return (
      <div className="subcomp" key={i + 1}>
        <div className="subcomp-title">{sub.indicatorName}</div>
        <div className="subcomp-bar">
          <div
            className={'subcomp-bar-inner ' + bgClass}
            style={{ width: childWidthProp }}
          ></div>

          <div className="norm-marker" style={{ width: childNormWidth }}></div>
        </div>
        <div className="subcomp-level">{sub.indicatorDescription}</div>
      </div>
    );
  });

  const actionList = highlight.actions.map((action, i) => {
    return (
      <li key={i + 1}>
        <span>{action}</span>
      </li>
    );
  });

  const reflectionList = highlight.reflections.map((reflection, i) => {
    return (
      <li key={i + 1}>
        <span>{reflection}</span>
      </li>
    );
  });

  return (
    <div className="competency-highlight">
      <div className="competency-highlight-header">
        <div className={'icon ' + bgClass}>
          <span className={highlight.icon}></span>
        </div>

        <div className="competency-highlight-title">
          {highlight.indicatorName}
        </div>
        <div className="competency-highlight-bar">
          <div
            className={'competency-highlight-bar-inner ' + bgClass}
            style={{ width: widthProp }}
          ></div>
          <div className="norm-marker" style={{ width: normWidth }}></div>
        </div>
        <div className="competency-highlight-level">{highlight.levelLabel}</div>
      </div>
      <div className="competency-highlight-description">
        <p>{highlight.indicatorDescription}</p>
      </div>
      <div className="competency-highlight-subcompetencies">
        <h4>Sub-competency Breakdown</h4>
        {children}
      </div>
      <div className="coaching-lists">
        <div className="coaching-list">
          <div className="title">For Reflection</div>
          <p>
            Below are some points or questions to reflect on either alone or
            with a coach.
          </p>
          <ul>{reflectionList}</ul>
        </div>
        <div className="coaching-list">
          <div className="title">For Action</div>
          <p>
            Below are some suggested actions you might consider to enhance in
            this area.
          </p>
          <ul>{actionList}</ul>
        </div>
      </div>
    </div>
  );
};

const Highlights = ({ strengths, opportunities, startPage, name = '' }) => {
  const START_PAGE = startPage || 6;

  const strengthsPageContent = strengths.map((item, i) => {
    return (
      <div className="competency-details" key={i + 1}>
        <CompetencyDetail
          item={item}
          reflections={item.reflections}
          actions={[]}
        />
      </div>
    );
  });
  const opportunitiesPageContent = opportunities.map((item, i) => {
    return (
      <div className="competency-details" key={i + 1}>
        <CompetencyDetail
          item={item}
          reflections={item.reflections}
          actions={[]}
        />
      </div>
    );
  });

  return (
    <>
      <div className="pdfSections box-shadow-no-export">
        <Header />
        {strengthsPageContent}
        <Footer pageNum={START_PAGE} name={name} />
      </div>
      <div className="pdfSections box-shadow-no-export">
        <Header />
        {opportunitiesPageContent}
        <Footer pageNum={START_PAGE + 1} name={name} />
      </div>
    </>
  );
  {
    /* <div className="competency-details">
          <CompetencyDetail
            item={item}
            reflections={reflections}
            actions={[]}
          />
        </div> */
  }
};

const WhatsIncludedSection = () => {
  const data = [
    [
      'Your Dimension Overview',
      'Overview of how you likely show up in each of our seven dimension',
      'Insight into the types of behaviors covered in each dimension',
    ],
    [
      'Your Dimension Overview II',
      'Overview of the behaviors typically associated with lawyers that are high and low in each dimension',
      'Indication of the behaviors that your responses best align with',
    ],
    [
      'Your Competency Overview',
      'Illustration of each dimension and its associated competencies',
      'Measurement of your self-indentified level of mastery with each competency and dimension, categorized as a core strength, proficiency, or opportunity',
    ],
    [
      'Your Developmental Guide',
      'Opportunity to select your core strengths, or areas of interest and how you plan to lean into these areas',
      'Space to indicate which, if any, areas you might focus your growth and development and the steps or actions you plan to take in order to do this',
    ],
  ];

  return (
    <div className="text-block">
      <h4>What is Included in the Report</h4>

      {data.map(([title, ...points]) => {
        return (
          <div key={title} style={{ paddingBottom: '20px' }}>
            <Typography sx={{ fontWeight: 'bold' }} variant="h6">
              {title}
            </Typography>
            <ul style={{ listStyleType: 'unset', listStylePosition: 'inside' }}>
              {points.map(point => (
                <li key={point}>{point}</li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Developmental;
