export function RelationshipsAndTeamwork({
  width = 32,
  height = 32,
  x = 0,
  y = 0,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="15.5" fill="#F3F6F9" stroke="#BFD5D9" />
      <path
        d="M15.1884 18.7825C14.5592 18.7826 13.9543 18.5391 13.5007 18.1031C13.0471 17.6671 12.7799 17.0723 12.7551 16.4436C12.7304 15.8149 12.95 15.201 13.3679 14.7306C13.7859 14.2603 14.3697 13.97 14.997 13.9206C15.0605 13.9156 15.1249 13.9131 15.1884 13.9131V12.29C15.1881 12.0153 15.2579 11.7452 15.3913 11.5051C15.3251 11.4876 15.2569 11.4787 15.1884 11.4784H12.5347C12.7001 11.1942 12.7748 10.8662 12.7485 10.5384C12.7154 10.1203 12.5217 9.73128 12.2081 9.45283C11.8945 9.17439 11.4852 9.02814 11.0662 9.04475C10.6471 9.06135 10.2507 9.23952 9.96009 9.54191C9.66947 9.8443 9.50719 10.2474 9.50724 10.6668C9.50724 10.9519 9.58278 11.232 9.72617 11.4784H7.07246C6.85721 11.4784 6.65078 11.5639 6.49857 11.7161C6.34637 11.8683 6.26086 12.0747 6.26086 12.29V20.4057C6.26086 20.6209 6.34637 20.8273 6.49857 20.9795C6.65078 21.1317 6.85721 21.2172 7.07246 21.2172H9.72617C9.56073 21.5015 9.48611 21.8294 9.51231 22.1572C9.54544 22.5753 9.73912 22.9644 10.0528 23.2428C10.3664 23.5213 10.7756 23.6675 11.1947 23.6509C11.6138 23.6343 12.0102 23.4561 12.3008 23.1537C12.5914 22.8513 12.7537 22.4482 12.7536 22.0288C12.7536 21.7437 12.6781 21.4637 12.5347 21.2172H15.1884C15.2569 21.217 15.3251 21.208 15.3913 21.1906C15.2579 20.9505 15.1881 20.6803 15.1884 20.4057V18.7825Z"
        fill="#6690B6"
      />
      <path
        d="M24.9275 11.4782H22.2738C22.4392 11.7624 22.5138 12.0904 22.4876 12.4182C22.4545 12.8363 22.2608 13.2254 21.9472 13.5038C21.6335 13.7823 21.2243 13.9285 20.8052 13.9119C20.3861 13.8953 19.9898 13.7171 19.6992 13.4147C19.4085 13.1123 19.2462 12.7092 19.2463 12.2898C19.2463 12.0047 19.3218 11.7247 19.4652 11.4782H16.8115C16.5963 11.4782 16.3898 11.5637 16.2376 11.7159C16.0854 11.8681 15.9999 12.0746 15.9999 12.2898V14.9434C15.7158 14.7781 15.3879 14.7034 15.0601 14.7295C14.6418 14.7623 14.2524 14.9558 13.9737 15.2695C13.695 15.5832 13.5485 15.9926 13.5651 16.4118C13.5817 16.8311 13.76 17.2277 14.0626 17.5183C14.3653 17.809 14.7687 17.9711 15.1883 17.9708C15.4735 17.9708 15.7535 17.8953 15.9999 17.7519V20.4055C15.9999 20.6208 16.0854 20.8272 16.2376 20.9794C16.3898 21.1316 16.5963 21.2171 16.8115 21.2171H19.4652C19.2998 20.9329 19.2252 20.6049 19.2514 20.2771C19.2845 19.859 19.4782 19.47 19.7918 19.1915C20.1054 18.9131 20.5147 18.7668 20.9338 18.7834C21.3528 18.8 21.7492 18.9782 22.0398 19.2806C22.3304 19.583 22.4927 19.9861 22.4927 20.4055C22.4927 20.6906 22.4171 20.9707 22.2738 21.2171H24.9275C25.1427 21.2171 25.3491 21.1316 25.5013 20.9794C25.6536 20.8272 25.7391 20.6208 25.7391 20.4055V12.2898C25.7391 12.0746 25.6536 11.8681 25.5013 11.7159C25.3491 11.5637 25.1427 11.4782 24.9275 11.4782Z"
        fill="#00629B"
      />
    </svg>
  );
}
