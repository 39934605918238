import _ from 'lodash';
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';

function descendingComparator(a, b, orderBy, columns) {
  const getValue = _.find(columns, { id: orderBy })?.getValue;
  const A =
    typeof getValue(a) === 'string' ? getValue(a).toUpperCase() : getValue(a); // ignore upper and lowercase
  const B =
    typeof getValue(b) === 'string' ? getValue(b).toUpperCase() : getValue(b); // ignore upper and lowercase
  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, columns) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, columns)
    : (a, b) => -descendingComparator(a, b, orderBy, columns);
}

function SortableTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: '#5d2a5f' }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            sx={{
              color: '#fff',
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {columns.map((column, index) => (
          <TableCell
            sx={{
              paddingTop: 2,
              paddingBottom: 2,
            }}
            key={column.label}
            align="left"
            padding="none"
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.id && (
              <TableSortLabel
                sx={{
                  color: '#fff !important',
                  '&:hover': {
                    color: '#fff!important',
                  },
                  '&.Mui-active': {
                    color: '#fff !important',
                  },
                  '&.Mui-active svg': {
                    color: '#fff !important',
                  },
                }}
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SortableTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const SortableTableToolbar = props => {
  const { numSelected, title, downloadAllEntriesAsCsv, children } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%', fontWeight: 'bold', color: '#202e43' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}
      {children}
    </Toolbar>
  );
};

SortableTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default function SortableTable(props) {
  const [order, setOrder] = React.useState(props.defaultOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(props.defaultOrderBy);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {
    columns,
    rows,
    title,
    downloadAllEntriesAsCsv,
    setSelectedItems,
    emptyTableMessage = 'No data present',
    showSortableTableToolbar = true,
  } = props;

  const sortedRows = rows
    .sort(getComparator(order, orderBy, columns))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleRequestSort = (event, property) => {
    if (!property) {
      return;
    }

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event ? event.target.checked : null) {
      const newSelecteds = rows.map((n, index) => index);
      setSelected(newSelecteds);
      props.setSelected(newSelecteds.length);
      setSelectedItems && setSelectedItems(newSelecteds);
      return;
    }
    setSelected([]);
    props.setSelected(0);
    setSelectedItems && setSelectedItems([]);
  };

  const handleClick = (event, index) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    props.setSelected(newSelected.length);
    const selectedRows = sortedRows.filter((item, index) => {
      const selected = newSelected.includes(index);
      return selected;
    });
    setSelectedItems && setSelectedItems(newSelected, selectedRows);
  };

  const handleChangePage = (event, newPage) => {
    handleSelectAllClick();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {showSortableTableToolbar && (
          <SortableTableToolbar
            numSelected={selected.length}
            title={title}
            downloadAllEntriesAsCsv={downloadAllEntriesAsCsv}
          >
            {props.children}
          </SortableTableToolbar>
        )}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <SortableTableHead
              columns={columns}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sortedRows.length == 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    {emptyTableMessage}
                  </TableCell>
                </TableRow>
              )}
              {sortedRows.map((row, index) => {
                const isItemSelected = isSelected(index);
                const labelId = `enhanced-table-checkbox-${index}`;
                const tableCells = columns.map(column => {
                  const { id, width, stopEventPropagation } = column;
                  const comp = column.render(row, index);

                  return id !== '_ROWINDEX_' ? (
                    <TableCell
                      key={id}
                      component="td"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        minWidth: width || 150,
                        maxWidth: width || 150,
                        wordWrap: 'break-word',
                      }}
                      align="left"
                      onClick={event => {
                        if (stopEventPropagation) {
                          event.stopPropagation();
                        }
                      }}
                    >
                      {comp}
                    </TableCell>
                  ) : null;
                });
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, index)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    {tableCells}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={() => {
            const remainderCheck = rows.length % rowsPerPage === 0 ? 0 : 1;
            return `${page + 1}/${Math.floor(rows.length / rowsPerPage) + remainderCheck}`;
          }}
        />
      </Paper>
    </Box>
  );
}
