import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const flexStyle = {
  display: 'flex',
  'flex-direction': 'row',
  'align-items': 'top',
  'justify-content': 'flex-start',
};

export default function TextInput({
  required,
  name,
  label,
  value,
  type,
  onChange,
  placeholder,
  inputProps,
}) {
  return (
    <Box sx={{ m: 1 }}>
      <div style={flexStyle}>
        <TextField
          id={name}
          name={name}
          label={label}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          InputProps={{ inputProps }}
        />
        {required ? (
          <b style={{ color: 'red', paddingLeft: '10px', fontSize: '14pt' }}>
            *
          </b>
        ) : null}
      </div>
    </Box>
  );
}
