import _ from 'lodash';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BasicControls from '../../Components/BasicControls';
import BasicSelect from '../../Components/Inputs/BasicSelect';
import BasicTextInput from '../../Components/Inputs/BasicTextInput';
import EditModal from '../../Components/EditModal';
import { put as putUser } from '../../services/user';
import { isValidEmail } from '../../helper/helper.js';

const userStatus = [
  { value: 'active', displayText: 'Active' },
  { value: 'inactive', displayText: 'Inactive' },
];

const UpdateUserModal = ({
  handleCancel,
  title,
  id,
  setIsRowUpdated,
  row,
  isAdmin,
  firm,
}) => {
  const [user, setUser] = React.useState({
    emailAddr: row.emailAddr || '',
    firstName: row.firstName || '',
    lastName: row.lastName || '',
    status: row.status || 'active',
    isDailyEmailEnabled: row.isDailyEmailEnabled,
    isCoach: row.isCoach,
  });
  const [responseMessage, setResponseMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [responseSeverity, setResponseSeverity] = React.useState('success');
  const [responseStatus, setResponseStatus] = React.useState([-1]);

  useEffect(() => {
    if (responseStatus[0] === -1) return;

    if (responseStatus[0] !== 200) {
      setResponseMessage('Failed to submit user');
      setResponseSeverity('error');
    } else {
      setResponseMessage('User updated successfully');
      setResponseSeverity('success');
      setIsRowUpdated([1]);
    }

    setSnackbarOpen(true);
  }, [responseStatus, setIsRowUpdated]);

  return (
    <EditModal handleCancel={handleCancel} title={title}>
      <div id="updateTicketModal">
        <BasicTextInput
          title={'Enter Email'}
          placeholder={'Enter Email'}
          type="email"
          value={user.emailAddr}
          onChange={e => setUser({ ...user, emailAddr: e.target.value })}
        />
        <BasicTextInput
          title={'Enter First Name'}
          placeholder={'Enter First Name'}
          value={user.firstName}
          onChange={e => setUser({ ...user, firstName: e.target.value })}
        />
        <BasicTextInput
          title={'Enter Last Name'}
          placeholder={'Enter Last Name'}
          value={user.lastName}
          onChange={e => setUser({ ...user, lastName: e.target.value })}
        />
        <BasicSelect
          options={userStatus}
          title="Status"
          value={user.status}
          onChange={e => setUser({ ...user, status: e.target.value })}
        />
        <FormControlLabel
          label="Is daily email enabled ?"
          control={
            <Checkbox
              checked={user.isDailyEmailEnabled}
              onChange={event => {
                setUser({ ...user, isDailyEmailEnabled: event.target.checked });
              }}
              inputProps={{
                'aria-label': 'select for is daily email enabled',
              }}
            />
          }
        />
        {firm.coachFeatureEnabled && (
          <FormControlLabel
            label="Is a coach ?"
            control={
              <Checkbox
                checked={user.isCoach}
                onChange={event => {
                  setUser({ ...user, isCoach: event.target.checked });
                }}
                inputProps={{
                  'aria-label': 'select for is a coach',
                }}
              />
            }
          />
        )}
        <BasicControls
          onCancel={handleCancel}
          onClick={async () => {
            if (!user.emailAddr || !user.firstName) {
              setResponseMessage(
                'Failed to submit user, email and first name cannot be empty'
              );
              setResponseSeverity('error');
              setSnackbarOpen(true);
              return;
            }
            if (!isValidEmail(user.emailAddr)) {
              setResponseMessage(
                'Failed to submit user, please enter a valid email address'
              );
              setResponseSeverity('error');
              setSnackbarOpen(true);
              return;
            }

            const response = await putUser(id, user);
            setResponseStatus([response.status]);
          }}
          message={responseMessage}
          snackbarOpen={snackbarOpen}
          setSnackbarOpen={setSnackbarOpen}
          severity={responseSeverity}
          style={{ mb: 0 }}
        />
      </div>
    </EditModal>
  );
};

const mapStateToProps = state => {
  return {
    firm: state.firm,
  };
};

export default connect(mapStateToProps, null)(UpdateUserModal);
