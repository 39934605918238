import { DimensionCategory } from '../types';
import { displayDimensionName } from '../utils';

type Props = {
  index: number;
  category: DimensionCategory;
};

export function DimensionBehaviorsSection({ category, index }: Props) {
  return (
    <div className="dimension-behavior-section">
      <div className="category-name">
        <h4>{category.name}</h4>
        {index === 0 && <p>Typical Behaviors:</p>}
      </div>
      <div className="dimension-color-bars">
        {category.dimensions.map((dimension, index) => (
          <div
            key={index}
            className="dimension-color-bar"
            style={{ backgroundColor: dimension.hex_color }}
          />
        ))}
      </div>

      {category.dimensions.map((dimension, index) => (
        <div key={index} className="dimension-behavior-row">
          <div className="col-left">
            <img
              src={dimension.icon_url}
              alt={displayDimensionName(dimension.name)}
            />
            <h5 className="dimension-name">
              {displayDimensionName(dimension.name)}
            </h5>
          </div>
          <ul className="col-right">
            {dimension.typical_behaviors.map((dbb, idx) => (
              <li key={idx}>{dbb}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}
