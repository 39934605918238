import { Divider, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const SubmittedMessage = () => {
  return (
    <Box sx={{ maxWidth: 1200, p: 4 }}>
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 700 }}>
        Thank you for completing Thine's Competency Selection Survey!
      </Typography>
      <Typography variant="h6">
        You insight has been invaluable in the creation of your firm's unique
        algorithm. we will compile all of the survey submissions at your firm.
        The eight competencies that have the most consensus across all of the
        submitted surveys will form the basis of your initial algortihm. We will
        present this to you and allow you to make changes as needed.
        <br />
        <br />
        Please reach out to{' '}
        <Link href="mailto:maryclaire.jones@thine.co">
          maryclaire.jones@thine.co
        </Link>{' '}
        with any questions, comments or concerns.
      </Typography>
      <br />
      <Divider />
    </Box>
  );
};

export default SubmittedMessage;
