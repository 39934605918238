import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useState, useEffect } from 'react';

export default function QstSlider(props) {
  console.log(props?.defaultValue);
  const [answered, setAnswered] = useState(props.answered);
  const [section, setSection] = useState(props.section);
  const [answerUpdatedValue, setAnswerUpdatedValue] = useState(null);
  const [answerDefaultValue, setAnswerDefaultValue] = useState(
    props.answeredIndex
  );
  const [answerValue, setAnswerValue] = useState(null);

  useEffect(() => {
    // workaround for props not accurately passing in right away on first render
    console.log('props.defaultValue', props);
    if (!answerUpdatedValue) {
      setAnswerValue(props.defaultValue);
    } else {
      setAnswerValue(answerUpdatedValue);
    }
  }, [props.answered]);

  const handleChange = (e, va) => {
    // handle onmouseup and change events for kb and mouse navigation
    console.log('va=>>', va);
    const { type } = e;
    // const { value } = e.target;
    console.log('e', e.target);
    const value = va ?? props.defaultValue;
    console.log(type + ' ' + value);
    if (value) {
      setAnswerUpdatedValue(value);
      props.handleSlide(value - 1); // takes array index
      setAnswerValue(value);
    }
  };

  const isDesktop = window.innerWidth > 960;
  const adjustedContainerWidth = isDesktop ? props.width : '100vw!important';
  const adjustedQuestionWidth = isDesktop
    ? props.width * 0.95
    : '76vw!important';
  const sideMarginMobile = '12vw';

  const screenReaderLabel =
    props.accessibilityTitle + ' ' + '1: ' + props.left + ' 5: ' + props.right;
  const marks = [
    {
      value: 1,
      label: props.left,
    },
    {
      value: 2,
      label: '',
    },
    {
      value: 3,
      label: '',
    },
    {
      value: 4,
      label: '',
    },
    {
      value: 5,
      label: props.right,
    },
  ];

  let labelStyles = {
    width: '25%',
    transformLeft: 'translateX(-50%) !important',
    transformRight: 'translateX(-50%) !important',
  };

  if (section === 3) {
    labelStyles.width = '40%';
  }

  const handleOnThumbClick = () => {
    console.log('click on thumb', props);
    if (props.className && answerValue == 3) {
      props.handleSlide(answerValue - 1); // takes array index
    }
  };

  return (
    <Box
      sx={{
        width: adjustedContainerWidth,
        marginBottom: 5,
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Slider
        className={props.className || ''}
        size="medium"
        name="question-slider"
        sx={{
          width: adjustedQuestionWidth,
          marginTop: props.section === 3 ? '30px' : '',
          marginLeft: isDesktop ? '37.5px' : sideMarginMobile,
          boxSizing: 'border-box',
          color: '#b9dff4',
          '& .MuiSlider-markLabel': {
            color: '#000',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            width: '0%',
          },
          "& .MuiSlider-markLabel[data-index='0']": {
            transform: labelStyles.transform,
            textAlign: 'center',
            maxWidth: labelStyles.width,
            width: labelStyles.width,
            minWidth: labelStyles.width,
            marginLeft: section === 3 ? '10%' : '',
          },
          "& .MuiSlider-markLabel[data-index='4']": {
            transform: labelStyles.transform,
            textAlign: 'center',
            minWidth: labelStyles.width,
            maxWidth: labelStyles.width,
            marginLeft: section === 3 ? '-10%' : '',
          },
          '& .MuiSlider-markLabelActive': {
            width: '25%',
            color: '#000',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          },
          '& .MuiSlider-mark': {
            color: '#eee',
            backgroundColor: '#eee',
            width: 4,
            height: 4,
          },
          '& .MuiSlider-track': {
            color: '#b9dff4',
            backgroundColor: '#b9dff4',
          },
          '&.unanswered .MuiSlider-track': {
            color: '#bdbdbd',
            backgroundColor: '#bdbdbd',
          },
          '& .MuiSlider-rail': {
            color: '#64c4f9',
            height: 6,
            backgroundColor: '#64c4f9',
          },
          '&.unanswered .MuiSlider-rail': {
            color: '#5e5e5e',
            height: 6,
            backgroundColor: '#5e5e5e',
          },
          '& .MuiSlider-thumb': {
            color: '#b9dff4',
            backgroundColor: '#b9dff4',
          },
          '&.unanswered .MuiSlider-thumb': {
            color: '#bdbdbd',
            backgroundColor: '#bdbdbd',
          },
        }}
        aria-labelledby={`input-slider`}
        aria-label={screenReaderLabel}
        min={+props.min}
        max={+props.max}
        componentsProps={{
          thumb: {
            onClick: handleOnThumbClick,
          },
        }}
        defaultValue={props?.defaultValue}
        value={answerValue} // see earlier comments below on mouse vs keyboard nav value change event
        onChange={handleChange}
        // onChangeCommitted={handleChange}
        step={1}
        marks={marks}
      />
    </Box>
  );
}
