import React from 'react';

import { newColorMap } from './constants';

const HighlightPanel = props => {
  // take an array of objects (label, data object, blurbText), color map from parent component
  const children = props.data.map((row, i) => {
    const colorClass = 'bg-' + row.color;
    const difference = 100 - row.individualScore;
    const donutLabel = Math.floor(row.individualScore);
    const dashArray = row.individualScore + ' ' + difference;
    return (
      <div className="competency-chip" key={i + 1}>
        <div className={'color-block ' + colorClass}> </div>
        <div className="label">{row.indicatorName}</div>
        <div className="donut-contain">
          <svg>
            <defs>
              <style>
                @import
                url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
              </style>
            </defs>
            <circle
              cx="50%"
              cy="50%"
              r="40"
              strokeWidth="10"
              stroke={newColorMap[row.color]}
              fill="none"
              pathLength="100"
              strokeDasharray={dashArray}
              strokeDashoffset="0"
              strokeLinecap="butt"
            ></circle>
            <circle
              cx="50%"
              cy="50%"
              strokeWidth="1"
              stroke="#cccccc"
              fill="#ffffff"
              pathLength="100"
              r="35"
            ></circle>

            <circle
              cx="50%"
              cy="50%"
              strokeWidth="1"
              stroke="#cccccc"
              fill="none"
              pathLength="100"
              r="45"
            ></circle>
            <text
              x="50%"
              y="50%"
              dy=".3em"
              textAnchor="middle"
              style={{ 'font-family': 'Noto Sans, sans-serif' }}
            >
              {donutLabel}
            </text>
          </svg>
        </div>
      </div>
    );
  });
  // return wrapper with competency panes inside
  return (
    <>
      <div className="key-competency">
        <h5>{props.label}</h5>
        <div className="key-competency-inner">
          <div className="competency-stack">{children}</div>
          <div className="competency-blurb">{props.blurbText}</div>
        </div>
      </div>
    </>
  );
};

export default HighlightPanel;
