export function ReputationManagement({
  width = 32,
  height = 32,
  x = 0,
  y = 0,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="15.5" fill="#F3F6F9" stroke="#BFD5D9" />
      <rect
        x="6.76086"
        y="14.413"
        width="18.4783"
        height="5.26087"
        fill="#00629B"
        stroke="#6690B6"
      />
      <rect
        x="8.84784"
        y="12.3261"
        width="3.17391"
        height="1.08696"
        fill="#00629B"
        stroke="#6690B6"
      />
      <rect
        x="14.413"
        y="12.3261"
        width="3.17391"
        height="1.08696"
        fill="#00629B"
        stroke="#6690B6"
      />
      <rect
        x="19.9783"
        y="12.3261"
        width="3.17391"
        height="1.08696"
        fill="#00629B"
        stroke="#6690B6"
      />
    </svg>
  );
}
