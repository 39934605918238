import { Route } from 'react-router-dom';

import { DebugMain } from './DebugMain';
import { DebugAssignment } from './DebugAssignment';
import { DebugAssessment } from './DebugAssessment';
import React from 'react';
import { DebugClientCandidateMapping } from './DebugClientCandidateMapping';

// respect REACT_APP_FEATURE_ADMIN_DEBUG if it exists, else enable in development
const enableAdminDebugRoutes = true;

export function AdminDebugRoutes() {
  if (!enableAdminDebugRoutes) {
    return null;
  }

  return (
    <>
      <Route exact path="/admin/debug" component={() => <DebugMain />} />
      <Route
        exact
        path="/admin/debug/assignment/:assignment_id"
        component={DebugAssignment}
      />
      <Route
        exact
        path="/admin/debug/assessment/:assessment_id"
        component={DebugAssessment}
      />
      <Route
        exact
        path="/admin/debug/client_candidate_mapping/:client_candidate_mapping_id"
        component={DebugClientCandidateMapping}
      />
    </>
  );
}
