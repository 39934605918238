import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
} from 'recharts';

import { CompetencyOverviewChartData } from './dimensionDefsToData';
import { SectionHeader } from './SectionHeader';

const renderDonut =
  ({ radius, width }: { radius: number; width: number }) =>
  (props: any) => {
    // console.log("renderDonut", props)
    const { cx, cy } = props;
    if (props.name.startsWith('HEADER:')) return <></>;
    return (
      <>
        <circle
          cx={cx}
          cy={cy - 1}
          r={radius + width}
          fill={'#3D3D3D'}
          className="customized-shape"
        />
        <circle
          cx={cx}
          cy={cy - 1}
          r={radius}
          fill="#fff"
          className="customized-shape"
        />
      </>
    );
  };

type ChartProps = {
  data: CompetencyOverviewChartData[];
};

export function CompetencyOverviewChart({ data }: ChartProps) {
  return (
    <>
      <ComposedChart
        width={500}
        height={548}
        data={data}
        layout="vertical"
        margin={{ top: -16, right: 10, left: 0, bottom: 0 }}
      >
        <CartesianGrid horizontal={false} stroke="#ccc" strokeWidth={1.25} />

        <XAxis
          type="number"
          fontSize={'8px'}
          fontWeight={400}
          fontFamily={'Source Sans 3'}
          domain={[0, 100]}
          tickCount={6}
          orientation={'top'}
          tickSize={0}
          axisLine={false}
        />

        <YAxis
          dataKey="name"
          type="category"
          width={200}
          padding={{ top: -10, bottom: -2 }}
          tickSize={0}
          interval={0}
          stroke={'#ccc'}
          tick={props => <SectionHeader {...props} />}
        />

        <Bar stackId="a" dataKey="value" fill="#fbbf24" barSize={11}>
          {data.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.color} />;
          })}
        </Bar>
        <Bar stackId="a" dataKey="remainder" fill="rgba(238, 238, 238, 0.5)" />

        {/* currently we have no norms, but uncomment this when we do */}
        {/*<Scatter*/}
        {/*  dataKey="norm"*/}
        {/*  fill="white"*/}
        {/*  shape={renderDonut({ radius: 3.5, width: 1 })}*/}
        {/*/>*/}
      </ComposedChart>

      {/* currently we have no norms, but uncomment this when we do */}
      {/*<NormsLegend />*/}
    </>
  );
}

/**
 * print Legend with norms dot
 *
 * @constructor
 */
function NormsLegend() {
  return (
    <div className={'legend'}>
      <p>Norm:</p>
      <div
        style={
          {
            // width: '10px',
            // height: '10px',
            // backgroundColor: 'lightyellow',
          }
        }
      >
        {/*<p>groats</p>*/}
        <svg width={8} height={8}>
          <circle
            cx={4}
            cy={4}
            r={4}
            fill={'#202E43'}
            className="customized-shape"
          />
          <circle
            cx={4}
            cy={4}
            r={3}
            fill="#fff"
            className="customized-shape"
          />
        </svg>
      </div>
    </div>
  );
}
