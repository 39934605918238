export function PursuitOfExcellence({ width = 32, height = 32, x = 0, y = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="15.5" fill="#F3F6F9" stroke="#BFD5D9" />
      <path
        d="M23.2036 9.65256C22.8975 9.32734 22.4633 9.13985 22.0156 9.13985H20.5904C20.5961 8.88349 20.5999 8.61951 20.5999 8.34784H11.4078C11.4078 8.61948 11.4116 8.88349 11.4173 9.13985H9.98256C9.5349 9.13985 9.10065 9.32734 8.79457 9.65256C8.48463 9.97972 8.32396 10.4273 8.35072 10.8808C8.60707 15.1545 10.8128 17.8366 14.3098 18.2345L13.6326 20.5914C12.8272 20.5914 12.1749 21.2437 12.1749 22.0491V23.6522H19.8347V22.0491C19.8347 21.2437 19.1824 20.5914 18.377 20.5914L17.6998 18.2345C21.1892 17.8328 23.393 15.1526 23.6474 10.8808C23.6742 10.4293 23.5135 9.98162 23.2036 9.65256ZM9.87925 10.7889C9.87734 10.7468 9.89457 10.7181 9.90985 10.7028C9.93854 10.6722 9.97299 10.6722 9.98256 10.6722H11.4901C11.7177 13.6815 12.3815 15.3879 13.0262 16.3521C10.5718 15.3228 9.98637 12.5738 9.87925 10.7889ZM18.9815 16.3463C19.6262 15.3802 20.2881 13.6757 20.5158 10.6703H22.0175C22.029 10.6703 22.0615 10.6703 22.0902 10.7009C22.1055 10.7162 22.1227 10.7449 22.1208 10.787C22.0118 12.5719 21.4283 15.3133 18.9815 16.3463Z"
        fill="#00629B"
      />
    </svg>
  );
}
