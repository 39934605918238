import { Donut } from '../Donut';
import { displayCompetencyName } from '../utils';

type Props = {
  indicator_name: string;
  indicator_description: string;
  hex_color: string;
  reflections: string[];
  subcompetencies: {
    name: string;
    score: number;
  }[];
};

export function IndicatorPage({
  indicator_name,
  hex_color,
  indicator_description,
  subcompetencies,
  reflections,
}: Props) {
  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block dvmt2-indicators">
          <h4>{indicator_name}</h4>
          <p>{indicator_description}</p>

          <h5>Sub-competency Breakdown</h5>
          <div className={'sub-competency-charts-container'}>
            {subcompetencies
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map((sub, i) => (
                <div key={i} className="sub-competency-chart">
                  <div className={'sub-competency-chart-donut'}>
                    <Donut
                      value={sub.score}
                      radius={40}
                      stroke_width={8}
                      hex_color={hex_color}
                      empty_hex_color={'#F2F2F2'}
                      border_color={'#BFD5D9'}
                      text_color={'#3D3D3D'}
                      text_style={{
                        fontFamily: 'Catamaran',
                        fontSize: '18px',
                        fontWeight: 400,
                        letterSpacing: '0.18px',
                      }}
                    />
                  </div>
                  <div className="sub-competency-name">
                    {displayCompetencyName(sub.name)}
                  </div>
                </div>
              ))}
          </div>

          <h6>For Reflection</h6>
          <ul>
            {reflections.map((reflection, i) => (
              <li key={i}>{reflection}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
