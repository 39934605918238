import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import request from '../../RequestUtil.js';
import OCICandidate from './Reports/OCI';
import { withRouter } from 'react-router-dom';
import CandidateSteps from '../../Components/CandidateSteps';
import { assessmentValues } from '../../config/AppConst.js';
import moment from 'moment';

class CandidateResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      stage: 0,
      candidate_report_id: 0,
      assign_id: 0,
      fromAssessment: false,
    };

    this.showReport = this.showReport.bind(this);
  }

  componentDidMount() {
    request({
      method: 'post',
      responseType: 'json',
      url: '/api/candidate/results',
    })
      .then(response => {
        const receivedAssignment = new URLSearchParams(
          this.props.location.search
        ).get('assignment_id');
        if (receivedAssignment) {
          const row = response.data.find(
            x => x.assignment_id.toString() === receivedAssignment
          );
          if (row) {
            this.showReport({
              assessment_id: row.assessment_id,
              assign_id: row.assign_id,
              candidate_report_id: row.candidate_report_id,
            });
            this.setState({ result: response.data, fromAssessment: true });
          }
        } else {
          this.setState({ results: response.data, stage: 1 });
        }
      })
      .catch(err => console.log(err));
  }

  showReport(data) {
    this.setState({
      stage: 2,
      candidate_report_id: data.candidate_report_id,
      assign_id: data.assign_id,
      assessment_id: data.assessment_id,
    });
  }

  render() {
    let rows = this.state.results.map((row, i) => {
      return (
        <tr key={i + 1}>
          <td>{row.assessment_title}</td>
          <td>{row.client_name}</td>
          <td>{moment(row.completed_dt).format('M/DD/YYYY')}</td>
          <td>
            {
              // TODO - move entry level assessment to use is_report_locked only, on insertion in client_candidate_assessment_mapping, add default value to true
              assessmentValues.entryLevel.includes(row.assessment_type) ? (
                <button
                  className="button"
                  onClick={() =>
                    this.showReport({
                      assessment_id: row.assessment_id,
                      assign_id: row.assign_id,
                      candidate_report_id: row.candidate_report_id,
                    })
                  }
                >
                  View Report
                </button>
              ) : null
            }

            {!row.is_report_locked && (
              <Link
                to={{
                  pathname: `/firm/report/${row.id}`,
                }}
              >
                <button className="button cursor">View Report</button>
              </Link>
            )}
          </td>
        </tr>
      );
    });

    return (
      <div>
        {this.state.fromAssessment && <CandidateSteps step={3} />}
        {this.state.stage === 1 ? (
          <div>
            <h1>My Results</h1>
            <div className="card">
              <table>
                <thead>
                  <tr>
                    <th>Assessment</th>
                    <th>On Behalf Of</th>
                    <th>Completion Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        ) : null}

        {this.state.stage === 2 && this.state.candidate_report_id === 3 && (
          <OCICandidate
            assign_id={this.state.assign_id}
            assessment_id={this.state.assessment_id}
          />
        )}
      </div>
    );
  }
}

export default withRouter(CandidateResults);
