import React from 'react';

const DimensionBehaviors = props => {
  const rows = props.data.map((row, i) => {
    return (
      <div key={i + 1}>
        <div className="table-row">
          <div className="left">
            <span className={'text-' + row.color}>{row.name}</span>
          </div>
          <div className="center">
            <div>{row.lowDefinition}</div>
          </div>
          <div className="right">
            <div>{row.highDefinition}</div>
          </div>
        </div>
        <div className="pseudo-borders">
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="definition-table">
      <div className="definition-header">
        <div className="left"></div>
        <div className="center">
          <span>Typical Low Behaviors:</span>
        </div>
        <div className="right">
          <span>Typical High Behaviors:</span>
        </div>
      </div>
      <div className="definition-body">{rows}</div>
    </div>
  );
};

export default DimensionBehaviors;
