import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Tabs,
  Tab,
  Popover,
  Box,
  Divider,
  Select,
  Tooltip,
  tooltipClasses,
  TableSortLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Loader from '../../../Components/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import UpdateAssessmentModal from './UpdateAssessmentModal';
import ViewHistoryModal from './ViewHistoryModal';
import request from '../../../RequestUtil';
import LoadingButton from '@mui/lab/LoadingButton';
import BasicTextInput from '../../../Components/Inputs/BasicTextInput';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { visuallyHidden } from '@mui/utils';
import { downloadFile } from '../../../helper/helper';

const ITEM_HEIGHT = 48;
const DEFAULT_ASSESSMENT_VERSION = 101;

const stickyColumnStyle = {
  position: 'sticky',
  right: 0,
  minWidth: 50,
  backgroundColor: 'white',
};

// Your existing function
const createGroupedAssessment = assessments => {
  const result = [];

  assessments.forEach(item => {
    if (item.answers) {
      const newItem = {
        item_id: item.item_id,
        question_id: item.question_id,
        item_title: item.item_title || item.question_title,
        label_left: item.label_left,
        label_right: item.label_right,
        answer_id: item.answers[0].answer_id,
        score_value: item.answers[0].score_value,
        answer_title: item.answers[0].answer_title,
        indicator_name: item.answers[0].indicator_name,
        indicator_id: item.answers[0].indicator_id,
        score_ignore: item.score_ignore,
        section_num: item.section_num,
        childrens: [],
      };

      item.answers.slice(1).forEach(function (value) {
        const _item = {
          item_id: item.item_id,
          question_id: item.question_id,
          item_title: item.item_title || item.question_title,
          label_left: item.label_left,
          label_right: item.label_right,
          answer_id: value.answer_id,
          score_value: value.score_value,
          answer_title: value.answer_title,
          indicator_name: value.indicator_name,
          indicator_id: value.indicator_id,
          score_ignore: item.score_ignore,
          section_num: item.section_num,
        };
        newItem.childrens.push(_item);
      });
      result.push(newItem);
    }
  });

  return result;
};

const descendingComparator = (a, b, order, orderBy, columns) => {
  const getValue = _.find(columns, { id: orderBy })?.getValue;
  const A =
    typeof getValue(a) === 'string' ? getValue(a).toUpperCase() : getValue(a); // ignore upper and lowercase
  const B =
    typeof getValue(b) === 'string' ? getValue(b).toUpperCase() : getValue(b); // ignore upper and lowercase

  if (A === null && B === null) return 0;
  if (A === null) return 1;
  if (B === null) return -1;

  // General comparison
  if (order === 'asc') return A < B ? -1 : A > B ? 1 : 0;
  else return A < B ? 1 : A > B ? -1 : 0;
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

const Assessments = ({ user }) => {
  const [assessmentLoading, setAssessmentLoading] = useState(true);
  const [assessmentVersionLoading, setAssessmentVersionLoading] =
    useState(true);
  const [assessments, setAssessments] = useState([]);
  const [assessmentVersions, setAssessmentVersions] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [assessment, setAssessment] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [expanded, setExpanded] = useState('');
  const openPopover = Boolean(anchorEl);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('answer_title');

  const id = openPopover ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const version = +searchParams.get('version') || DEFAULT_ASSESSMENT_VERSION;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchAssessmentVersions = async () => {
    try {
      const response = await request({
        method: 'GET',
        url: `/api/assessments/entry-level-assessments`,
      });

      if (response?.status !== 200) {
        throw new Error('Error in fetching');
      }

      const versions = await response.data;
      if (versions?.length) {
        setAssessmentVersions(versions);
      }
    } catch (error) {
      console.error('Failed to fetch assessment:', error);
    } finally {
      setAssessmentVersionLoading(false);
    }
  };

  const fetchAssessmentItems = async () => {
    setAssessmentLoading(true);
    const response = await request({
      method: 'GET',
      url: `/api/assessments/${version}/items`,
    });

    if (response?.status !== 200) {
      throw new Error('Error in fetching');
    }
    const result = await response.data;
    setAssessments(result);
    setAssessmentLoading(false);
  };

  useEffect(() => {
    fetchAssessmentVersions();
  }, []);

  useEffect(() => {
    fetchAssessmentItems();
  }, [version]);

  const columns = [
    {
      id: 'question_id',
      disablePadding: false,
      label: 'Question Id',
      getValue: row => {
        return row?.question_id;
      },

      minWidth: 120,
    },
    {
      id: 'item_title',
      disablePadding: false,
      label: 'Item Title',
      getValue: row => row?.item_title,

      minWidth: 180,
    },
    {
      id: 'label_left',
      disablePadding: false,
      label: 'Left Anchor',
      getValue: row => row?.label_left,

      minWidth: 180,
    },
    {
      id: 'label_right',
      disablePadding: false,
      label: 'Right Anchor',
      getValue: row => row?.label_right,

      minWidth: 180,
    },
    {
      id: 'answer_id',
      disablePadding: false,
      label: 'Answer Id',
      getValue: row => row?.answer_id,

      minWidth: 130,
    },
    {
      id: 'score_value',
      disablePadding: false,
      label: 'Score Value',
      getValue: row => row?.score_value,

      minWidth: 140,
    },
    {
      id: 'answer_title',
      disablePadding: false,
      label: 'Answer Value',
      getValue: row => {
        return row?.answer_title;
      },

      minWidth: 180,
    },
    {
      id: 'indicator_name',
      disablePadding: false,
      label: 'Competency',
      getValue: row => row?.indicator_name,

      minWidth: 180,
    },
    {
      id: 'indicator_id',
      disablePadding: false,
      label: 'Indicator Id',
      getValue: row => row?.indicator_id,

      minWidth: 140,
    },
    {
      id: 'score_ignore',
      disablePadding: false,
      label: 'Ignore Score',
      getValue: row => row?.score_ignore,

      minWidth: 180,
    },
    {
      id: 'section_num',
      disablePadding: false,
      label: 'Section Number',
      getValue: row => row?.section_num,

      minWidth: 180,
    },
    {
      id: 'edit',
      disablePadding: false,
      label: '',
      getValue: row => row?.indicator_id,
      minWidth: 100,
      sx: {
        ...stickyColumnStyle,
        backgroundColor: '#5d2a5f',
      },
    },
  ];

  const handleTabChange = (event, value) => {
    history.push(`?version=${value}`);
  };

  const handleCreate = () => {
    setOpen(true);
  };

  const { groupedAssessment, numberOfItems } = useMemo(() => {
    if (!assessments)
      return {
        groupedAssessment: [],
        numberOfItems: 0,
      };
    const items = createGroupedAssessment(assessments) ?? [];

    return {
      groupedAssessment: items
        .sort((a, b) => descendingComparator(a, b, order, orderBy, columns))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      numberOfItems: items?.length,
    };
  }, [assessments, page, rowsPerPage, order, orderBy, columns]);

  const downloadCSV = () => {
    const assessmentVersion = assessmentVersions?.find(
      v => v.assessment_id == version
    );
    const items = createGroupedAssessment(assessments) ?? [];

    let exportData = [];
    items.forEach(item => {
      const { childrens, item_id, ...itemWithoutAnswers } = item;
      exportData.push(itemWithoutAnswers);
      item.childrens.forEach(child => {
        exportData.push(child);
      });
    });

    downloadFile({
      exportData,
      filename: `${assessmentVersion?.assessment_title} - ${assessmentVersion.version} Overview ${moment().format('MM.DD.YYYY')}.csv`,
    });
  };

  const handleSubmit = async details => {
    setSubmitting(true);
    try {
      await fetch('/api/assessments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          assessment_id: details.assessmentId,
        }),
      });
      await fetchAssessmentVersions();
      setAnchorEl(null);
    } catch (e) {
      console.log('Error on creating new version of assessment', e);
    } finally {
      setSubmitting(false);
    }
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ mb: 1 }}
      >
        <Typography variant="h4">Assessments Versions</Typography>
        <div>
          <Button
            variant="contained"
            sx={{ marginRight: 1 }}
            aria-describedby={id}
            onClick={e => setAnchorEl(e.currentTarget)}
          >
            <AddIcon /> Add new
          </Button>
          <Button
            variant="contained"
            sx={{ marginRight: 0 }}
            onClick={downloadCSV}
          >
            <DownloadIcon /> Download all
          </Button>
        </div>
      </Grid>
      {assessmentVersionLoading ? (
        <Loader minHeight={'70vh'} />
      ) : (
        <Tabs
          value={version}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{ marginBottom: '2px' }}
        >
          {assessmentVersions?.map((assessment, ind) => (
            <Tab
              key={ind}
              label={assessment?.assessment_title + assessment?.version}
              value={assessment?.assessment_id}
            />
          ))}
        </Tabs>
      )}
      {!assessmentLoading ? (
        <>
          <TableContainer>
            <Table sx={{ borderCollapse: 'separate' }}>
              <TableHead sx={{ backgroundColor: '#5d2a5f', color: '#fff' }}>
                <TableRow>
                  {columns.map((column, ind) => (
                    <TableCell
                      sx={{
                        ...(column?.sx ?? {}),
                      }}
                      key={ind}
                      align={'left'}
                    >
                      <TableSortLabel
                        sx={{
                          color: '#fff !important',
                          '&:hover': {
                            color: '#fff!important',
                          },
                          '&.Mui-active': {
                            color: '#fff !important',
                          },
                          '&.Mui-active svg': {
                            color: '#fff !important',
                          },
                          minWidth: column?.minWidth,
                        }}
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                        {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(groupedAssessment || []).map(item => {
                  // const item = groupedAssessment[assessmenKey][0]
                  const isExpanded = item?.item_id === expanded;
                  return (
                    <React.Fragment key={item.item_id}>
                      <TableRow
                        key={item.answer_id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            setExpanded(p =>
                              p == item?.item_id ? '' : item?.item_id
                            )
                          }
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {isExpanded ? (
                              <ExpandLessIcon
                                sx={{
                                  color: '#00629B',
                                }}
                              />
                            ) : (
                              <ExpandMoreIcon
                                sx={{
                                  color: '#00629B',
                                }}
                              />
                            )}
                            {item.question_id}
                          </Box>
                        </TableCell>
                        <TableCell>{item.item_title}</TableCell>
                        <TableCell>{item.label_left}</TableCell>
                        <TableCell>{item.label_right}</TableCell>
                        <TableCell>{item.answer_id}</TableCell>
                        <TableCell>{item.score_value}</TableCell>
                        <TableCell>{item?.answer_title}</TableCell>
                        <TableCell>{item.indicator_name}</TableCell>
                        <TableCell>{item.indicator_id}</TableCell>
                        <TableCell>
                          {item.score_ignore ? 'TRUE' : 'FALSE'}
                        </TableCell>
                        <TableCell>{item.section_num}</TableCell>
                        <TableCell align="right" sx={stickyColumnStyle}>
                          <EditMenuPopup
                            handleEdit={value => {
                              setEdit(value);
                              setOpen(true);
                              setAssessment(item);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      {isExpanded
                        ? item?.childrens?.map(item2 => (
                            <TableRow
                              key={item2.answer_id}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row"></TableCell>
                              <TableCell>{item2.item_title}</TableCell>
                              <TableCell>{item2.label_left}</TableCell>
                              <TableCell>{item2.label_right}</TableCell>
                              <TableCell>{item2.answer_id}</TableCell>
                              <TableCell>{item2.score_value}</TableCell>
                              <TableCell>{item2.answer_title}</TableCell>
                              <TableCell>{item2.indicator_name}</TableCell>
                              <TableCell>{item2.indicator_id}</TableCell>
                              <TableCell>
                                {item2.score_ignore ? 'TRUE' : 'FALSE'}
                              </TableCell>
                              <TableCell>{item2.section_num}</TableCell>
                            </TableRow>
                          ))
                        : null}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={numberOfItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Loader minHeight={'70vh'} />
      )}
      {(open && edit && (
        <UpdateAssessmentModal
          setOpen={setOpen}
          assessment={assessment}
          handleItemUpdate={() => {
            fetchAssessmentItems();
          }}
        />
      )) ||
        (open && !edit && (
          <ViewHistoryModal setOpen={setOpen} itemId={assessment.item_id} />
        ))}

      <AddNewAssessmentPopup
        submitting={submitting}
        id={id}
        assessmentId={version}
        openPopover={openPopover}
        anchorEl={anchorEl}
        assessmentVersions={assessmentVersions}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const EditMenuPopup = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick} id="search-menu-button">
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="search-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: '29ch' } }}
      >
        <MenuItem
          selected={false}
          onClick={() => {
            handleClose();
            props.handleEdit(true);
          }}
        >
          <Typography variant="inherit" noWrap>
            Edit Item
          </Typography>
        </MenuItem>
        <MenuItem
          selected={false}
          onClick={() => {
            handleClose();
            props.handleEdit(false);
          }}
        >
          <Typography variant="inherit" noWrap>
            View History
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

const AddNewAssessmentPopup = ({
  handleSubmit,
  id,
  submitting,
  anchorEl,
  openPopover,
  handleClose,
  assessmentVersions,
  assessmentId,
}) => {
  const [state, setState] = useState({
    assessmentId,
    versionTitle: '',
  });

  useEffect(() => {
    setState({
      assessmentId,
      versionTitle: '',
    });
  }, [assessmentId]);

  const handlechange = e =>
    setState(p => ({ ...p, [e.target.name]: e.target.value }));

  return (
    <Popover
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: -30,
      }}
    >
      <Box
        sx={{
          width: 350,
        }}
      >
        <Box
          sx={{
            pr: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ p: 2, fontWeight: 'bold' }}>
            Add new assessment version
          </Typography>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 1 }}>
          <Typography>
            Adding a new version will duplicate the current assessment you are
            on.
          </Typography>
          <div>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 600 }}
              component="div"
            >
              Assessment Level
            </Typography>

            <Select
              labelId="assessmentId-label"
              id="section-type-select"
              name="assessmentId"
              value={state['assessmentId']}
              displayEmpty
              onChange={handlechange}
              sx={{ width: '100%' }}
              disabled={submitting}
            >
              {assessmentVersions.map((assessmentVersion, ind) => (
                <MenuItem key={ind} value={assessmentVersion.assessment_id}>
                  {assessmentVersion.assessment_title} -{' '}
                  {assessmentVersion.version}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
            }}
          >
            <Button onClick={handleClose}> Close </Button>
            {submitting ? (
              <LoadingButton
                loading
                variant="outlined"
                sx={{
                  borderRadius: '20px',
                }}
              >
                Yes, save changes
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  borderRadius: '20px',
                }}
                onClick={() => handleSubmit(state)}
                variant="contained"
              >
                Yes, save changes
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Assessments);
