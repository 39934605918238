import moment from 'moment';
import { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  Popover,
  Divider,
  Select,
  MenuItem,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditModal from '../../../Components/EditModal';
import BasicTextInput from '../../../Components/Inputs/BasicTextInput';
import request from '../../../RequestUtil';
import LoadingButton from '@mui/lab/LoadingButton';
import '../../../App.css';
import { getIndicators } from '../../../services/indicators';
import { updateItem } from '../../../services/item';
import { openSnackbar } from '../../../redux/actions/snackbar';

const anchors = [
  {
    label_left: 'Strongly Disagree',
    label_right: 'Strongly Agree',
    label: 'Strongly Agree and Strongly Disagree',
    value: 1,
  },
  {
    label_left: 'Rarely',
    label_right: 'Always',
    label: 'Rarely and Always',
    value: 2,
  },
  {
    label_left: '',
    label_right: '',
    label: 'Text box poles',
    value: 3,
  },
];
const sections = [
  { label: 'Section 1', value: 1 },
  { label: 'Section 2', value: 2 },
  { label: 'Section 3', value: 3 },
];

const initialState = {
  item_id: '',
  item_title: '',
  anchorId: 1,
  label_left: 'Strongly Disagree',
  label_right: 'Strongly Agree',
  answer_id: '',
  reverse_coded: false,
  original_reverse_coded: false,
  indicator_id: 1,
  score_ignore: false,
  section_num: sections[0]?.value,
  question_id: '',
};

const UpdateAssessmentModal = ({
  setOpen,
  assessment,
  handleItemUpdate,
  openSnackbar,
}) => {
  const [competencies, setCompetencies] = useState([]);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemUpdateBy, setItemUpdateBy] = useState(null);
  const [itemCreatedBy, setItemCreatedBy] = useState(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (assessment) {
      const anchorDet = anchors?.find(
        item =>
          item.label_left == assessment?.label_left &&
          item?.label_right == assessment?.label_right
      );

      setState({
        item_id: assessment?.item_id,
        item_title: assessment?.item_title,
        anchorId: anchorDet?.value ? anchorDet?.value : 3,
        label_left: assessment?.label_left,
        label_right: assessment?.label_right,
        answer_id: assessment?.answer_id,
        reverse_coded:
          assessment.childrens[0].score_value !=
          assessment.childrens[0].answer_title,
        original_reverse_coded:
          assessment.childrens[0].score_value !=
          assessment.childrens[0].answer_title,
        indicator_id: assessment?.indicator_id,
        score_ignore: assessment?.score_ignore,
        section_num: assessment ? assessment?.section_num : sections[0]?.value,
        initial_title: assessment?.item_title,
        question_id: assessment?.question_id,
      });
    } else {
      setState(initialState);
    }
  }, [assessment]);

  useEffect(() => {
    const fetchCompetencies = async () => {
      try {
        setLoading(true);
        const indicatorResponse = await getIndicators({
          indicatorType: 1,
        });

        const sortedData = indicatorResponse?.rows?.sort((a, b) =>
          a.indicatorName.localeCompare(b.indicatorName)
        );
        setCompetencies(sortedData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchCompetencies();
  }, []);

  const handlechange = e => {
    setState(p => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleAnchor = e => {
    const anchorDet = anchors.find(a => a?.value == e.target.value);

    setState(p => ({
      ...p,
      anchorId: anchorDet?.value,
      label_left: anchorDet?.label_left,
      label_right: anchorDet?.label_right,
      item_title: anchorDet?.value === 3 ? '' : state.initial_title,
    }));
  };

  const handleCancel = () => {
    if (!submiting) handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setSubmiting(true);
      if (assessment) {
        const updated = {
          item_id: +state['item_id'],
          item_title: state['item_title'],
          score_ignore: state['score_ignore'],
          label_left: state['label_left'],
          label_right: state['label_right'],
          section_num: +state['section_num'],
          indicator_id: +state['indicator_id'],
          reverse: state['reverse_coded'],
          question_id: state['question_id'],
        };

        await updateItem(updated);
        openSnackbar({
          severity: 'success',
          message: 'Item updated successfully!',
        });

        setSubmiting(false);
        handleItemUpdate();
        setOpen(false);
      }
    } catch (e) {
      console.log('error,', e);
      setSubmiting(false);
      openSnackbar({
        severity: 'error',
        message: 'Something went wrong, please try again later!',
      });
    }
  };

  useEffect(async () => {
    if (assessment?.updated_by) {
      const response = await request({
        method: 'get',
        url: `/api/users/${assessment.updated_by}`,
        responseType: 'json',
      });
      const updatedBy = response?.data?.[0];
      setItemUpdateBy(`${updatedBy['first_name']} ${updatedBy['last_name']}`);
    }
    if (assessment?.created_by) {
      const response = await request({
        method: 'get',
        url: `/api/users/${assessment?.created_by}`,
        responseType: 'json',
      });
      const createdBy = response?.data?.[0];

      setItemCreatedBy(`${createdBy['first_name']} ${createdBy['last_name']}`);
    }
  }, [assessment]);

  return (
    <EditModal
      title={`${assessment ? 'Update Assessment' : 'Add Item'}`}
      handleCancel={handleCancel}
      contentPaddingLeft={0}
    >
      <Box sx={{ maxWidth: 500 }}>
        <BasicTextInput
          title={'Item Title'}
          placeholder={'Item Title'}
          name={'item_title'}
          value={state.item_title}
          onChange={handlechange}
          style={{ mb: 1, width: '100%' }}
          disabled={state?.anchorId === 3}
        />
        <div>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
            mb={2}
          >
            Anchors
          </Typography>

          <Select
            labelId="anchorId-label"
            id="assessment-anchorId-select"
            name="anchorId"
            value={state['anchorId']}
            displayEmpty
            onChange={handleAnchor}
            sx={{ width: '100%' }}
          >
            {anchors.map((assessment, ind) => (
              <MenuItem key={ind} value={assessment.value}>
                {assessment.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        {state?.anchorId == 3 ? (
          <>
            <br />
            <BasicTextInput
              title={'Left Anchor'}
              placeholder={'Left Anchor'}
              name={'label_left'}
              value={state.label_left}
              onChange={handlechange}
              style={{ mb: 1, width: '100%' }}
            />
            <BasicTextInput
              title={'Right Anchor'}
              placeholder={'Right Anchor'}
              name={'label_right'}
              value={state.label_right}
              onChange={handlechange}
              style={{ mb: 1, width: '100%' }}
            />
          </>
        ) : null}
        <div>
          <Typography mt={3} fontWeight={'700'}>
            {
              'Is this item to be reverse coded?(a response of 5 would be scored as a 1 and vice versa)'
            }
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography>No</Typography>
            <Switch
              checked={state['reverse_coded']}
              onChange={e =>
                setState(p => ({ ...p, reverse_coded: e?.target?.checked }))
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>Yes</Typography>
          </Box>
        </div>
        <div>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
            mb={2}
          >
            Competency
          </Typography>

          <Select
            labelId="indicatorName-label"
            id="assessment-type-select"
            name="indicator_id"
            value={state['indicator_id']}
            displayEmpty
            disabled={loading}
            onChange={handlechange}
            sx={{ width: '100%', mb: 2 }}
          >
            {competencies?.map((indicator, ind) => (
              <MenuItem key={ind} value={indicator.indicatorId}>
                {indicator.indicatorName}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <Typography mt={3} fontWeight={'700'}>
            {'Ignore score for this item ?'}
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography>False</Typography>
            <Switch
              checked={state['score_ignore']}
              onChange={e =>
                setState(p => ({ ...p, score_ignore: e?.target?.checked }))
              }
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography>True</Typography>
          </Box>
        </div>
        <div>
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600 }}
            component="div"
            mb={2}
          >
            Section number
          </Typography>

          <Select
            labelId="section_num-label"
            id="section-type-select"
            name="section_num"
            value={state['section_num']}
            displayEmpty
            onChange={handlechange}
            sx={{ width: '100%' }}
          >
            {sections.map((assessment, ind) => (
              <MenuItem key={ind} value={assessment.value}>
                {assessment.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Box sx={{ width: '100%', marginTop: 1 }}>
          <Typography
            id="modal-modal-sub-title"
            variant="subtitle1"
            sx={{
              pt: 0.5,
              color: 'rgba(17, 17, 17, 0.7)',
            }}
          >
            {assessment?.updated_at ? (
              <>
                Last edited on{' '}
                <b>{moment(assessment?.updated_at).format('MM/DD/yyyy')}</b>
                {itemUpdateBy ? (
                  <>
                    {' '}
                    by <b>{itemUpdateBy}</b>
                  </>
                ) : null}
              </>
            ) : null}
          </Typography>
          <Typography
            id="modal-modal-sub-title"
            variant="subtitle1"
            sx={{
              pt: 0.5,
              color: 'rgba(17, 17, 17, 0.7)',
            }}
          >
            {assessment?.created_at ? (
              <>
                Created at{' '}
                <b>{moment(assessment?.created_at).format('MM/DD/yyyy')}</b>{' '}
                {itemCreatedBy ? (
                  <>
                    {' '}
                    by <b>{itemCreatedBy}</b>
                  </>
                ) : null}
              </>
            ) : null}
          </Typography>
        </Box>

        <Box
          sx={{
            mb: 2,
            mt: 2,
            display: 'inline-flex',
            justifyContent: 'center',
            width: '100%',
            mb: 0,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleCancel}
            sx={{
              mr: 2,
              width: '40%',
              maxWidth: '250px',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleClick}
            aria-describedby={id}
            sx={{
              mr: 2,
              width: '40%',
              maxWidth: '250px',
            }}
          >
            {submiting ? 'Loading...' : assessment ? 'Update' : 'Add'}
          </Button>
          <ConfirmationPopup
            submitting={submiting}
            id={id}
            openPopover={openPopover}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </Box>
      </Box>
    </EditModal>
  );
};

const ConfirmationPopup = ({
  id,
  openPopover,
  handleClose,
  handleSubmit,
  anchorEl,
  submitting,
}) => {
  return (
    <Popover
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          pr: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ p: 2, fontWeight: 'bold' }}>Confirmation</Typography>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
      </Box>
      <Divider></Divider>
      <Typography sx={{ p: 2 }}>
        Are you sure you want to save your changes?
      </Typography>
      <Box
        sx={{
          pb: 2,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleClose}> Close </Button>
        {submitting ? (
          <LoadingButton
            loading
            variant="outlined"
            sx={{
              mr: 2,
              ml: 2,
              borderRadius: '20px',
            }}
          >
            Yes, save changes
          </LoadingButton>
        ) : (
          <Button
            sx={{
              mr: 2,
              ml: 2,
              borderRadius: '20px',
            }}
            onClick={handleSubmit}
            variant="contained"
          >
            Yes, save changes
          </Button>
        )}
      </Box>
    </Popover>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      openSnackbar,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(UpdateAssessmentModal);
