import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SortableTable from '../../../Components/SortableTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UpdateTicketModal from './UpdateTicket/UpdateTicketModal';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const ITEM_HEIGHT = 48;
const TableMenu = ({ id, setIsTicketUpdated, row, index, isAdmin }) => {
  const [selectedTicketId, setSelectedTicketId] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {modalOpen && (
        <UpdateTicketModal
          handleCancel={() => {
            setModalOpen(false);
          }}
          title={'Edit Support Ticket'}
          subTitle={'Update details or requests for existing support tickets'}
          id={selectedTicketId}
          setIsTicketUpdated={setIsTicketUpdated}
          row={selectedRow}
          isAdmin={isAdmin}
        ></UpdateTicketModal>
      )}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '29ch',
          },
        }}
      >
        <MenuItem
          selected={false}
          onClick={() => {
            handleClose();
            setModalOpen(true);
            setSelectedTicketId(id);
            setSelectedRow(row);
          }}
        >
          <Typography variant="inherit" noWrap>
            Edit Ticket
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

const ClientSupport = props => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(2000);
  const [tableData, setTableData] = useState([]);
  const [numOfElements, setNumOfElements] = useState(0);
  const [numSelected, setNumSelected] = useState(0);
  const [isTicketUpdated, setIsTicketUpdated] = useState([1]);
  const [isAdmin, setIsAdmin] = useState(
    _.get(props, 'user.perms', []).includes(4)
  );
  const history = useHistory();

  useEffect(() => {
    const fetchRows = async () => {
      const response = await fetch(
        `/api/support-ticket?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      const data = await response.json();

      const tableData = data.rows.map(row => {
        let clientName = row.tp_portal_client.clientName;
        delete row.tp_portal_client;
        return {
          clientName,
          ...row,
        };
      });
      setTableData(tableData);
      setNumOfElements(data.count);
    };
    fetchRows();
  }, [pageNumber, pageSize, isTicketUpdated]);

  const getColumns = () => {
    const columns = [
      {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'ID',
        isVisible: () => true,
        getValue: row => row.id,
        render: row => row.id,
      },
      {
        id: 'clientName',
        numeric: false,
        disablePadding: false,
        label: 'Client',
        isVisible: () => isAdmin,
        getValue: row => row.clientName,
        render: row => row.clientName,
      },
      {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'Title',
        isVisible: () => true,
        getValue: row => row.title,
        render: row => row.title,
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description',
        isVisible: () => true,
        getValue: row => row.description,
        render: row => row.description,
      },
      {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
        isVisible: () => true,
        getValue: row => row.type,
        render: row => _.startCase(row.type),
      },
      {
        id: 'priority',
        numeric: false,
        disablePadding: false,
        label: 'Priority',
        isVisible: () => true,
        getValue: row => row.priority,
        render: row => _.startCase(row.priority),
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        isVisible: () => true,
        getValue: row => row.status,
        render: row => _.startCase(row.status),
      },
      {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Date Submitted',
        isVisible: () => true,
        getValue: row => row.createdAt,
        render: row => new Date(row.createdAt).toLocaleDateString(),
      },
      {
        id: 'updatedAt',
        numeric: false,
        disablePadding: false,
        label: 'Date Updated',
        isVisible: () => true,
        getValue: row => row.updatedAt,
        render: row => new Date(row.updatedAt).toLocaleDateString(),
      },
      {
        id: '',
        numeric: false,
        disablePadding: false,
        label: '',
        width: 30,
        isVisible: () => true,
        getValue: row => row.school,
        render: (row, index) => (
          <TableMenu
            id={row.id}
            isAdmin={isAdmin}
            setIsTicketUpdated={setIsTicketUpdated}
            index={index}
            row={row}
          />
        ),
      },
    ];

    return columns.filter(cell => cell.isVisible());
  };

  return (
    <>
      <SortableTable
        columns={getColumns()}
        rows={tableData}
        title={`${tableData.length} Support Tickets`}
        downloadAllEntriesAsCsv={() => {}}
        defaultOrderBy="id"
        setSelected={setNumSelected}
      >
        <Stack
          spacing={2}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {!isAdmin ? (
            <Button
              sx={{
                width: 'fit-content',
              }}
              variant="contained"
              onClick={() => {
                history.push('/support/create');
              }}
            >
              <AddIcon />
              Create&nbsp;a&nbsp;new&nbsp;Ticket
            </Button>
          ) : null}
        </Stack>
      </SortableTable>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(ClientSupport);
