const MiniChart = props => {
  const { data, color } = props;
  const transparencyMap = [0.4, 0.6, 1];

  const bars = data.map((sc, i) => {
    const heightProp = sc.individualScore + '%';
    return (
      <div
        key={i + 1}
        className={'bar ' + color}
        style={{ height: heightProp, opacity: transparencyMap[i] }}
      ></div>
    );
  });

  const legendContents = data.map((sc, i) => {
    return (
      <div className="item" key={i + 1}>
        <div
          className={'bullet ' + color}
          style={{ opacity: transparencyMap[i] }}
        >
          {' '}
        </div>
        <div className="label">{sc.indicatorName}</div>
      </div>
    );
  });

  return (
    <div className="mini-chart-contain">
      <div className="chart-wrapper">
        <div className="bg">
          <div className="line">
            <span>100</span>
          </div>
          <div className="line">
            <span>80</span>
          </div>
          <div className="line">
            <span>60</span>
          </div>
          <div className="line">
            <span>40</span>
          </div>
          <div className="line">
            <span>20</span>
          </div>
          <div className="line">
            <span>0</span>
          </div>
        </div>
        <div className="chart">{bars}</div>
      </div>
      <div className="legend">{legendContents}</div>
    </div>
  );
};

const CompetencyDetail = props => {
  const { item } = props;
  const {
    reflections: [],
    actions: [],
  } = item;
  const reflections = props.reflections.map((text, i) => {
    return (
      <li key={i + 1}>
        <span>{text}</span>
      </li>
    );
  });

  const actions = props.actions.map((text, i) => {
    return (
      <li key={i + 1}>
        <span>{text}</span>
      </li>
    );
  });

  const bgColorClass = 'bg-' + item.color;
  const competencyWidthProp = item.individualScore + '%';

  const subcompetencyList = item.children.map((sc, i) => {
    return (
      <span className={'sc-label ' + bgColorClass} key={i + 1}>
        {sc.indicatorName}
      </span>
    );
  });

  return (
    <div className="competency-detail">
      <div className="header">
        <div className={'detail-icon ' + bgColorClass}>
          <span className={item.icon}></span>
        </div>
        <div className="name">{item.indicatorName}</div>
        <div className="bar">
          <div
            className={'bar-inner ' + bgColorClass}
            style={{ width: competencyWidthProp }}
          ></div>
        </div>
        <div className="level">{item.levelLabel}</div>
      </div>
      <div className="description">
        <p>{item.indicatorDescription}</p>
      </div>
      <div className="graphics-contain">
        <div className="subcompetencies">
          <h5>Relevant Subcompetencies</h5>
          <div className="inline-labels">{subcompetencyList}</div>
        </div>
        <div>
          <h5>Subcompetency Measurement</h5>
          <MiniChart data={item.children} color={bgColorClass} />
        </div>
      </div>
      <div className="lists">
        {reflections.length > 0 ? (
          <>
            <h5>For Reflection</h5>
            <p>
              Below are some points or questions to reflect on either alone or
              with a coach.
            </p>
            <ul>{reflections}</ul>
          </>
        ) : null}
        {actions.length > 0 ? (
          <>
            <h5>For Action</h5>
            <p>
              Below are some suggested actions you might consider to enhance in
              this area.
            </p>
            <ul>{actions}</ul>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CompetencyDetail;
