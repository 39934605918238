import { Donut } from '../Donut';

type Props = {
  category_name: string;
  competency_name: string;
  icon_url: string;
  hex_color: string;
  value: number;
};

export function CoreCompetencyChip({
  category_name,
  competency_name,
  hex_color,
  value,
  icon_url,
}: Props) {
  return (
    <div className="core-competency-chip">
      <div
        className={'cc-color-block'}
        style={{ backgroundColor: hex_color }}
      ></div>
      <div className="core-competency-chip-inner">
        <div className={'cc-icon-container'}>
          <img className={'cc-icon'} src={icon_url} alt={category_name} />
        </div>
        <div className="cc-label">
          <h5>{category_name}</h5>
          <h6>{competency_name}</h6>
        </div>
        <div className="cc-donut-contain">
          <Donut
            value={value}
            radius={20}
            stroke_width={5}
            hex_color={hex_color}
            empty_hex_color={'#fff'}
            border_color={'#BFD5D9'}
            text_color={'#3D3D3D'}
            text_style={{
              fontFamily: 'Catamaran',
              fontSize: '12px',
            }}
          />
        </div>
      </div>
    </div>
  );
}
