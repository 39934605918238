import './styles.scss';
import { DimensionCategory } from '../types';
import { displayCompetencyName, displayDimensionName } from '../utils';

type Props = {
  category: DimensionCategory;
};
export function CategorySection({ category }: Props) {
  return (
    <div className="dimension-section">
      <h4 className="dimension-name">{category.name}</h4>
      <div className="dimension-section-competencies">
        {category.dimensions.map((dimension, index) => (
          <div key={index} className="competency">
            <div
              className="competency-color-bar"
              style={{ backgroundColor: dimension.hex_color }}
            />
            <div className="competency-layout">
              <div className="competency-icon">
                <img
                  src={dimension.icon_url}
                  alt={displayDimensionName(dimension.name)}
                />
              </div>
              <div>
                <h5 className="competency-name">
                  {displayDimensionName(dimension.name)}
                </h5>
                <p className="sub-competency-name">
                  {dimension.competencies
                    .map(competency => displayCompetencyName(competency.name))
                    .join(', ')}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
