import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const getIndex = function (propValue, propName, arrayToSearch) {
  let idx = arrayToSearch.findIndex(item => {
    return propValue === item[propName];
  });
  return idx;
};

export const getScoreMeta = score => {
  if (score > 80) {
    return {
      color: '#45AAE1',
      label: 'High Alignment',
      reportLabel: 'High',
    };
  } else if (score > 30) {
    return {
      color: '#45AAE1',
      label: 'Medium Alignment',
      reportLabel: 'Medium',
    };
  } else {
    return {
      color: '#45AAE1',
      label: 'Low Alignment',
      reportLabel: 'Low',
    };
  }
};

export const downloadReport = async title => {
  const boxShadows = document.querySelectorAll('.box-shadow-no-export');

  boxShadows.forEach(function (el) {
    el.classList.remove('box-shadow-no-export');
  });

  const pageWidth = 960;
  const pageHeight = 960 * 1.4142;
  let pdf = new jsPDF('p', 'px', 'a4', true);
  pdf.setFillColor('#ffffff');
  const pdfSections = document.getElementsByClassName('pdfSections');
  let PDFPagesHtml = [];
  let PDFSinglePageHtml = [];
  let sectionsCombinedHeigth = 0;
  //document.body.style.overflow = "hidden";
  for (let i = 0; i < pdfSections.length; i++) {
    sectionsCombinedHeigth += pdfSections[i].offsetHeight;
    if (
      sectionsCombinedHeigth < pageHeight - 40 &&
      !pdfSections[i].classList.value.includes('pageBreak')
    ) {
      PDFSinglePageHtml.push(pdfSections[i]);
    } else {
      PDFPagesHtml.push(PDFSinglePageHtml);
      PDFSinglePageHtml = [];
      PDFSinglePageHtml.push(pdfSections[i]);
      sectionsCombinedHeigth = pdfSections[i].offsetHeight;
    }
    if (i === pdfSections.length - 1 && PDFSinglePageHtml.length > 0) {
      PDFPagesHtml.push(PDFSinglePageHtml);
    }
  }

  for (let i = 0; i < PDFPagesHtml.length; i++) {
    if (i > 0) {
      await pdf.addPage('a4', 'p');
      document.getElementById('downloadReport').style.paddingTop = '30px';
    }
    for (let f = 0; f < PDFPagesHtml[i].length; f++) {
      document
        .getElementById('downloadReport')
        .appendChild(PDFPagesHtml[i][f].cloneNode(true));

      if (PDFPagesHtml[i][f].classList.value.includes('canvasContainer')) {
        const backctx = document
          .querySelector('#downloadReport #parent_chart')
          .getContext('2d');
        backctx.drawImage(document.querySelector('#oci #parent_chart'), 0, 0);
      }
    }
    const html = document.getElementById('downloadReport');
    const canvas = await html2canvas(html, {
      width: pageWidth,
      height: pageHeight,
      x: 0,
      y: 0,
      windowWidth: pageWidth,
      windowHeight: pageHeight,
    });
    // document.body.appendChild(canvas);
    let img = await canvas.toDataURL('image.png');
    const imgProps = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    html.innerHTML = '';
    await pdf.addImage(
      img,
      'PNG',
      0,
      0,
      pdfWidth,
      pdfHeight,
      undefined,
      'FAST'
    );
  }
  document.getElementById('downloadReport').style.paddingTop = '';
  await pdf.save(title);

  boxShadows.forEach(function (el) {
    el.classList.add('box-shadow-no-export');
  });
};
