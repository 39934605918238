import React from 'react';
import _ from 'lodash';
import './css/Main.scss';
import ServerReportContainer from './pages/ServerReportContainer.js';

const ServerApp = () => {
  return <ServerReportContainer />;
};

export default ServerApp;
