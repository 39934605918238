import _ from 'lodash';
import axios from 'axios';
import { getBaseUrl } from './config';

// axios.interceptors.response.use(response => {
//     console.log('Response:', typeof (response))
//     return response
// })
/**
 * Request Wrapper with default successs/error actions
 */
export default options => {
  const BASE_URL = getBaseUrl();
  const onSuccess = response => response;
  const onError = error => {
    const { status } = _.get(error, 'response', {});
    if (status == 403 || status == 401) {
      window.location = `${BASE_URL}/unauthorized`;
    }

    if (status == 404) {
      window.location = `${BASE_URL}/notfound`;
    }
    return Promise.reject(error);
  };

  return axios(options).then(onSuccess).catch(onError);
};
