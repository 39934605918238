import { DimensionDef } from '../types';
import { ReputationManagement } from '../icons/ReputationManagement';
import { SelfManagement } from '../icons/SelfManagement';
import { RelationshipsAndTeamwork } from '../icons/RelationshipsAndTeamwork';
import { BusinessOrientation } from '../icons/BusinessOrientation';
import { Execution } from '../icons/Execution';
import { PursuitOfExcellence } from '../icons/PursuitOfExcellence';
import { DynamicApproach } from '../icons/DynamicApproach';
import { FC, ReactNode } from 'react';
import { displayCompetencyName, displayDimensionName } from '../utils';

export type CompetencyOverviewChartData = {
  name: string;
  remainder: number;
  value: number;
  norm: number;
  color: string;
};

/**
 * transform DimensionDef[] as returned by /api/scoring/assigned to Data[]
 * suitable as input to CompetencyOverviewChart
 * @param dims
 */
export function dimensionDefsToData(
  dims: DimensionDef[]
): CompetencyOverviewChartData[] {
  const data: CompetencyOverviewChartData[] = [];
  const sorted = dims.sort((a, b) => sortDimensions(a.name, b.name));
  sorted.forEach(dim => {
    // prepare header
    const { color } = getDimensionDataByName(dim.name);
    const empty: CompetencyOverviewChartData = {
      name: `EMPTY:${displayDimensionName(dim.name)}`,
      remainder: 0,
      value: 0,
      norm: 0,
      color,
    };
    data.push(empty);
    const header: CompetencyOverviewChartData = {
      name: `HEADER:${displayDimensionName(dim.name)}`,
      remainder: 0,
      value: 0,
      norm: 0,
      color,
    };
    data.push(header);

    // add rows
    dim.children
      .sort((a, b) => (a.indicatorName < b.indicatorName ? -1 : 1))
      .forEach(competency => {
        data.push({
          name: displayCompetencyName(competency.indicatorName),
          remainder: 100 - competency.individualScore,
          value: competency.individualScore,
          norm: competency.populationScore,
          color,
        });
      });
  });
  return data;
}

type DimensionData = {
  icon: FC<{ width?: number; height?: number; x?: number; y?: number }>;
  color: string;
};

function sortDimensions(a: string, b: string) {
  const dimensionOrder = [
    'Reputation Management',
    'Self-Management',
    'Relationships + Teamwork',
    'Business Orientation',
    'Execution',
    'Pursuit of Excellence',
    'Dynamic Approach',
  ];
  return dimensionOrder.indexOf(a) - dimensionOrder.indexOf(b);
}

export function getDimensionDataByName(dimensionName: string): DimensionData {
  const map: Record<string, DimensionData> = {
    'Reputation Management': {
      icon: ReputationManagement,
      color: '#F1B434',
    },
    'Self-Management': {
      icon: SelfManagement,
      color: '#C73663',
    },
    'Relationships + Teamwork': {
      icon: RelationshipsAndTeamwork,
      color: '#632F6B',
    },
    'Business Orientation': {
      icon: BusinessOrientation,
      color: '#0077B6',
    },
    Execution: {
      icon: Execution,
      color: '#64B0BB',
    },
    'Pursuit of Excellence': {
      icon: PursuitOfExcellence,
      color: '#33937C',
    },
    'Dynamic Approach': {
      icon: DynamicApproach,
      color: '#E87722',
    },
  };

  return map[dimensionName] || Object.values(map)[0];
}
