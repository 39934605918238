import React, { Component } from 'react';
import request from '../../RequestUtil.js';
import { Chart } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import '../../css/client.scss';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import lodashGet from 'lodash/get';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import EditModal from '../../Components/EditModal';
import BasicSelect from '../../Components/Inputs/BasicSelect';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import BasicControls from '../../Components/BasicControls.jsx';
import moment from 'moment';

// this is the client dashboard
// it should only render on client-scoped accounts
// it should have a back link to candidate dashboard so client accounts may take an assessment if they wish and are scoped for it (check permissions)

class ClientDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      data: {},
      assessmentData: {},
      searchType: 0,
      showPanels: false,
      selectedAssessment: '',
    };
  }

  componentDidMount() {
    request({
      method: 'post',
      url: '/api/firm/dashboard',
      responseType: 'json',
    })
      .then(response => {
        this.setState({ isLoaded: true, ...response.data });

        this.showJobSearches(1, true);
      })
      .catch(err => console.log(err));

    // load back stats based on permissions scoped to user!

    request({
      method: 'get',
      url: `/api/assessment-pools/group`,
      responseType: 'json',
    })
      .then(response => {
        let assessmentData = response.data;
        Object.keys(response.data).forEach(key => {
          const assessment = assessmentData[key];
          const { pools = [] } = assessment;
          assessment.pools = pools.filter(
            job => new Date(job.expiryDate).getTime() > new Date().getTime()
          );
        });
        this.setState({ assessmentData });
      })
      .catch(err => console.log(err));

    // load back stats based on permissions scoped to user!
  }

  showJobSearches = (newAssessment, newSearchType) => {
    const { assessmentData, selectedAssessment, searchType } = this.state;
    this.setState(
      {
        selectedAssessment: newAssessment || selectedAssessment,
        searchType: newSearchType || searchType,
      },
      () => {
        const { selectedAssessment } = this.state;
        const selectedPools = lodashGet(
          assessmentData,
          `[${selectedAssessment}].pools`,
          []
        );
        const selectedPoolIds = selectedPools.map(
          assessment => assessment.poolId
        );

        if (selectedPoolIds.length > 0) {
          request({
            method: 'get',
            url: `/api/jobs?poolIds=${selectedPoolIds.join(',')}`,
            responseType: 'json',
          })
            .then(response => {
              this.setState({
                showPanels: true,
                assessmentData: {
                  ...assessmentData,
                  [selectedAssessment]: {
                    ...assessmentData[selectedAssessment],
                    jobs: response.data,
                  },
                },
              });
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
    );
  };

  render() {
    let icon_color = '#45AAE1';
    let colors = {
      entry_level: '#eaaa00',
      lateral: '#1492D8',
      integration: '#5D2A5F',
      developmental: '#009586',
    };
    const {
      assessmentData = {},
      selectedAssessment = '',
      showPanels,
    } = this.state;
    let dashboard = this.state.isLoaded ? (
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            mt: 2,
            mb: 4,
          }}
        >
          <Box>
            <img
              src={this.state.client.logo_url}
              width="250px"
              className="logo"
              alt="company logo"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            maxWidth: 1800,
            pr: 12,
            pl: 4,
            pb: 0,
            pt: 3,
            height: 109,
            backgroundColor: '#ecf6fc',
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                fontFamily: "'Roboto', sans-serif",
                mb: 2,
              }}
            >
              {this.state.user.first_name == null
                ? 'Welcome!'
                : 'Welcome, ' + this.props.user.first_name + '!'}{' '}
            </Typography>
          </Box>
          <Box sx={{ position: 'relative', bottom: 168 }}>
            <img
              src="https://bucketeer-602c768c-fde7-4c6f-b808-e4d8b6efef64.s3.us-east-1.amazonaws.com/public/Dashboard_Graphic_1.png"
              alt=""
              className="top-img"
            ></img>
          </Box>
        </Box>

        <Box sx={{ mt: 3.2 }} className="assessment-buttons">
          {Object.keys(assessmentData).map((key, index) => {
            const assessment = assessmentData[key];
            let { label, isActive } = assessment;
            if (label == 'Integration') {
              label = 'Technical Skills Assessment';
            }
            return (
              <button
                key={label}
                className={`assessment-select ${!isActive ? 'inactive' : ''} ${
                  selectedAssessment === key && showPanels ? 'outlined' : ''
                }`}
                disabled={!isActive}
                onClick={() => {
                  this.showJobSearches(key, index + 1);
                }}
              >
                <Box className="svg-bg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="33"
                    viewBox="0 0 30 33"
                    fill="none"
                  >
                    <path
                      d="M15.1993 0C10.4789 0 6.62524 3.85368 6.62524 8.56691C6.62524 13.2801 10.4789 17.1338 15.1922 17.1338C19.9054 17.1338 23.7591 13.2801 23.7591 8.56691C23.7591 3.85368 19.9125 0 15.1993 0Z"
                      fill={icon_color}
                    />
                    <path
                      d="M29.9047 23.9891C29.6826 23.4304 29.3818 22.9003 29.0451 22.4204C27.326 19.8776 24.6686 18.1943 21.6744 17.7788C21.302 17.743 20.8865 17.8146 20.5857 18.0438C19.017 19.2042 17.1403 19.8059 15.1991 19.8059C13.2579 19.8059 11.3812 19.1971 9.8054 18.0367C9.50455 17.8146 9.09626 17.7 8.71663 17.7716C5.72251 18.1799 3.02923 19.8704 1.34593 22.4133C1.00928 22.9003 0.708431 23.459 0.486379 23.9819C0.371771 24.204 0.414749 24.469 0.522193 24.6911C0.823038 25.214 1.19551 25.7369 1.53217 26.1881C2.05507 26.8973 2.62094 27.5348 3.25128 28.1365C3.77418 28.6594 4.37587 29.1464 4.9704 29.6335C7.9287 31.8397 11.4815 33.0001 15.149 33.0001C18.8164 33.0001 22.3692 31.8397 25.3275 29.6335C25.9292 29.1823 26.5238 28.6594 27.0467 28.1365C27.6483 27.5348 28.2429 26.9044 28.7658 26.1881C29.1382 25.7011 29.4749 25.214 29.7757 24.6911C29.9763 24.4762 30.0193 24.2112 29.9047 23.9891Z"
                      fill={icon_color}
                    />
                  </svg>
                </Box>
                <strong>{label}</strong>
              </button>
            );
          })}
        </Box>

        {this.state.showPanels && (
          <JobSearches
            searchType={this.state.searchType}
            client_id={this.state.client.client_id}
            selectedAssessmentLabel={lodashGet(
              assessmentData,
              `[${selectedAssessment}].label`,
              ''
            )}
            jobsData={lodashGet(
              assessmentData,
              `[${selectedAssessment}].jobs`,
              []
            )}
            showJobSearches={() => this.showJobSearches()}
            selectedAssessmentDataPools={lodashGet(
              assessmentData,
              `[${selectedAssessment}].pools`,
              []
            )}
          />
        )}
        <Box sx={{ mt: 3.2 }}>
          <h2>Total Attorneys by Assessment</h2>
          <Box className="donuts">
            <DonutPanel
              data={this.state.dashData.entryLevel.data}
              isActive={this.state.dashData.entryLevel.isActive}
              label="Thine Launch"
              color={colors.entry_level}
            />
            <DonutPanel
              data={this.state.dashData.lateral.data}
              isActive={this.state.dashData.lateral.isActive}
              label="Thine Integrate"
              color={colors.lateral}
            />
            <DonutPanel
              data={this.state.dashData.integration.data}
              isActive={this.state.dashData.integration.isActive}
              label="Thine Calibrate"
              color={colors.integration}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 12.8, maxWidth: 1800 }}>
          {this.state.isLoaded === true ? (
            <TotalApps client_id={this.state.client.client_id} />
          ) : null}
        </Box>
      </Box>
    ) : (
      <Box></Box>
    );

    return dashboard;
  }
}

class DonutPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let total = 0;
    let difference = 100;
    let total_class = '';
    let total_label = 0;

    if (this.props.isActive) {
      total = this.props.data.percent;
      difference = 100 - total;
      total_class = this.props.color || '#000';
      total_label = this.props.data.complete_ct;
    } else {
      total = 0;
      difference = 100;
      total_class = '';
      total_label = 0;
    }

    let active_class =
      this.props.isActive === true
        ? 'panel-base donut-meter-contain'
        : 'panel-base donut-meter-contain inactive';
    return (
      <Box className={active_class} sx={{ mr: 4 }}>
        <h4>{this.props.label}</h4>
        <svg width="330px" height="330px" viewBox="0 0 42 42" className="donut">
          <circle
            className="donut-hole"
            cx="21"
            cy="21"
            r="15.915494"
            fill="#ffffff"
          ></circle>
          <circle
            className="donut-ring"
            cx="21"
            cy="21"
            r="15.915494"
            fill="transparent"
            stroke="#d2d3d4"
            strokeWidth="3"
          ></circle>
          <circle
            className="donut-segment"
            cx="21"
            cy="21"
            r="15.915494"
            fill="transparent"
            stroke={total_class}
            strokeWidth="3"
            strokeDasharray={total + ' ' + difference}
            strokeDashoffset="50"
          ></circle>
          <g className="donut-text">
            <text x="50%" y="45%" className="chart-number">
              {total_label}
            </text>
          </g>
        </svg>
      </Box>
    );
  }
}

class TotalApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      labels: [],
      data: [],
      formStage: {
        client_id: this.props.client_id,
        assessment_type: 1,
        viewType: 1,
        viewValue: '2023',
      },
    };

    this.updateChart = this.updateChart.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);

    // should be fed props: client_id,
  }

  updateChart(params) {
    this.setState({ isLoaded: false });

    request({
      method: 'post',
      url: '/api/firm/usage',
      responseType: 'json',
      data: params,
    })
      .then(response => {
        this.setState({
          isLoaded: true,
          chartData: {
            labels: response.data.labels,
            datasets: [
              {
                data: response.data.data,
                backgroundColor: '#00CCD9',
                borderColor: '#00CCD9',
                tension: 0.3,
              },
            ],
          },
        });

        this.setState({ isLoaded: true });
      })
      .catch(err => {
        console.log(err);

        this.setState({ isLoaded: true });
      });
  }

  handleYearChange(e) {
    let newState = this.state;
    newState.formStage.viewValue = e.target.value;

    this.setState(newState);

    this.updateChart(this.state.formStage);
  }

  handleTypeChange(e) {
    let newState = this.state;
    newState.formStage.assessment_type = e.target.value;

    this.setState(newState);

    this.updateChart(this.state.formStage);
  }

  componentDidMount() {
    this.updateChart(this.state.formStage);
  }

  render() {
    let options = {
      showLines: false,
    };

    return (
      <Box sx={{ maxWidth: 1060 }}>
        <Box>
          <Box className="total-apps-dd">
            <Typography variant="h6" sx={{ fontWeight: 700, mr: 4 }}>
              Total Attorneys
            </Typography>
            <Select
              labelId="job-search"
              id="job-search-select"
              value={this.state.formStage.assessment_type}
              displayEmpty
              onChange={this.handleTypeChange}
              sx={{ mr: 2 }}
            >
              <MenuItem value={1}>Thine Launch</MenuItem>
              <MenuItem value={2}>Integrate Assessment</MenuItem>
              <MenuItem value={3}>Calibrate Assessment</MenuItem>
            </Select>
            <Select
              labelId="job-search"
              id="job-search-select"
              value={this.state.formStage.viewValue}
              displayEmpty
              onChange={this.handleYearChange}
            >
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2020">2020</MenuItem>
            </Select>
          </Box>
        </Box>
        <Box
          sx={{ p: 1.5, mt: 3 }}
          className="panel-base chart-base total-apps-contain"
        >
          <Box className="line-chart" sx={{ height: 358, width: 1060 }}>
            {this.state.isLoaded === true ? (
              <Line
                data={{ ...this.state.chartData, tension: 0 }}
                redraw="true"
                height="30"
                width="100%"
                style={{ height: 358 }}
                options={options}
              />
            ) : (
              <CircularProgress />
            )}
          </Box>
          <Box className="line-chart-key"></Box>
        </Box>
      </Box>
    );
  }
}

class JobSearches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: true,
      instances: [],
      modalOpen: false,
      practiceGroup: '',
      assessmentType: 0,
      title: null,
      jobsData: [],
      currentPage: 1,
      completeJobsData: [],
      sendMessageResponse: {
        open: false,
        message: '',
        severity: 'success',
      },
    };

    this.getSearches = this.getSearches.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.pageSize = 6;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.jobsData !== state.completeJobsData) {
      //Change in props
      return {
        jobsData: props.jobsData.slice(0, 6),
        completeJobsData: props.jobsData,
      };
    }
    return null; // No change to state
  }
  componentDidMount() {
    // get instances of pool fed in by button
    this.getSearches();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchType !== this.props.searchType) {
      this.getSearches();
    }
  }

  getSearches() {
    request({
      method: 'post',
      url: '/api/firm/searches',
      responseType: 'json',
      data: {
        client_id: this.props.client_id,
        assessment_type: this.props.searchType,
      },
    })
      .then(response => {
        this.setState({ isLoaded: true, instances: response.data });
      })
      .catch(err => console.log(err));
  }

  handleChange(val) {
    this.setState({
      ...this.state,
      assessmentType: val,
    });
  }

  createJob = () => {
    const { assessmentType, practiceGroup, title = null } = this.state;
    let data = {
      assessmentType,
      practiceGroup,
    };
    if (title) {
      data.title = title;
    }
    request({
      method: 'post',
      responseType: 'json',
      url: '/api/jobs',
      data: data,
    })
      .then(response => {
        this.setState({
          sendMessageResponse: {
            ...this.state.sendMessageResponse,
            open: true,
            message: 'Job Created Sucesssfully!',
            severity: 'success',
          },
          currentPage: 1,
        });
        this.props.showJobSearches();
      })
      .catch(err => {
        this.setState({
          sendMessageResponse: {
            ...this.state.sendMessageResponse,
            open: true,
            message: 'Something Went Wrong!',
            severity: 'error',
          },
        });
      });
  };

  render() {
    const {
      searchType = '',
      selectedAssessmentLabel,
      jobsData: completeJobsData,
      selectedAssessmentDataPools,
    } = this.props;
    const { assessmentType, jobsData = [], currentPage = 1 } = this.state;
    const totalPages = Math.ceil(completeJobsData.length / this.pageSize);
    const activeAssessmentType = selectedAssessmentDataPools.map(assessment =>
      Number(assessment.assessmentType)
    );

    let searchTypeLabel = '';
    switch (searchType) {
      case 1:
        searchTypeLabel = 'Thine Launch';
        break;
      case 2:
        searchTypeLabel = 'Thine Integrate';
        break;
      case 3:
        searchTypeLabel = 'Thine Calibrate';
        break;
      case 4:
        searchTypeLabel = 'Thine Accelerate';
        break;
      default:
        searchTypeLabel = 'Thine Launch';
    }

    let panels = jobsData
      .filter(job => job.parentId == null)
      .map((job, i) => {
        return (
          <SearchPanel
            job={job}
            key={i + 1}
            num={i}
            selectedAssessmentLabel={selectedAssessmentLabel}
          />
        );
      });
    const practiceGroupMap = [
      { value: 'Bankruptcy', displayText: 'Bankruptcy' },
      { value: 'Capital Markets', displayText: 'Capital Markets' },
      { value: 'Corporate', displayText: 'Corporate' },
      { value: 'Entertainment', displayText: 'Entertainment' },
      { value: 'Environmental', displayText: 'Environmental' },
      { value: 'Family', displayText: 'Family' },
      { value: 'Finance', displayText: 'Finance' },
      { value: 'Funds', displayText: 'Funds' },
      { value: 'Immigration', displayText: 'Immigration' },
      { value: 'Intellectual Property', displayText: 'Intellectual Property' },
      { value: 'Labor & Employment', displayText: 'Labor & Employment' },
      { value: 'Litigation', displayText: 'Litigation' },
      { value: 'M&A', displayText: 'M&A' },
      { value: 'Real Estate', displayText: 'Real Estate' },
      { value: 'Tax', displayText: 'Tax' },
      { value: 'General', displayText: 'General' },
    ];
    const modalOptions = {
      2: [
        { label: 'Junior', value: 2 },
        { label: 'Mid-level', value: 3 },
        { label: 'Senior', value: 4 },
      ],
      3: [
        { label: 'M&A', value: 5 },
        { label: 'Finance', value: 6 },
        { label: 'Capital Markets', value: 7 },
        { label: 'Litigation', value: 8 },
        { label: 'Funds', value: 9 },
      ],
      4: [{ label: 'Thine Launch', value: 10 }],
    };

    const searchTypeConstants = {
      1: {
        firmCanCreate: false,
        searchTypeLabel: 'Thine Launch',
        createLabel: 'Create Thine Launch Search',
        createSubheading: '',
        noneLabel: 'No Searches Found',
        formTypes: ['none'],
        errorMessage: '',
        nameLabel: 'Search',
      },
      2: {
        firmCanCreate: true,
        searchTypeLabel: 'Thine Integrate',
        createLabel: 'Create Lateral Role',
        createSubheading:
          'Create a role to group associate results based on practice area and experience level',
        noneLabel: 'No Jobs Found',
        formTypes: ['lateralLevel', 'practiceGroup'],
        createErrorMessage: 'Please select an experience level.',
        nameLabel: 'Search',
      },
      3: {
        firmCanCreate: true,
        searchTypeLabel: 'Thine Calibrate',
        createLabel: 'Create Skills Role',
        createSubheading:
          'Create a role to group associate results based on practice area and experience level',
        noneLabel: 'No Jobs Found',
        formTypes: ['skill', 'skillLevel'],
        createErrorMessage: 'Please select a skill.',
        nameLabel: 'Search',
      },
      4: {
        firmCanCreate: true,
        searchTypeLabel: 'Thine Accelerate',
        createLabel: 'Create Group',
        createSubheading: '',
        noneLabel: 'No Groups Found',
        formTypes: ['name'],
        createErrorMessage: 'Please enter a name for the group.',
        nameLabel: 'Group',
      },
    };

    return (
      <Box sx={{ mt: 6.4 }}>
        <Box
          sx={{
            mt: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            maxWidth: 800,
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: 200, mb: 3.2, maxWidth: 550 }}
          >
            {searchTypeConstants[searchType].searchTypeLabel} Assessments
          </Typography>
          {searchTypeConstants[searchType].firmCanCreate ? (
            <Button
              onClick={() => this.setState({ modalOpen: true })}
              variant="text"
              sx={{
                fontSize: '20px',
                textDecoration: 'underline',
                textTransform: 'initial',
              }}
              endIcon={
                <Icon color="primary" sx={{ fontSize: '35px!important' }}>
                  add_circle
                </Icon>
              }
            >
              Create New
            </Button>
          ) : null}
        </Box>

        <Typography variant="h6" sx={{ fontWeight: 700, mb: 3.2 }}>
          {panels.length > 0
            ? `All ${searchTypeConstants[searchType].searchTypeLabel} Assessments`
            : 'No Jobs Found'}
        </Typography>
        {this.state.isLoaded ? (
          <>
            <div className="job-searches-contain">{panels}</div>
            {completeJobsData.length > this.pageSize ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: 750,
                }}
              >
                <div>
                  <Button
                    onClick={() => {
                      const updatesCurrentPage = currentPage - 1;
                      const startIndex =
                        (updatesCurrentPage - 1) * this.pageSize;
                      const endIndex = startIndex + this.pageSize;
                      const jobsData = completeJobsData.slice(
                        startIndex,
                        endIndex
                      );
                      this.setState({
                        jobsData,
                        currentPage: updatesCurrentPage,
                      });
                    }}
                    sx={{ fontSize: '25px' }}
                    variant="text"
                    disabled={currentPage === 1}
                  >
                    {'<'}
                  </Button>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 700, display: 'inline-block' }}
                  >
                    {`${currentPage}/${totalPages}`}
                  </Typography>
                  <Button
                    onClick={() => {
                      const updatesCurrentPage = currentPage + 1;
                      const startIndex =
                        (updatesCurrentPage - 1) * this.pageSize;
                      const endIndex = startIndex + this.pageSize;
                      const jobsData = completeJobsData.slice(
                        startIndex,
                        endIndex
                      );
                      this.setState({
                        jobsData,
                        currentPage: updatesCurrentPage,
                      });
                    }}
                    sx={{ fontSize: '25px' }}
                    variant="text"
                    disabled={currentPage === totalPages}
                  >
                    {'>'}
                  </Button>
                </div>
              </Box>
            ) : null}
          </>
        ) : null}
        {this.state.modalOpen && (
          <EditModal
            handleCancel={() => {
              this.setState({ modalOpen: false });
            }}
            title={searchTypeConstants[searchType].createLabel}
            subTitle={searchTypeConstants[searchType].createSubheading}
          >
            {searchTypeConstants[searchType].formTypes.includes(
              'practiceGroup'
            ) && (
              <BasicSelect
                options={practiceGroupMap} //
                title="Practice Group"
                value={this.state.practiceGroup}
                onChange={e => {
                  this.setState({ practiceGroup: e.target.value }); //
                }}
                style={{ mb: 1 }}
              />
            )}
            {searchTypeConstants[searchType].formTypes.includes('skill') ? (
              <FormControl
                sx={{ m: 0 }}
                component="fieldset"
                variant="standard"
              >
                <BasicSelect
                  options={modalOptions[searchType].map(option => {
                    return { value: option.value, displayText: option.label };
                  })}
                  title="Skill"
                  value={this.state.assessmentType}
                  onChange={e => {
                    this.setState({ assessmentType: e.target.value });
                  }}
                  style={{ mb: 1 }}
                />
              </FormControl>
            ) : null}
            {searchTypeConstants[searchType].formTypes.includes(
              'lateralLevel'
            ) ? (
              <FormControl
                sx={{ m: 0 }}
                component="fieldset"
                variant="standard"
              >
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: 600 }}
                  component="div"
                >
                  Practice Group
                </Typography>
                <FormGroup>
                  {modalOptions[searchType].map(exp => (
                    <FormControlLabel
                      disabled={!activeAssessmentType.includes(exp.value)}
                      control={
                        <Radio
                          checked={assessmentType === exp.value}
                          onChange={() => {
                            this.handleChange(exp.value);
                          }}
                          value={exp.value}
                          name="assessmentType"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      }
                      label={exp.label}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : null}
            {searchTypeConstants[searchType].formTypes.includes('name') ? (
              <FormControl
                sx={{ m: 0, width: '100%' }}
                component="fieldset"
                variant="standard"
              >
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: 600 }}
                  component="div"
                >
                  Name:
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <TextField
                        name="groupName"
                        fullWidth={true}
                        onChange={e => {
                          this.setState({
                            title: e.target.value,
                            assessmentType: 10,
                          }); // TODO: there is only one assessment for the Developmental type and none others need custom group names for now, but add/handle via dropdown when that is no longer the case :)
                        }}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            ) : null}

            <BasicControls
              onCancel={() => {
                this.setState({
                  modalOpen: false,
                  practiceGroup: null,
                  assessmentType: 0,
                  sendMessageResponse: {
                    open: false,
                    message: '',
                    severity: 'success',
                  },
                });
              }}
              onClick={() => {
                if (
                  searchTypeConstants[searchType].formTypes.includes('name') &&
                  this.state.title == null
                ) {
                  // no name ;
                } else if (
                  searchTypeConstants[searchType].formTypes.includes('skill') &&
                  this.state.practiceGroup == null
                ) {
                  // no practice group
                }
                if (assessmentType === 0) {
                  this.setState({
                    sendMessageResponse: {
                      open: true,
                      message:
                        searchTypeConstants[searchType].createErrorMessage,
                      severity: 'error',
                    },
                  });
                } else {
                  this.createJob();
                }
              }}
              snackbarOpen={this.state.sendMessageResponse.open}
              setSnackbarOpen={value =>
                this.setState({
                  sendMessageResponse: {
                    ...this.state.sendMessageResponse,
                    open: value,
                  },
                })
              }
              message={this.state.sendMessageResponse.message}
              severity={this.state.sendMessageResponse.severity}
              style={{ mb: 0 }}
            />
          </EditModal>
        )}
      </Box>
    );
  }
}

class SearchPanel extends Component {
  constructor(props) {
    super(props);
    // bind a function that redirects user to search/list page, with state data/props
  }

  /// expecting props (color from array (go by key - 1), title, assessment_id)

  render() {
    let colors = [
      '#F7612A',
      '#A4324A',
      '#5D2A5F',
      '#ADAF2D',
      '#008B57',
      '#42B0A9',
    ];
    let style = {
      borderBottom: '4px solid ' + colors[this.props.num],
    };
    const {
      job: { id, title, createdAt },
      num,
      selectedAssessmentLabel,
    } = this.props;

    return (
      <Link
        to={{
          pathname: `firm/jobs/${id}/candidates`,
          state: {
            jobId: id,
            predefined: true,
            jobName: title,
            selectedAssessmentLabel: selectedAssessmentLabel,
          },
        }}
      >
        <div className="search-panel" style={style}>
          <div className="svg-guy">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="33"
              viewBox="0 0 30 33"
              fill="none"
            >
              <path
                d="M15.1993 0C10.4789 0 6.62524 3.85368 6.62524 8.56691C6.62524 13.2801 10.4789 17.1338 15.1922 17.1338C19.9054 17.1338 23.7591 13.2801 23.7591 8.56691C23.7591 3.85368 19.9125 0 15.1993 0Z"
                fill={colors[num]}
              />
              <path
                d="M29.9047 23.9891C29.6826 23.4304 29.3818 22.9003 29.0451 22.4204C27.326 19.8776 24.6686 18.1943 21.6744 17.7788C21.302 17.743 20.8865 17.8146 20.5857 18.0438C19.017 19.2042 17.1403 19.8059 15.1991 19.8059C13.2579 19.8059 11.3812 19.1971 9.8054 18.0367C9.50455 17.8146 9.09626 17.7 8.71663 17.7716C5.72251 18.1799 3.02923 19.8704 1.34593 22.4133C1.00928 22.9003 0.708431 23.459 0.486379 23.9819C0.371771 24.204 0.414749 24.469 0.522193 24.6911C0.823038 25.214 1.19551 25.7369 1.53217 26.1881C2.05507 26.8973 2.62094 27.5348 3.25128 28.1365C3.77418 28.6594 4.37587 29.1464 4.9704 29.6335C7.9287 31.8397 11.4815 33.0001 15.149 33.0001C18.8164 33.0001 22.3692 31.8397 25.3275 29.6335C25.9292 29.1823 26.5238 28.6594 27.0467 28.1365C27.6483 27.5348 28.2429 26.9044 28.7658 26.1881C29.1382 25.7011 29.4749 25.214 29.7757 24.6911C29.9763 24.4762 30.0193 24.2112 29.9047 23.9891Z"
                fill={colors[num]}
              />
            </svg>
          </div>
          <h4>{title}</h4>
          <Typography variant="subtitle1">
            {moment(createdAt).format('MM.DD.YYYY')}
          </Typography>
        </div>
      </Link>
    );
  }
}

export default ClientDashboard;
