import { Assigned } from '../types';
import { CompetencyOverviewChart } from './CompetencyOverviewChart';
import { dimensionDefsToData } from './dimensionDefsToData';
import { useMemo } from 'react';
import './styles.scss';

export function Page08_CompetencyOverview(props: Assigned) {
  const data = useMemo(
    () => dimensionDefsToData(props.dimensionDefs),
    [props.dimensionDefs]
  );

  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>Competency Overview</h4>
          <p style={{ fontSize: '10px' }}>
            The following illustrates your self-indicated level of mastery of
            each of the 22 competencies.
          </p>

          <section id="competency-overview-chart">
            <CompetencyOverviewChart data={data} />
          </section>
        </div>
      </div>
    </div>
  );
}
