import moment from 'moment';
import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import EditModal from '../../../Components/EditModal';
import '../../../App.css';
import SortableTable from '../../../Components/SortableTable';
import { getItemHistory } from '../../../services/item';

const ViewHistoryModal = ({ setOpen, itemId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [numSelected, setNumSelected] = useState(0);

  const handleCancel = () => {
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setIsLoading(true);
      const history = await getItemHistory(itemId);
      setHistory(history);
      setIsLoading(false);
    };

    fetchHistory();
  }, []);

  const columnList = [
    {
      id: 'updated_by',
      disablePadding: false,
      label: 'Updated By',
      getValue: row => row?.first_name + ' ' + row?.last_name,
      render: row => row?.first_name + ' ' + row?.last_name,
      width: 20,
    },
    {
      id: 'updated_at',
      disablePadding: false,
      label: 'Updated Date',
      getValue: row => row?.updated_at,
      render: row => {
        return moment(row?.updated_at).format('YYYY-MM-DD HH:mm:ss');
      },
      width: 20,
    },
  ];

  return (
    <EditModal
      title={'View History'}
      handleCancel={handleCancel}
      contentPaddingLeft={0}
      minWidth={'60vw'}
    >
      <>
        <SortableTable
          columns={columnList}
          rows={history}
          title={`${history.length} History`}
          downloadAllEntriesAsCsv={() => {}}
          defaultOrderBy="updated_at"
          defaultOrder="desc"
          setSelected={setNumSelected}
        ></SortableTable>
      </>
      <Box
        sx={{
          mb: 2,
          mt: 2,
          display: 'inline-flex',
          justifyContent: 'center',
          width: '100%',
          mb: 0,
        }}
      >
        <Button
          variant="contained"
          onClick={handleCancel}
          sx={{
            mr: 2,
            width: '40%',
            maxWidth: '250px',
          }}
        >
          Close
        </Button>
      </Box>
    </EditModal>
  );
};

export default ViewHistoryModal;
