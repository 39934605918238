import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SortableTable from '../../Components/SortableTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UpdateUserModal from './UpdateUserModal';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import BasicSnackbar from '../../Components/Utilities/BasicSnackbar';
import { put as putUser } from '../../services/user';
import request from '../../RequestUtil.js';

const ITEM_HEIGHT = 48;
const TableMenu = ({ id, setIsRowUpdated, row, index, isAdmin }) => {
  const [selectedUserId, setSelectedUserId] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {modalOpen && (
        <UpdateUserModal
          handleCancel={() => {
            setModalOpen(false);
          }}
          title={'Edit User'}
          id={selectedUserId}
          setIsRowUpdated={setIsRowUpdated}
          row={selectedRow}
          isAdmin={isAdmin}
        ></UpdateUserModal>
      )}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '29ch',
          },
        }}
      >
        <MenuItem
          selected={false}
          onClick={() => {
            handleClose();
            setModalOpen(true);
            setSelectedUserId(id);
            setSelectedRow(row);
          }}
        >
          <Typography variant="inherit" noWrap>
            Edit User
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

const Users = props => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(2000);
  const [tableData, setTableData] = useState([]);
  const [numOfElements, setNumOfElements] = useState(0);
  const [numSelected, setNumSelected] = useState(0);
  const [isUserUpdated, setIsRowUpdated] = useState([1]);
  const [isAdmin, setIsAdmin] = useState(
    _.get(props, 'user.perms', []).includes(4)
  );
  const history = useHistory();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState('');
  const [responseSeverity, setResponseSeverity] = React.useState('success');
  const { coachFeatureEnabled } = props.firm;

  useEffect(() => {
    const fetchRows = async () => {
      const response = await fetch(
        `/api/portal-users?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      const data = await response.json();
      setTableData(data.rows);
      setNumOfElements(data.count);
    };
    fetchRows();
  }, [pageNumber, pageSize, isUserUpdated]);

  const getColumns = () => {
    const columns = [
      {
        id: 'emailAddr',
        numeric: false,
        disablePadding: false,
        label: 'Email Address',
        isVisible: () => true,
        getValue: row => row.emailAddr,
        render: row => row.emailAddr,
      },
      {
        id: 'userName',
        numeric: false,
        disablePadding: false,
        label: 'Name',
        isVisible: () => true,
        getValue: row => `${row.firstName} ${row.lastName}`,
        render: row => `${row.firstName} ${row.lastName}`,
      },
      {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: 'Date Added',
        isVisible: () => true,
        getValue: row => row.createdAt,
        render: row => moment(row.createdAt).format('MM/DD/YYYY'),
      },
      {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        isVisible: () => true,
        getValue: row => row.status,
        render: row => _.capitalize(row.status),
      },
      {
        id: 'isDailyEmailEnabled',
        numeric: false,
        disablePadding: false,
        label: 'Daily Email Enabled',
        stopEventPropagation: true,
        isVisible: () => true,
        getValue: row => row.isDailyEmailEnabled,
        render: row => (
          <Checkbox
            checked={row.isDailyEmailEnabled}
            onChange={async event => {
              const response = await putUser(row.userId, {
                ...row,
                isDailyEmailEnabled: !row.isDailyEmailEnabled,
              });

              if (response.status !== 200) {
                setResponseMessage('Failed to update user');
                setResponseSeverity('error');
              } else {
                setResponseMessage('User updated successfully');
                setResponseSeverity('success');
                setIsRowUpdated([1]);
              }

              setSnackbarOpen(true);
            }}
            inputProps={{
              'aria-label': 'select for is daily email enabled',
            }}
          />
        ),
      },
      {
        id: 'isCoach',
        numeric: false,
        disablePadding: false,
        label: 'Coach',
        stopEventPropagation: true,
        isVisible: () => coachFeatureEnabled,
        getValue: row => row.isCoach,
        render: row => (
          <Checkbox
            checked={row.isCoach}
            onChange={async event => {
              const response = await putUser(row.userId, {
                ...row,
                isCoach: !row.isCoach,
              });

              if (response.status !== 200) {
                setResponseMessage('Failed to update user');
                setResponseSeverity('error');
              } else {
                setResponseMessage('User updated successfully');
                setResponseSeverity('success');
                setIsRowUpdated([1]);
              }

              setSnackbarOpen(true);
            }}
            inputProps={{
              'aria-label': 'select this if user is a coach',
            }}
          />
        ),
      },
      {
        id: '',
        numeric: false,
        disablePadding: false,
        label: '',
        width: 30,
        stopEventPropagation: true,
        isVisible: () => true,
        getValue: row => row.index,
        render: (row, index) => (
          <TableMenu
            id={row.userId}
            isAdmin={isAdmin}
            setIsRowUpdated={setIsRowUpdated}
            index={index}
            row={row}
          />
        ),
      },
    ];

    return columns.filter(cell => cell.isVisible());
  };

  return (
    <>
      <SortableTable
        columns={getColumns()}
        rows={tableData}
        title={`${tableData.length} Users`}
        downloadAllEntriesAsCsv={() => {}}
        defaultOrderBy="emailAddr"
        setSelected={setNumSelected}
      >
        <Stack
          spacing={2}
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
        >
          {!isAdmin ? (
            <Button
              sx={{
                width: 'fit-content',
              }}
              variant="contained"
              onClick={() => {
                history.push('/users/create');
              }}
            >
              <AddIcon />
              Invite&nbsp;New&nbsp;User
            </Button>
          ) : null}
        </Stack>
      </SortableTable>
      <BasicSnackbar
        message={responseMessage}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        severity={responseSeverity}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    firm: state.firm,
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Users);
