import { Assigned } from '../types';
import './styles.scss';
import { CoreCompetencyChip } from './CoreCompetencyChip';

import { DimensionColorsAndIcons } from '../constants';
import { displayCompetencyName, displayDimensionName } from '../utils';
import { useMemo } from 'react';

export function Page09_KeyCompetencies(data: Assigned) {
  // sort in descending order
  const growthOpportunities = useMemo(() => {
    return data.growthOpportunities.sort(
      (a, b) => b.individualScore - a.individualScore
    );
  }, [data]);

  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>Key Competencies</h4>
          <p style={{ marginBottom: '11px', fontSize: '10px' }}>
            The following identifies the three competencies that you are highest
            in and the three competencies which present the greatest opportunity
            for growth.{' '}
          </p>

          <p style={{ marginBottom: '11px', fontSize: '10px' }}>
            An overview of each of these competencies follows, along with a more
            in-depth analysis and questions for reflection.
          </p>

          <div className="core-competencies-container">
            <h6>Your Core Strengths:</h6>
            <div className="core-competency-inner">
              <div className="core-competency-stack">
                {data.coreStrengths.map((coreStrength, index) => {
                  const dimension =
                    DimensionColorsAndIcons[coreStrength.dimensionName];
                  if (!dimension) {
                    console.error(
                      'Dimension not found:',
                      coreStrength.dimensionName
                    );
                    return null;
                  }
                  return (
                    <CoreCompetencyChip
                      key={index}
                      category_name={displayDimensionName(
                        coreStrength.dimensionName
                      )}
                      competency_name={displayCompetencyName(
                        coreStrength.indicatorName
                      )}
                      icon_url={dimension.no_circle_icon_url}
                      hex_color={dimension.color}
                      value={coreStrength.individualScore}
                    />
                  );
                })}
              </div>
              <div className="core-competency-blurb">
                <p>
                  How have you seen your strengths show up at work? How have
                  your strengths enabled you to succeed? Have your strengths
                  ever been an obstacle at work?
                </p>
              </div>
            </div>

            <h6>Your Growth Opportunities:</h6>
            <div className="core-competency-inner">
              <div className="core-competency-stack">
                {growthOpportunities.map((growthOpportunity, index) => {
                  const dimension =
                    DimensionColorsAndIcons[growthOpportunity.dimensionName];
                  if (!dimension) {
                    console.error(
                      'Dimension not found:',
                      growthOpportunity.dimensionName
                    );
                    return null;
                  }
                  return (
                    <CoreCompetencyChip
                      key={index}
                      category_name={displayDimensionName(
                        growthOpportunity.dimensionName
                      )}
                      competency_name={displayCompetencyName(
                        growthOpportunity.indicatorName
                      )}
                      icon_url={dimension.no_circle_icon_url}
                      hex_color={dimension.color}
                      value={growthOpportunity.individualScore}
                    />
                  );
                })}
              </div>
              <div className="core-competency-blurb">
                <p>
                  Do these opportunities align with your expectations? How have
                  these competencies served you? Are there obstacles that have
                  impacted growth in these areas?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
