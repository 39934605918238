import PlusIcon from '../icons/PlusIcon.svg';

export function Page02_About() {
  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>
            <span className="title-bar"></span>
            About This Report
          </h4>
          <p style={{ fontSize: '10px' }}>
            Every lawyer has their own unique set of professional strengths and
            talents. These strengths and the behaviors that accompany them are
            often called professional competencies. Competencies can be
            demonstrated in different ways depending on the person or
            circumstances, and there is no single, correct way that lawyers
            should show up in the workplace. However, understanding the various
            elements of professional competencies and how you embody them will
            empower you to be more intentional about the behaviors you exhibit
            and how you leverage them for greater success and increased job
            satisfaction.
          </p>
          <p style={{ fontSize: '10px' }}>
            The Modern Lawyer Competency Framework&trade; was developed in
            consultation with organizational psychologists, legal professionals,
            and academics leveraging extensive research into those workplace
            behaviors essential to high-performing lawyers. Your results are
            organized into seven dimensions, or clusters of related
            competencies, and shown on an individual competency basis, measuring
            how frequently or strongly you engage in certain behaviors.
          </p>

          <span className="plus-icon">
            <img src={PlusIcon} alt="Plus Icon" />
            <h5>Recognizing and Reflecting</h5>
          </span>

          <p style={{ fontSize: '10px' }}>
            Accelerate is a behavioral assessment. It may therefore reflect
            patterns driven by your personality and preferences. However, other
            aspects of your behavior are contextual and shaped by your
            profession, organization, colleagues, internal and external clients,
            roles or positions, and so on. You may, therefore, find some
            insights that align with how you believe you show up in the
            workplace and others that surprise you. Where your report does not
            align with your understanding of yourself, consider how your current
            context may be influencing how you show up, and what, if anything,
            you would like to do about that.
          </p>
          <p style={{ fontSize: '10px' }}>
            We encourage you to review the report in its entirety. Look for
            patterns; note any areas that resonate with you, as they may
            highlight a starting point for further reflection or conversation.
            Are there any priorities you believe are essential to your success?
          </p>
        </div>
      </div>
    </div>
  );
}
