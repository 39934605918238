import axios from 'axios';

// Todo - move this to a common place later
axios.defaults.withCredentials = true;

export const me = () => {
  return axios
    .get(`/api/users/session`, { withCredentials: true })
    .then(response => {
      console.log('response: ', response);
      return response.data;
    });
};

export const logout = () => {
  return axios.post(`/v1/auth/logout`, {}).then(response => {
    console.log('response: ', response);
    return response.data;
  });
};
