export type AnswerPayload = {
  question_id: number;
  answer_id: number;
  assign_id: string;
  question_type: number;
};

/**
 * this stupid thing has to run serially
 * @param answers
 */
export async function submitAnswers(answers: AnswerPayload[]) {
  for (const answer of answers) {
    console.log('submitting answer', answer);
    await submitAnswer(answer);
  }
  console.log('done submitting answers');
  return 'done';
}

async function submitAnswer(answer: AnswerPayload) {
  const payload = {
    items: [answer],
    doUpdate: true,
  };
  const result = await fetch('/api/assessments/user/assigned/take/answers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return result.text();
}
