import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const Loader = ({ minHeight = 'auto' }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight }}
  >
    <CircularProgress />
    <Box sx={{ p: '1rem' }}>Loading ...</Box>
  </Grid>
);

export default Loader;
