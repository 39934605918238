import { parse } from 'json2csv';
import { saveAs } from 'file-saver';

export function isValidEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

/**
 * This method is used to download files on the client
 * @param {object} exportData - An object with key-values
 * @param {string} filename - Name of downloaded file
 */
export function downloadFile({ exportData, filename }) {
  const parsedData = parse(exportData);
  const blob = new Blob([parsedData], {
    type: 'text/plain;charset=utf=8',
  });
  saveAs(blob, filename);
}
