import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Loader from '../../Loader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import './DragAndDrop.css';
import _ from 'lodash';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

/**
 * Fetch user's answers for a question
 *
 * TODO: this app should use react-query
 *
 * @param assign_id
 * @param question_id
 * @returns {Promise<AxiosResponse<any>>}
 */
async function fetchAnswers({ assign_id, question_id }) {
  return axios
    .post('/api/assessments/user/assigned/take/answered', {
      assign_id,
      question_id,
    })
    .then(r => r.data);
}

/**
 * Save ordered answers to the server
 *
 * TODO: this app should use react-query
 *
 * @param assign_id
 * @param items
 * @param doUpdate
 * @returns {Promise<void>}
 */
async function saveAnswers({ assign_id, items, doUpdate }) {
  const body = items.map((item, idx) => ({
    assign_id,
    question_id: item.question_id,
    answer_id: item.answer_id,
    answer_order: idx + 1,
  }));
  return axios({
    method: 'post',
    url: '/api/assessments/user/assigned/take/answers',
    responseType: 'json',
    data: {
      items: body,
      doUpdate,
    },
  });
}

/**
 * orders question's answers
 * - if savedAnswers, preserve savedAnswers order
 * - else randomize order
 * @param savedAnswers
 * @param question
 */
function calcOrderedAnswers({ savedAnswers, questionAnswers }) {
  if (!savedAnswers?.length) {
    return _.shuffle(questionAnswers);
  }
  return savedAnswers
    .sort((a, b) => a.answer_order - b.answer_order)
    .map(answer => {
      return questionAnswers.find(ans => ans.answer_id === answer.answer_id);
    });
}

/**
 * Move an item from one position to another in an array without mutating the original array.
 *
 * @param {Array} array - The original array.
 * @param {number} fromIndex - The index of the element to move.
 * @param {number} toIndex - The index to move the element to.
 * @return {Array} A new array with the element moved.
 */
function moveElement(array, fromIndex, toIndex) {
  // Create a copy of the array to avoid mutations
  let result = _.clone(array);

  // Remove the item from the original position and store it
  const [item] = result.splice(fromIndex, 1);

  // Insert the item at the new position
  result.splice(toIndex, 0, item);

  return result;
}

/**
 * calculate updated item answer position number during drag
 * @param index
 * @returns {*}
 */
function calcItemNumber({ dragDetails, index }) {
  // dragging an answer before this item to this item or after
  if (dragDetails.fromIndex < index) {
    if (dragDetails.toIndex >= index) {
      return index;
    }
  }

  // dragging an answer after this item to this item or before
  if (dragDetails.fromIndex > index) {
    if (dragDetails.toIndex <= index) {
      return index + 2;
    }
  }

  if (dragDetails.fromIndex === index) {
    if (!_.isNil(dragDetails.toIndex)) {
      return dragDetails.toIndex + 1;
    }
  }
  return index + 1;
}

export function DragAndDropQuestion({ assign_id, question }) {
  const [userAnswers, setUserAnswers] = useState([]);
  const isLoading = userAnswers.length === 0;

  const [dragDetails, setDragDetails] = useState({
    fromIndex: null,
    toIndex: null,
  });

  /**
   * load user's existing answers on component mount
   */
  useEffect(async () => {
    const savedAnswers = await fetchAnswers({
      assign_id,
      question_id: question.question_id,
    });

    const orderedAnswers = calcOrderedAnswers({
      savedAnswers,
      questionAnswers: question.answers,
    });

    // if question wasn't answered previously, persist ordered answers now
    if (!savedAnswers?.length) {
      saveAnswers({ assign_id, items: orderedAnswers, doUpdate: true });
    }

    setUserAnswers(orderedAnswers);
  }, []);

  const handleDrop = async droppedItem => {
    if (!droppedItem.destination) return; // ignore drop outside droppable container
    if (droppedItem.destination.index === droppedItem.source.index) return; // ignore drop in the same position

    const reorderedAnswers = moveElement(
      userAnswers,
      droppedItem.source.index,
      droppedItem.destination.index
    );

    try {
      saveAnswers({ assign_id, items: reorderedAnswers, doUpdate: true });
      setUserAnswers(reorderedAnswers);
    } catch (err) {
      console.log(err);
      // Handle error state or display error message
    }
    setDragDetails({ fromIndex: null, toIndex: null });
  };

  /**
   * track items' position during drag so we can calculcate the new position number
   * @param source
   * @param destination
   * @returns {Promise<void>}
   */
  const handleDragUpdate = async ({ source, destination }) => {
    setDragDetails({ fromIndex: source.index, toIndex: destination?.index });
  };

  return (
    <DragDropContext
      onDragEnd={e => handleDrop(e)}
      isDragDisabled={isLoading}
      onDragUpdate={handleDragUpdate}
    >
      <div className="qst">
        {isLoading ? (
          <Loader />
        ) : (
          <fieldset
            id={'question-' + question.question_id}
            className="checkboxContainer"
          >
            <h4 className="qst-number">
              Question {question.qstNumber} of {question.questionsCount}
            </h4>
            <h3 className="qst-title">{question.question_title}</h3>
            <div className="qst-inner">
              <p>{question.question_description}</p>
            </div>

            <Droppable droppableId={question.question_id.toString()}>
              {provided => {
                return (
                  <div
                    className="list-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {userAnswers.map((item, index) => {
                      const { answer_id, answer_title } = item;
                      return (
                        <Draggable
                          className="draggable"
                          key={answer_id}
                          draggableId={answer_id.toString()}
                          index={index}
                        >
                          {provided => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <div className="item-answer-container">
                                <span className="item-answer">
                                  <span className="item-answer-number">
                                    {calcItemNumber({ dragDetails, index })}
                                  </span>
                                  {answer_title}
                                </span>
                                <DragIndicatorIcon className="item-answer-icon" />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </fieldset>
        )}
      </div>
    </DragDropContext>
  );
}

// exports for testing only
// if (process.env['NODE_ENV'] === 'test') {
//   module.exports.calcOrderedAnswers = calcOrderedAnswers;
//   module.exports.moveElement = moveElement;
//   module.exports.calcItemNumber = calcItemNumber;
// }
