import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  plugins: {
    title: {
      display: true,
      text: 'Alignment Distribution',
      position: 'top',
      align: 'start',
      padding: {
        bottom: 20,
        top: 0,
      },
    },
    legend: {
      position: 'bottom',
      display: true,
      labels: {
        usePointStyle: true,
      },
    },
  },
};

export default function PieChart({ likely, wildcard, unlikely }) {
  const data = {
    labels: ['High', 'Medium', 'Low'],
    datasets: [
      {
        label: 'Alignment Distribution',
        data: [likely, wildcard, unlikely],
        backgroundColor: ['#008b57', '#eaaa00', '#da0a00'],
        borderColor: ['#ffffff', '#ffffff', '#ffffff'],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} options={options} />;
}
