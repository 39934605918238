import { Assigned } from '../types';
import './styles.scss';
import { DimensionOverviewChart } from './DimensionOverviewChart';
import { DimensionCategories, DimensionColorsAndIcons } from '../constants';
import { useMemo } from 'react';
import { displayDimensionName } from '../utils';

export function Page07_DimensionOverview(data: Assigned) {
  const dimensions = DimensionCategories.flatMap(
    category => category.dimensions
  );

  const chartData = useMemo(
    () =>
      data.candidateDimensions.map(dimension => {
        return {
          name: displayDimensionName(dimension.name),
          value: Math.round(dimension.individualScore),
          color: DimensionColorsAndIcons[dimension.name].color,
        };
      }),
    [data.candidateDimensions]
  );

  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <h4>Dimension Overview </h4>
          <p style={{ fontSize: '10px' }}>
            This chart illustrates how you present on the various competencies
            when viewed at the dimension level. The higher the percentage, the
            more you indicated you lean into these types of behaviors at work.
            The key below provides additional context about each of the
            dimensions, and the types of behaviors they encompass.
          </p>

          <div
            style={{
              marginLeft: '-10px',
              marginBottom: '-15px',
            }}
          >
            <DimensionOverviewChart data={chartData} />
          </div>

          <div className="dimension-definitions-container">
            {dimensions.map((dimension, index) => (
              <div
                className="dimension"
                key={index}
                style={{
                  gridColumn: index >= 3 ? 2 : 1, // Starts placing items in the second column from the fourth item onward
                }}
              >
                <img
                  src={dimension.icon_url}
                  alt={`${displayDimensionName(dimension.name)} icon`}
                  className="dimension-icon"
                />
                <div>
                  <p className="dimension-title">
                    {displayDimensionName(dimension.name)}:{' '}
                    <span className="dimension-description">
                      {dimension.definition}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
