import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Button, Grid, TableSortLabel, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import _ from 'lodash';

import writeXlsxFile from 'write-excel-file';
import { ClientCandidateMapping } from './types';

type UserAnswer = {
  assessment_id: number;
  item_id: number;
  indicator_name: string;
  question_title: string;
  label_left: string;
  label_right: string;
  answer_title: string;
  score_value: number;
  score_ignore: boolean;
};

const columns = [
  {
    id: 'assessment_id',
    disablePadding: false,
    label: 'assessment_id',
    getValue: (row: UserAnswer) => row.assessment_id,
  },
  {
    id: 'item_id',
    disablePadding: false,
    label: 'item_id',
    getValue: (row: UserAnswer) => row.item_id,
  },
  {
    id: 'indicator_name',
    disablePadding: false,
    label: 'indicator_name',
    getValue: (row: UserAnswer) => row.indicator_name,
  },
  {
    id: 'question_title',
    disablePadding: false,
    label: 'question_title',
    getValue: (row: UserAnswer) => row.question_title,
  },
  {
    id: 'label_left',
    disablePadding: false,
    label: 'label_left',
    getValue: (row: UserAnswer) => row.label_left,
  },
  {
    id: 'label_right',
    disablePadding: false,
    label: 'label_right',
    getValue: (row: UserAnswer) => row.label_right,
  },
  {
    id: 'answer_title',
    disablePadding: false,
    label: 'answer_title',
    getValue: (row: UserAnswer) => row.answer_title,
  },
  {
    id: 'score_value',
    disablePadding: false,
    label: 'score_value',
    getValue: (row: UserAnswer) => row.score_value,
  },
  {
    id: 'score_ignore',
    disablePadding: false,
    label: 'score_ignore',
    getValue: (row: UserAnswer) => row.score_ignore,
  },
];

const descendingComparator = (
  a: object,
  b: object,
  order: 'asc' | 'desc',
  orderBy: string,
  columns: any
) => {
  const getValue = _.find(columns, { id: orderBy })?.getValue;
  const A =
    typeof getValue(a) === 'string' ? getValue(a).toUpperCase() : getValue(a); // ignore upper and lowercase
  const B =
    typeof getValue(b) === 'string' ? getValue(b).toUpperCase() : getValue(b); // ignore upper and lowercase

  if (A === null && B === null) return 0;
  if (A === null) return 1;
  if (B === null) return -1;

  // General comparison
  if (order === 'asc') return A < B ? -1 : A > B ? 1 : 0;
  else return A < B ? 1 : A > B ? -1 : 0;
};

type Props = {
  data: ClientCandidateMapping;
};
export function ShowCompletedAssessment({ data }: Props) {
  const [orderBy, setOrderBy] = useState('item_id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const assignment_id = data.mappings[0]?.assessmentAssignId;

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUserAnswers = useMemo(() => {
    // console.log("sorted", query.data?.userAnswers)
    if (!data?.userAnswers) return [];

    return data.userAnswers.sort((a, b) => {
      return descendingComparator(a, b, order, orderBy, columns);
    });
  }, [data?.userAnswers, order, orderBy]);

  function showJson() {
    const fileName = `debug-assignment-${assignment_id}.json`;
    const json = JSON.stringify(sortedUserAnswers, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // a.download = fileName
    a.target = '_blank';
    a.click();
  }

  function downloadExcel() {
    const fileName = `debug-assignment-${assignment_id}.xlsx`;
    makeExcel(fileName, sortedUserAnswers);
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ mb: 1 }}
      >
        <Typography variant="h4">Debug Assignment: {assignment_id}</Typography>
        <div>
          <Button
            variant="contained"
            sx={{ marginRight: 1 }}
            onClick={showJson}
          >
            <DownloadIcon /> Show JSON
          </Button>
          <Button
            variant="contained"
            sx={{ marginRight: 0 }}
            onClick={downloadExcel}
          >
            <DownloadIcon /> Download Excel
          </Button>
        </div>
      </Grid>

      <TableContainer sx={{ height: '50vh' }}>
        <Table sx={{ borderCollapse: 'separate' }}>
          {/*<TableHead sx={{backgroundColor: "#5d2a5f", color: "#fff", position:'sticky', top:0, zIndex:99}}>*/}
          <TableHead
            sx={{
              backgroundColor: '#5d2a5f',
              color: '#fff',
              position: 'sticky',
              top: 0,
              zIndex: 99,
            }}
          >
            <TableRow>
              {columns.map((column, ind) => (
                <TableCell key={ind} align={'left'}>
                  <TableSortLabel
                    sx={{
                      color: '#fff !important',
                      '&:hover': {
                        color: '#fff!important',
                      },
                      '&.Mui-active': {
                        color: '#fff !important',
                      },
                      '&.Mui-active svg': {
                        color: '#fff !important',
                      },
                      // minWidth: column?.minWidth,
                    }}
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={() => handleRequestSort(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUserAnswers.map((row: any, index: number) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, ind) => (
                    <TableCell key={ind} align={'left'}>
                      {String(column.getValue(row))}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <pre>{JSON.stringify(data.scores, null, 2)}</pre>
    </>
  );
}

async function makeExcel(fileName: string, userAnswers: UserAnswer[]) {
  const userAnswersSchema = [
    {
      column: 'assessment_id',
      type: Number,
      value: (ua: UserAnswer) => ua.assessment_id,
    },
    {
      column: 'item_id',
      type: Number,
      value: (ua: UserAnswer) => ua.item_id,
    },
    {
      column: 'indicator_name',
      type: String,
      value: (ua: UserAnswer) => ua.indicator_name,
    },
    {
      column: 'question_title',
      type: String,
      value: (ua: UserAnswer) => ua.question_title,
    },
    {
      column: 'label_left',
      type: String,
      value: (ua: UserAnswer) => ua.label_left,
    },
    {
      column: 'label_right',
      type: String,
      value: (ua: UserAnswer) => ua.label_right,
    },
    {
      column: 'answer_title',
      type: String,
      value: (ua: UserAnswer) => ua.answer_title,
    },
    {
      column: 'score_value',
      type: Number,
      value: (ua: UserAnswer) => ua.score_value,
    },
    {
      column: 'score_ignore',
      type: Boolean,
      value: (ua: UserAnswer) => ua.score_ignore,
    },
  ];
  // @ts-ignore
  await writeXlsxFile([userAnswers], {
    schema: [userAnswersSchema],
    sheets: ['userAnswers'],
    stickyRowsCount: 1,
    fileName,
  });
}
