import html2canvas from 'html2canvas';
import { useRef } from 'react';

import './SvgFonts.css';
import { applySvgStyles } from './SvgFontsUtils';
import { DimensionOverviewChart } from './Reports/Components/Accelerate/Page07_DimensionOverview/DimensionOverviewChart';
import { DimensionColorsAndIcons } from './Reports/Components/Accelerate/constants';
import { Page07_DimensionOverview } from './Reports/Components/Accelerate/Page07_DimensionOverview/Page07_DimensionOverview';

import MockAllData from './Reports/Components/Accelerate/__fixtures__/api.scoring.assigned.json';
import { Page08_CompetencyOverview } from './Reports/Components/Accelerate/Page08_CompetencyOverview/Page08_CompetencyOverview';

export function SvgFonts() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
      }}
    >
      {/*<Cloner font={'Arial'}/>*/}
      {/*<Cloner font={'Times'}/>*/}
      {/*<Cloner font={'Catamaran'}/>*/}
      <Thingy />
    </div>
  );
}

const MockChartData = [
  {
    name: 'Reputation Management',
    value: 90,
    color: DimensionColorsAndIcons['Reputation Management'].color,
  },
  {
    name: 'Self-Management',
    value: 82,
    color: DimensionColorsAndIcons['Self-Management'].color,
  },
  {
    name: 'Relationships + Teamwork',
    value: 75,
    color: DimensionColorsAndIcons['Relationships + Teamwork'].color,
  },
  {
    name: 'Pursuit of Excellence',
    value: 63,
    color: DimensionColorsAndIcons['Pursuit of Excellence'].color,
  },
  {
    name: 'Dynamic Approach',
    value: 51,
    color: DimensionColorsAndIcons['Dynamic Approach'].color,
  },
  {
    name: 'Business Orientation',
    value: 47,
    color: DimensionColorsAndIcons['Business Orientation'].color,
  },
  {
    name: 'Execution',
    value: 21,
    color: DimensionColorsAndIcons['Execution'].color,
  },
];

function Thingy() {
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  return (
    <div
      style={{
        // width: '500px',
        height: '100vh',
        display: 'flex',
        // backgroundColor: 'yellow',
      }}
    >
      <div>
        <button
          onClick={() =>
            ref1.current &&
            ref2.current &&
            duplicate(ref1.current, ref2.current)
          }
        >
          Duplicate
        </button>
        <div
          ref={ref1}
          style={{
            width: '500px',
            height: '100%',
            // backgroundColor: 'yellow',
          }}
        >
          {/*<Page07_DimensionOverview {...MockAllData}/>*/}
          <Page08_CompetencyOverview {...MockAllData} />
        </div>
      </div>

      <div
        id={'target'}
        ref={ref2}
        style={{
          width: '500px',
          height: '100%',
          backgroundColor: 'yellow',
        }}
      ></div>
    </div>
  );
}

function duplicate(src: HTMLElement, target: HTMLElement) {
  html2canvas(src, {
    allowTaint: true,
    onclone: async doc => {
      console.log('cloning', doc);
      await applySvgStyles(doc);
    },
  }).then(canvas => {
    target.replaceWith(canvas);
  });
}

function Cloner({ font }: { font: string }) {
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div>
        <button
          onClick={() =>
            ref1.current &&
            ref2.current &&
            duplicate(ref1.current, ref2.current)
          }
        >
          Duplicate
        </button>
        <div
          id={'source'}
          ref={ref1}
          style={{
            width: '200px',
            height: '100px',
          }}
        >
          <svg width="200" height="100">
            <rect
              x="10"
              y="10"
              width="180"
              height="80"
              fill="none"
              stroke="black"
              stroke-width="1"
            />
            <text
              x="100"
              y="50"
              font-family={font}
              font-size="20"
              font-weight={800}
              text-anchor="middle"
              alignment-baseline="middle"
              fill="black"
            >
              {font}
            </text>
          </svg>
        </div>
      </div>

      <div
        id={'target'}
        ref={ref2}
        style={{
          width: '200px',
          height: '100px',
        }}
      >
        <svg width="200" height="100">
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            fill="none"
            stroke="black"
            stroke-width="1"
          />
        </svg>
      </div>
    </div>
  );
}
