import './styles.scss';
import { Assigned } from '../types';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { IndicatorPage } from './IndicatorPage';
import { DimensionCategories } from '../constants';
import { displayCompetencyName, displayDimensionName } from '../utils';

type Props = {
  data: Assigned;
  startingPage: number;
};

export function Page10_Indicators({ data, startingPage }: Props) {
  const fullName = `${data.user.first_name} ${data.user.last_name}`;

  const dimensions = DimensionCategories.flatMap(c => c.dimensions);
  const competencies = dimensions.flatMap(dimension => {
    return dimension.competencies.map(competency => {
      const highlight = data.highlights.find(
        h => h.indicatorName === competency.name
      );

      return {
        indicator_name: competency.name,
        indicator_description: competency.definition,
        for_reflection: competency.for_reflection,
        hex_color: dimension.hex_color,
        subcompetencies:
          highlight?.children.map(child => {
            return {
              name: child.indicatorName,
              score: child.individualScore,
            };
          }) || [],
      };
    });
  });

  return (
    <>
      {competencies.map((competency, index) => {
        const pageNum = startingPage + index;
        const {
          indicator_name,
          indicator_description,
          hex_color,
          subcompetencies,
          for_reflection,
        } = competency;
        return (
          <div
            id={`page${pageNum}`}
            key={index}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin "
          >
            <Header />
            <div className="section">
              <IndicatorPage
                indicator_name={displayCompetencyName(indicator_name)}
                indicator_description={indicator_description}
                hex_color={hex_color}
                subcompetencies={subcompetencies}
                reflections={for_reflection}
              />
            </div>
            <Footer name={fullName} pageNum={pageNum} />
          </div>
        );
      })}
    </>
  );
}
