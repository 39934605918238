export function DynamicApproach({ width = 32, height = 32, x = 0, y = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="15.5" fill="#F3F6F9" stroke="#BFD5D9" />
      <ellipse
        cx="4.53638"
        cy="4.57011"
        rx="4.53638"
        ry="4.57011"
        transform="matrix(0.862788 0.505566 -0.494455 0.869203 9.38892 6.26086)"
        fill="#00629B"
      />
      <ellipse
        cx="3.14057"
        cy="3.16392"
        rx="3.14057"
        ry="3.16392"
        transform="matrix(0.862788 0.505566 -0.494455 0.869203 16.4963 17.7591)"
        fill="#00629B"
      />
      <ellipse
        cx="3.14057"
        cy="3.16392"
        rx="3.14057"
        ry="3.16392"
        transform="matrix(0.862788 0.505566 -0.494455 0.869203 21.7113 8.59222)"
        fill="#00629B"
      />
      <rect
        width="1.41122"
        height="2.78144"
        rx="0.70561"
        transform="matrix(-0.0774566 0.996996 -0.996813 -0.0797713 19.2174 11.9835)"
        fill="#6690B6"
      />
      <rect
        width="1.39777"
        height="2.8099"
        rx="0.698886"
        transform="matrix(-0.820095 0.572228 0.56083 0.827931 13.0499 17.369)"
        fill="#6690B6"
      />
      <rect
        width="1.39635"
        height="2.81273"
        rx="0.698173"
        transform="matrix(0.862788 0.505566 -0.494455 0.869203 20.6434 16.1148)"
        fill="#6690B6"
      />
    </svg>
  );
}
