import { CSSProperties } from 'react';

type DonutProps = {
  radius: number;
  stroke_width: number;
  hex_color: string;
  empty_hex_color: string;
  border_color: string;
  text_color: string;
  value: number;
  text_style: CSSProperties;
};

export function Donut({
  radius,
  stroke_width,
  hex_color,
  empty_hex_color,
  border_color,
  text_color,
  text_style,
  value,
}: DonutProps) {
  const difference = 100 - value;
  const donutLabel = Math.floor(value);
  const dashArray = value + ' ' + difference;

  /**
   * TODO make the little notch in the donut be white instead of invisible
   * maybe https://recharts.org/en-US/api/RadialBarChart
   */

  return (
    <svg>
      <defs>
        <style>
          @import
          url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");
        </style>
      </defs>

      {/* outer stroke */}
      <circle
        cx="50%"
        cy="50%"
        strokeWidth="1"
        stroke={border_color}
        fill="none"
        pathLength="100"
        r={radius - stroke_width / 2}
      />

      {/* inner stroke */}
      <circle
        cx="50%"
        cy="50%"
        strokeWidth="1"
        stroke={border_color}
        fill="none"
        pathLength="100"
        r={radius + stroke_width / 2}
      />

      {/* fill the empty portion */}
      <circle
        cx="50%"
        cy="50%"
        r={radius}
        strokeWidth={stroke_width}
        stroke={empty_hex_color}
        fill="none"
        pathLength="100"
        strokeDashoffset="0"
        strokeLinecap="butt"
      />

      {/* fill the main portion */}
      <circle
        cx="50%"
        cy="50%"
        r={radius}
        strokeWidth={stroke_width}
        stroke={hex_color}
        fill="none"
        pathLength="100"
        strokeDasharray={dashArray}
        strokeDashoffset="0"
        strokeLinecap="butt"
      />

      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        style={text_style}
        fill={text_color}
      >
        {donutLabel}
      </text>
    </svg>
  );
}
