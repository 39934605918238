import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedValue, theme) {
  return {
    fontWeight:
      selectedValue.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({
  name,
  value,
  options,
  required = false,
  multiple = false,
  subTitle,
  onChange,
  error,
}) {
  const optionsValueLabelMap = options.reduce((acc, option) => {
    acc[option.value] = option.label;
    return acc;
  }, {});
  const theme = useTheme();

  const flexStyle = {
    display: 'flex',
    'flex-direction': 'row',
    'align-items': 'top',

    'justify-content': 'flex-start',
  };

  return (
    <div style={flexStyle}>
      <FormControl sx={{ m: 1, width: '100%' }} error={error}>
        <InputLabel id={`${name}-label`}>{subTitle}</InputLabel>

        <Select
          labelId={`${name}-label`}
          id={name}
          multiple={multiple}
          value={value}
          onChange={onChange}
          input={<OutlinedInput id="select-multiple-chip" label={subTitle} />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={optionsValueLabelMap[value]} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={getStyles(option.value, value, theme)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {required ? (
        <b style={{ color: 'red', paddingLeft: '10px', fontSize: '14pt' }}>*</b>
      ) : null}
    </div>
  );
}
