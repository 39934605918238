import _ from 'lodash';
import React, { Component } from 'react';
import OCIClient from '../Reports/OCIClient';
import { downloadReport } from '../snippets/utils';
import { AccelerateReport } from '../Reports/Components/Accelerate/AccelerateReport';

class ServerReportContianer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitingOnServerData: true,
    };
  }

  componentDidMount = () => {
    // To trigger the event Listener
    document.addEventListener('render-report', () => {
      console.log('* * * * saw render-report event * * * *');
      this.setState(
        {
          waitingOnServerData: false,
        },
        async () => {
          const { fileName, reportId } = window.data;
          switch (reportId) {
            case 1:
              await downloadReport(fileName);
              break;
            case 9:
              /**
               * for new server-rendered Accelerate report, just wait briefly
               *
               * printing is handled by puppeteer printing to PDF
               */
              await new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                }, 3000);
              });
              // await download2(fileName);
              break;
          }
          console.log('* * * * sending printing-complete event * * * *');
          document.dispatchEvent(new Event('printing-complete'));
        }
      );
    });
  };

  getReportComponent = () => {
    const { reportData, reportId, firm } = window.data;
    const scoreType = _.get(firm, 'scoreType', 'numeric');

    switch (reportId) {
      case 1:
        return <OCIClient data={reportData} scoreType={scoreType} />;
      case 9:
        return <AccelerateReport data={reportData} scoreType={scoreType} />;
      default:
        return null;
    }
  };

  render() {
    const { waitingOnServerData } = this.state;

    if (waitingOnServerData) {
      return <div>Waiting on server data</div>;
    }

    const ReportComponent = this.getReportComponent();
    return <div>{ReportComponent}</div>;
  }
}

export default ServerReportContianer;
