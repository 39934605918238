import BigOrangeCircle from './big-orange-circle.svg';
import VoltaWhite from './volta-white.svg';
import './styles.scss';

type Props = {
  prepared_for: string;
  assessment_date: Date;
};
export function Page01_Cover({ prepared_for, assessment_date }: Props) {
  const formatted_date = assessment_date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className={'full-page'}>
      <div className={'cover-page-top'}>
        <div className={'logo-container'}>
          <img src={BigOrangeCircle} alt={'logo'} className="logo" />
          <div className={'title'}>
            <h1>Accelerate</h1>
            <div className={'orange-bar'}>
              <h2>Assessment Report</h2>
            </div>
          </div>
        </div>
      </div>

      <div className={'cover-page-bottom'}>
        <div className={'prepared-for-container border-bottom'}>
          <div className={'prepared-for-text'}>
            <div className={'bold'}>Prepared for</div>
            <div>{prepared_for}</div>
          </div>
          <img src={VoltaWhite} alt={'logo'} className="prepared-for-logo" />
        </div>
        <div className={'prepared-for-container'}>
          <div className={'prepared-date'}>{formatted_date}</div>
          <div className={'copyright'}>©2024 Thine Solutions, Inc.</div>
        </div>
      </div>
    </div>
  );
}
