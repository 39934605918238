import { Page03_About_Cont } from './Page03_About_Cont/Page03_About_Cont';
import { Page02_About } from './Page02_About/Page02_About';
import { Page04_TableOfContents } from './Page04_TableOfContents/Page04_TableOfContents';
import { Page05_ModernLawyerCompetencyFramework } from './Page05_ModernLawyerCompetencyFramework/Page05_ModernLawyerCompetencyFramework';

import './styles.scss';
import { Header } from './Header';
import { Footer } from './Footer';
import { Page06_DimensionBehaviors } from './Page06_DimensionBehaviors/Page06_DimensionBehaviors';
import { Page07_DimensionOverview } from './Page07_DimensionOverview/Page07_DimensionOverview';
import { Page08_CompetencyOverview } from './Page08_CompetencyOverview/Page08_CompetencyOverview';
import { Page09_KeyCompetencies } from './Page09_KeyCompetencies/Page09_KeyCompetencies';
import { Page10_Indicators } from './Page10_Indicators/Page10_Indicators';
import Zoomer from '../../../Components/Zoomer';
import { Page32_BuildingOnResults } from './Page32_BuildingOnResults/Page32_BuildingOnResults';
import { Page01_Cover } from './Page01_Cover/Page01_Cover';
import { Assigned } from './types';
import { Fragment } from 'react';
import { useMediaQuery } from '@mui/material';

type Props = {
  data: Assigned;
  scoreType: string;
  useZoomer?: boolean;
  clientCandidateAssissmentMappingId?: number;
};

export function AccelerateReport({
  data,
  useZoomer,
  clientCandidateAssissmentMappingId,
}: Props) {
  const fullName = `${data.user.first_name} ${data.user.last_name}`;
  const assessmentDate = new Date(data.user.completed_dt);
  const isPrinting = useMediaQuery('print');

  const Wrapper = useZoomer && !isPrinting ? Zoomer : Fragment;

  return (
    <>
      <div id={'downloadReport'}></div>
      <Wrapper
        isLocked={true}
        isLockReportEnabled={false}
        clientCandidateAssissmentMappingId={clientCandidateAssissmentMappingId}
        download={() =>
          console.log(`download ${fullName} Accelerate Report.pdf`)
        }
        downloadProgress={0}
        targetId="dvmt2"
        initialZoom={1}
      >
        <div id="dvmt2" className="reportContainer">
          {/* page 1 Cover */}
          <div
            id={'page1'}
            className="pdfSections box-shadow-no-export no-padding"
          >
            <Page01_Cover
              prepared_for={fullName}
              assessment_date={assessmentDate}
            />
          </div>
          {/* page 2 About Accelerate */}
          <div
            id={'page2'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page02_About />
            </div>
            <Footer name={fullName} pageNum={2} />
          </div>
          {/* page 3 About Accelerate continued */}
          <div
            id={'page3'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page03_About_Cont />
            </div>
            <Footer name={fullName} pageNum={3} />
          </div>
          {/* page 4 Table of Contents */}
          <div
            id={'page4'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page04_TableOfContents />
            </div>
            <Footer name={fullName} pageNum={4} />
          </div>
          {/* page 5 Modern Lawyer Competency Framework */}
          <div
            id={'page5'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page05_ModernLawyerCompetencyFramework />
            </div>
            <Footer name={fullName} pageNum={5} />
          </div>
          {/* page 6 Dimension Behaviors */}
          <div
            id={'page6'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page06_DimensionBehaviors />
            </div>
            <Footer name={fullName} pageNum={6} />
          </div>
          {/* page 7 Dimension Behaviors */}
          <div
            id={'page7'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page07_DimensionOverview {...data} />
            </div>
            <Footer name={fullName} pageNum={7} />
          </div>
          {/* page 8 Competency Overview */}
          <div
            id={'page8'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page08_CompetencyOverview {...data} />
            </div>
            <Footer name={fullName} pageNum={8} />
          </div>
          {/* page 9 Key Competencies */}
          <div
            id={'page9'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page09_KeyCompetencies {...data} />
            </div>
            <Footer name={fullName} pageNum={9} />
          </div>
          {/* page 10-xx Indicators */}
          <Page10_Indicators data={data} startingPage={10} />
          {/* page 32 Building On Results */}
          <div
            id={'page32'}
            className="pdfSections box-shadow-no-export dvmt2-padding dvmt2-margin html2pdf__page-break"
          >
            <Header />
            <div className="section">
              <Page32_BuildingOnResults />
            </div>
            <Footer name={fullName} pageNum={32} />
          </div>
        </div>
      </Wrapper>
    </>
  );
}

type PageWrapperProps = {
  children: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  name: string;
  pageNum: number;
};

function PageWrapper({
  children,
  hideHeader,
  hideFooter,
  name,
  pageNum,
}: PageWrapperProps) {
  return (
    <div className="pdfSections box-shadow-no-export cover">
      {hideHeader ? null : <Header />}
      {children}
      {hideFooter ? null : <Footer name={name} pageNum={pageNum} />}
    </div>
  );
}
