import Zoom from '@mui/material/Zoom';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import ArrowBack from '@mui/icons-material/ArrowBack';
import FileDownload from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import { updateClientCandidateAssissmentMapping } from '../services/candidate_mapping';

const BackButton = props => {
  let history = useHistory();
  return <ArrowBack onClick={() => history.goBack()} />;
};

const MAX_ZOOM = 1.3;
const MIN_ZOOM = 0.4;

const Zoomer = ({
  children,
  download,
  downloadProgress,
  isLocked,
  clientCandidateAssissmentMappingId,
  isLockReportEnabled,
  targetId,
  initialZoom = 0.5,
  progressVariant = 'determinate',
}) => {
  const [zoomScale, setZoomScale] = useState(initialZoom || 0.5);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isReportLockLoading, setIsReportLockLoading] = useState(false);
  const [isReportLocked, setIsReportLocked] = useState(isLocked);

  // we get value for isLocked from the API, so need to make this change
  useEffect(() => {
    setIsReportLocked(isLocked);
  }, [isLocked]);

  const handleZoomIn = () => {
    setZoomScale(zoomScale + 0.1);
  };

  const handleZoomOut = () => {
    setZoomScale(zoomScale - 0.1);
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    await download();
    setIsDownloading(false);
  };

  const toggleReportLock = async () => {
    setIsReportLockLoading(true);
    await updateClientCandidateAssissmentMapping(
      clientCandidateAssissmentMappingId,
      {
        isReportLocked: !isReportLocked,
      }
    );

    setIsReportLocked(!isReportLocked);
    setIsReportLockLoading(false);
  };

  useEffect(() => {
    const container = document.getElementById('zoomContainer');
    const height = document.getElementById(targetId).clientHeight * zoomScale;
    container.style.height = `${height + 50}px`;
  });

  return (
    <BackdropBox
      progressVariant={progressVariant}
      isDimmed={isDownloading}
      downloadProgress={downloadProgress}
    >
      <Box
        id="zoomContainer"
        sx={{ background: '#80808025', borderRadius: '8px' }}
      >
        <Box
          sx={{ p: 1, position: 'fixed', bottom: 50, left: 50, zIndex: 999 }}
        >
          <Fab
            sx={{ mr: 1 }}
            disabled={zoomScale >= MAX_ZOOM}
            onClick={handleZoomIn}
            size="small"
            color="grey"
            aria-label="add"
          >
            <BackButton />
          </Fab>
        </Box>
        <Box
          sx={{ p: 1, position: 'fixed', bottom: 50, right: 50, zIndex: 999 }}
        >
          {isLockReportEnabled && (
            <Fab
              sx={{ mr: 1 }}
              disabled={zoomScale <= MIN_ZOOM}
              onClick={toggleReportLock}
              size="small"
              color="grey"
              aria-label="add"
            >
              {isReportLockLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {isReportLocked ? (
                    <Tooltip title="Click to unlock the report for candidate">
                      <LockPersonRoundedIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Click to lock the report for candidate">
                      <LockOpenRoundedIcon />
                    </Tooltip>
                  )}
                </>
              )}
            </Fab>
          )}
          <Fab
            sx={{ mr: 1 }}
            disabled={zoomScale >= MAX_ZOOM}
            onClick={handleZoomIn}
            size="small"
            color="grey"
            aria-label="add"
          >
            <ZoomIn />
          </Fab>
          <Fab
            sx={{ mr: 1 }}
            disabled={zoomScale <= MIN_ZOOM}
            onClick={handleZoomOut}
            size="small"
            color="grey"
            aria-label="add"
          >
            <ZoomOut />
          </Fab>
          <Fab
            sx={{ mr: 1 }}
            disabled={zoomScale <= MIN_ZOOM}
            onClick={handleDownload}
            size="small"
            color="grey"
            aria-label="add"
          >
            <FileDownload />
          </Fab>
        </Box>
        <Zoom in={true}>
          <Box
            sx={{
              transform: isDownloading
                ? 'none'
                : `scale(${zoomScale}) !important`,
              transformOrigin: 'top center',
              paddingTop: '20px',
            }}
          >
            {children}
          </Box>
        </Zoom>
      </Box>
    </BackdropBox>
  );
};

const BackdropBox = ({
  isDimmed,
  downloadProgress,
  children,
  progressVariant,
}) => {
  const style = {
    zIndex: 1,
    width: '100%',
    height: '100vh',
    paddingTop: '5%',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <>
      <Backdrop open={isDimmed} sx={style} transitionDuration={200}>
        <CircularProgress variant={progressVariant} value={downloadProgress} />
        <div>Downloading Report ...</div>
      </Backdrop>
      {children}
    </>
  );
};

export default Zoomer;
