import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';

import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  TextField,
  Select,
  Grid,
  InputLabel,
} from '@mui/material';

import BasicSnackbar from '../../../Components/Utilities/BasicSnackbar';
import ClientJobSearchManage from './ClientJobSearchManage';
import SortableTable from '../../../Components/SortableTable';
import { getScoringConstants } from '../../../services/scoring-constants';
import { getAssessments } from '../../../services/assessments';

const ITEM_HEIGHT = 48;

class ClientManage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 0,
      jobsData: [],
      searchData: {},
      showAlert: '',
      alertMessage: '',
      selectedJobId: 0,
      scoringConstantsMap: {},
      assessmentsMap: {},
    };
  }

  async componentDidMount() {
    try {
      this.getJobs();
      const scoringConstantsResponse = await getScoringConstants({});

      const assessmentResponse = await getAssessments({
        assessmentType: 1,
      });

      this.setState({
        scoringConstantsMap: _.keyBy(scoringConstantsResponse.rows, 'constId'),
        assessmentsMap: _.keyBy(assessmentResponse, 'assessmentId'),
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  getJobs = async () => {
    const response = await axios({
      method: 'get',
      url: '/api/jobs/' + this.props.location.state.clientId,
      responseType: 'json',
    });

    const { response: jobsData } = response.data;
    this.setState({
      jobsData: jobsData,
      stage: 1,
    });
  };

  handleCreateJob = () => {
    this.setState({ ...this.state, stage: 2, selectedJobId: 0 });
  };

  handleEditJob = id => {
    this.setState({ stage: 2, selectedJobId: id });
  };

  handleToggleActive = async jobId => {
    let jobsData = [...this.state.jobsData];
    let idx = jobsData.findIndex(el => el.id == jobId);
    let change = jobsData[idx].status == 'active' ? 'inactive' : 'active';
    try {
      const response = await axios({
        method: 'put',
        url: '/api/jobs/' + jobId,
        data: { status: change },
        responseType: 'json',
      });

      jobsData[idx].status = change;
      this.setState({ jobsData });
    } catch (err) {
      console.log(err);
    }
  };

  handleCreateUser = () => {
    this.setState({ stage: 5 });
  };

  backMain = () => {
    this.setState({
      stage: 1,
    });
  };

  handleFieldChange = e => {
    const { value, name } = e.target;
    let searchData = this.state.searchData;
    searchData[name] = value;
    this.setState({ searchData });
  };

  toggleAlert = ({ alertMessage, alertSeverity }) => {
    this.setState({ alertMessage, alertSeverity });
  };

  render() {
    const { scoringConstantsMap, assessmentsMap, jobsData } = this.state;
    const tableData = jobsData;
    const getColumns = () => {
      const columns = [
        {
          id: 'search',
          numeric: false,
          disablePadding: false,
          label: 'Job Search',
          width: 120,
          isVisible: () => true,
          getValue: row => row.title,
          render: row => row.title,
        },
        {
          id: 'pool',
          numeric: false,
          disablePadding: false,
          label: 'Assessment Pool',
          width: 120,
          isVisible: () => true,
          getValue: row => row.pool,
          render: row => {
            switch (row.pool.assessmentType) {
              case 1:
                return 'Entry-Level';
              case 2:
                return 'Lateral (Junior)';
              case 3:
                return 'Lateral (Mid-Level)';
              case 4:
                return 'Lateral (Senior)';
              case 5:
                return 'Skills (M&A)';
              case 6:
                return 'Skills (Finance)';
              case 7:
                return 'Skills (Capital Markets)';
              case 8:
                return 'Skills (Litigation)';
              case 9:
                return 'Skills (Funds)';
              default:
                return '';
            }
          },
        },
        {
          id: 'isServerRenderedReport',
          numeric: false,
          disablePadding: false,
          label: 'Server Rendered Report',
          width: 120,
          isVisible: () => true,
          getValue: row => row.isServerRenderedReport,
          render: row => `${_.startCase(row.isServerRenderedReport)}`,
        },
        {
          id: 'isScoringConstantAttached',
          numeric: false,
          disablePadding: false,
          label: 'Attached Norm',
          // width: 120,
          isVisible: () => true,
          getValue: row => row.scoringConstantId,
          render: row => {
            const scoringConstant = scoringConstantsMap[row.scoringConstantId];
            if (!scoringConstant) return '';

            const { version, notes } = scoringConstant;
            return (
              <div>
                <div>{`Version ${version}`}</div>
                {notes && <small>{notes}</small>}
              </div>
            );
          },
        },
        {
          id: 'assessment',
          numeric: false,
          disablePadding: false,
          label: 'Assessment Version',
          // width: 120,
          isVisible: () => true,
          getValue: row => row.assessmentId,
          render: row => {
            const assessmentVersion = _.get(
              assessmentsMap,
              [row.assessmentId, 'version'],
              ''
            );
            return assessmentVersion ? `Version ${assessmentVersion}` : '';
          },
        },
        {
          id: 'status',
          numeric: false,
          disablePadding: false,
          label: 'Status',
          width: 120,
          isVisible: () => true,
          getValue: row => row.status,
          render: row => _.startCase(row.status),
        },
        {
          id: 'edit',
          numeric: false,
          disablePadding: false,
          label: 'Job Search',
          width: 80,
          isVisible: () => true,
          getValue: row => row.edit,
          render: row => (
            <SearchTableMenu
              toggleActive={() => {
                this.handleToggleActive(row.id);
              }}
              handleEdit={() => {
                this.handleEditJob(row.id);
              }}
              row={row}
            />
          ),
        },
      ];

      return columns.filter(cell => cell.isVisible());
    };

    return (
      <div>
        {this.state.stage === 1 ? (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ mb: 1 }}
            >
              <Typography variant="h4">
                Jobs: {this.props.location.state.clientName}
              </Typography>
              <Button
                variant="contained"
                sx={{ marginRight: 0 }}
                onClick={this.handleCreateJob}
              >
                <AddIcon></AddIcon> Create new Entry-Level Search
              </Button>
            </Grid>
            <SortableTable
              columns={getColumns()}
              rows={tableData}
              title={''}
              defaultOrderBy={'search'}
              setSelected={() => {}}
            ></SortableTable>

            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ mb: 1 }}
            >
              <Typography variant="h4">Firm Users: </Typography>
              <Button
                variant="contained"
                sx={{ marginRight: 0 }}
                onClick={this.handleCreateUser}
              >
                <AddIcon></AddIcon> Add User
              </Button>
            </Grid>

            <FirmUsersTable
              clientId={this.props.location.state.clientId}
              clientName={this.props.location.state.clientName}
            />
          </>
        ) : null}
        {this.state.stage === 2 ? (
          <ClientJobSearchManage
            handleChange={this.handleFieldChange}
            jobsData={jobsData}
            backMain={this.backMain}
            jobId={this.state.selectedJobId}
            clientName={this.props.location.state.clientName}
            clientId={this.props.location.state.clientId}
            toggleAlert={this.toggleAlert}
          ></ClientJobSearchManage>
        ) : null}
        {this.state.stage === 5 ? (
          <AddUserForm
            toggleAlert={this.toggleAlert}
            backMain={this.backMain}
            clientId={this.props.location.state.clientId}
            clientName={this.props.location.state.clientName}
          />
        ) : null}
        {this.state.alertMessage != ''}
        <BasicSnackbar
          message={this.state.alertMessage}
          snackbarOpen={this.state.alertSeverity}
          setSnackbarOpen={() =>
            this.setState({ alertMessage: '', alertSeverity: '' })
          }
          severity={this.state.alertSeverity}
        />
      </div>
    );
  }
}

const SearchTableMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  //const { poolType } = props.pool;
  const parentId = _.get(props, 'parentId', null);
  const { assessmentType } = props.row.pool;
  const showEdit = parentId == null && assessmentType == 1 ? true : false;

  return (
    <div>
      <IconButton onClick={handleClick} id="search-menu-button">
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="search-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: '29ch' } }}
      >
        {showEdit && (
          <MenuItem
            selected={false}
            onClick={() => {
              handleClose();
              props.handleEdit();
            }}
          >
            <Typography variant="inherit" noWrap>
              Edit Job
            </Typography>
          </MenuItem>
        )}

        {showEdit && (
          <MenuItem
            selected={false}
            onClick={() => {
              handleClose();
            }}
          >
            <Link to={`/admin/job/${props.row.id}/overview`}>
              <Typography variant="inherit" noWrap>
                See Overview
              </Typography>
            </Link>
          </MenuItem>
        )}

        <MenuItem
          selected={false}
          onClick={() => {
            props.toggleActive();
            handleClose();
          }}
        >
          <Typography variant="inherit" noWrap>
            Set {props.row.status == 'active' ? 'Inactive' : 'Active'}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

class FirmUsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
    };
  }

  componentDidMount() {
    axios({
      method: 'get',
      url: '/api/admin/users/firm/' + this.props.clientId,
      responseType: 'json',
    })
      .then(response => {
        this.setState({ tableData: response.data });
      })
      .catch(err => console.log(err));
  }

  handleToggleUser = async ({ id, status }) => {
    try {
      const current = _.toLower(status);
      const change = 'active' ? 'inactive' : 'active';

      await axios({
        method: 'put',
        url: `/api/admin/users/firm/${id}`,
        responseType: 'json',
        data: { status: change },
      });

      const tableData = [...this.state.tableData];
      const idx = tableData.findIndex(el => el.user_id === id);
      tableData[idx].status = change;
      this.setState({ tableData });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { tableData } = this.state;
    const getColumns = () => {
      const columns = [
        // {
        //   id: "userId",
        //   numeric: false,
        //   disablePadding: false,
        //   label: "User ID",
        //   isVisible: () => true,
        //   getValue: (row) => row.user_id,
        //   render: (row) => row.user_id,
        // },
        {
          id: 'emailAddr',
          numeric: false,
          disablePadding: false,
          label: 'Email Address',
          isVisible: () => true,
          getValue: row => row.email_addr,
          render: row => row.email_addr,
        },
        {
          id: 'userName',
          numeric: false,
          disablePadding: false,
          label: 'Name',
          isVisible: () => true,
          getValue: row => `${row.first_name} ${row.last_name}`,
          render: row => `${row.first_name} ${row.last_name}`,
        },
        {
          id: 'createdAt',
          numeric: false,
          disablePadding: false,
          label: 'Created At',
          isVisible: () => true,
          getValue: row => row.created_at,
          render: row => moment(row.created_at).format('MM/DD/YYYY'),
        },
        {
          id: 'status',
          numeric: false,
          disablePadding: false,
          label: 'Status',
          isVisible: () => true,
          getValue: row => row.status,
          render: row => _.capitalize(row.status),
        },

        {
          id: 'menu',
          numeric: false,
          disablePadding: false,
          label: '',
          width: 80,
          isVisible: () => true,
          //getValue: (row) => row.createdAt,
          render: row => (
            <UserTableMenu
              statusValue={row.status}
              toggleUser={() => {
                this.handleToggleUser({ id: row.user_id, status: row.status });
              }}
            ></UserTableMenu>
          ),
        },
      ];

      return columns.filter(cell => cell.isVisible());
    };

    return (
      <>
        <SortableTable
          columns={getColumns()}
          rows={tableData}
          defaultOrderBy="userName"
          setSelected={() => {}}
        />
      </>
    );
  }
}

const UserTableMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  let statusChangeLabel =
    props.statusValue == 'active' ? 'Set Inactive' : 'Set Active';

  return (
    <div>
      <IconButton onClick={handleClick} id="user-menu-button">
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="user-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: '29ch' } }}
      >
        <MenuItem
          selected={false}
          onClick={() => {
            props.toggleUser();
            handleClose();
          }}
        >
          <Typography variant="inherit" noWrap>
            {statusChangeLabel}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

class AddUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddr: null,
      firstName: '',
      lastName: '',
    };
  }

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ ...this.state, [name]: value });
  };

  handleSubmit = async () => {
    // create user

    try {
      const response = await axios({
        method: 'post',
        url: '/api/admin/users/firm',
        data: {
          emailAddr: this.state.emailAddr,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          clientId: this.props.clientId,
        },
        responseType: 'json',
      });
      this.props.toggleAlert({
        alertMessage: 'User created successfully.',
        alertSeverity: 'success',
      });
      this.props.backMain();
    } catch (err) {
      console.log(err);
      this.showAlert({
        alertMessage: 'There was a problem creating the user.',
        alertSeverity: 'error',
      });
    }
  };

  render() {
    return (
      <div>
        <Typography variant="h4">
          Add Firm User: {this.props.clientName}
        </Typography>
        <Box sx={{ width: 'fit-content', minWidth: '600px', paddingLeft: 1 }}>
          <Grid
            container
            columnSpacing={1}
            sx={{ mb: 1 }}
            alignItems="flex-start"
            direction="column"
          >
            <Grid item xs={12}>
              <InputLabel for="emailAddr" sx={{ fontWeight: 'bold' }}>
                Email Address:{' '}
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="emailAddr"
                onChange={this.handleInputChange}
                sx={{
                  display: 'block',
                  mb: '20px',
                  width: '400px',
                  input: {
                    '&::placeholder': {
                      color: 'black',
                    },
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid
            container
            columnSpacing={1}
            sx={{ mb: 1 }}
            alignItems="flex-start"
            direction="column"
          >
            <Grid item xs={12}>
              <InputLabel for="firstName" sx={{ fontWeight: 'bold' }}>
                First Name
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="firstName"
                onChange={this.handleInputChange}
                sx={{
                  display: 'block',
                  mb: '20px',
                  width: '400px',
                  input: {
                    '&::placeholder': {
                      color: 'black',
                    },
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid
            container
            columnSpacing={1}
            sx={{ mb: 1 }}
            alignItems="flex-start"
            direction="column"
          >
            <Grid item xs={12}>
              <InputLabel for="lastName" sx={{ fontWeight: 'bold' }}>
                Last Name:{' '}
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="lastName"
                onChange={this.handleInputChange}
                sx={{
                  display: 'block',
                  mb: '20px',
                  width: '400px',
                  input: {
                    '&::placeholder': {
                      color: 'black',
                    },
                  },
                }}
              ></TextField>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ mb: 1, mt: 1, width: '300px' }}
            alignItems="flex-start"
            direction="row"
          >
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{
                  mr: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                onClick={this.props.backMain}
              >
                <ArrowBackIcon /> Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                onClick={this.handleSubmit}
              >
                <EditIcon /> Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}

export default ClientManage;
