import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Tooltip, Typography } from '@mui/material';

export const DebugMain = () => {
  const history = useHistory();
  const addQuestionsMutation = useMutation(body => {
    console.log('fetching with body', body);
    return fetch('/api/admin/debug/assessment/addQuestions', {
      // URL to the API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then(response => response.json());
  });

  const markAssignmentIncompleteMutation = useMutation({
    mutationFn: (assignment_id: string) => {
      return fetch(`/api/admin/debug/assignment/${assignment_id}/incomplete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => response.json());
    },
  });

  const rescoreAssessmentByAssignmentIdMutation = useMutation({
    mutationFn: (assignment_id: string) => {
      return fetch(`/api/admin/debug/assignment/${assignment_id}/rescore`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => response.json());
    },
  });

  const createNewVersionMutation = useMutation(assessment_id => {
    return fetch(`/api/admin/debug/assessment/${assessment_id}/new-version`, {
      // URL to the API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.json());
  });

  function toAssignment(assignment_id: string) {
    history.push(`/admin/debug/assignment/${assignment_id}`);
  }

  function toClientCandidateMapping(client_candidate_mapping_id: string) {
    history.push(
      `/admin/debug/client_candidate_mapping/${client_candidate_mapping_id}`
    );
  }

  function toAssessment(assessment_id: string) {
    history.push(`/admin/debug/assessment/${assessment_id}`);
  }

  function deleteAssessment(assessment_id: string) {
    alert('Delete assessment ' + assessment_id);
  }

  function addQuestions(contents: string) {
    const json = JSON.parse(contents);
    addQuestionsMutation.mutate(json);
  }

  function becomeUser(email: string) {
    alert('Become user ' + email);
  }

  function recalculateServerRenderedReport(assignment_id: string) {
    console.log('recalculateServerRenderedReport', assignment_id);
    rescoreAssessmentByAssignmentIdMutation.mutate(assignment_id);
  }

  return (
    <div>
      <FormField
        label={'View Assignment ID - assessment taker sees this'}
        placeholder={'assignment_id'}
        onSubmit={toAssignment}
        caption={
          'e.g. 16912 from a URL like https://thine-staging.herokuapp.com/take?id=16912&desc=&title=Thine%20Accelerate&is_general=false'
        }
      />

      <FormField
        label={
          'Regenerate Server-rendered report by assignment ID - assessment taker sees this'
        }
        placeholder={'assignment_id'}
        onSubmit={recalculateServerRenderedReport}
        caption={
          'e.g. 16912 from a URL like https://thine-staging.herokuapp.com/take?id=16912&desc=&title=Thine%20Accelerate&is_general=false'
        }
      />

      <FormField
        label={'Client Candidate Mapping ID - client admin sees this'}
        placeholder={'client_candidate_mapping_id'}
        onSubmit={toClientCandidateMapping}
        caption={
          'e.g. 63748 from a URL like https://thine-staging.herokuapp.com/firm/report/63748'
        }
      />
      <FormField
        label={'Mark Assignment Incomplete'}
        placeholder={'assignment_id'}
        onSubmit={markAssignmentIncompleteMutation.mutate}
        caption={
          'e.g. 16912 from a URL like https://thine-staging.herokuapp.com/take?id=16912&desc=&title=Thine%20Accelerate&is_general=false'
        }
      />
      <FormField
        label={'View Assessment ID'}
        placeholder={'assessment_id'}
        onSubmit={toAssessment}
        caption={'e.g. 120 for Accelerate'}
      />
      {/*<FormField*/}
      {/*  label={'Create new assessment version for assessment_id'}*/}
      {/*  placeholder={'assessment_id'}*/}
      {/*  onSubmit={createNewVersionMutation.mutate}*/}
      {/*/>*/}
      {/*<FormField label={'Become user'} placeholder={'email address'} onSubmit={becomeUser}/>*/}
      {/*<FormField label={'Delete DebugAssessment'} onSubmit={deleteAssessment}/>*/}
      {/*<AddQuestionsField label={'Add Questions'} onSubmit={addQuestions} />*/}
    </div>
  );
};

// @ts-ignore
function AddQuestionsField({ label, onSubmit }) {
  const [value, setValue] = React.useState('');
  return (
    <div style={{ padding: '1rem' }}>
      <form>
        <label style={{ paddingRight: '1rem' }}>{label}</label>
        <textarea onChange={e => setValue(e.target.value)} />
      </form>
      <button
        onClick={() => {
          onSubmit(value);
        }}
      >
        Submit
      </button>
    </div>
  );
}

type FormFieldProps = {
  label: string;
  onSubmit: (value: string) => void;
  placeholder: string;
  caption?: string;
};

function FormField({
  label,
  onSubmit,
  placeholder = 'ID',
  caption,
}: FormFieldProps) {
  const [value, setValue] = React.useState('');
  return (
    <div style={{ padding: '1rem' }}>
      <form
        onSubmit={e => {
          e.stopPropagation();
          e.preventDefault();
          console.log('submit', JSON.stringify({ value }));
          onSubmit(value);
        }}
      >
        <label style={{ paddingRight: '1rem' }}>{label}</label>
        <input
          type={'text'}
          placeholder={placeholder}
          onChange={e => setValue(e.target.value)}
          value={value}
        />
        <div>
          <Typography variant="caption" gutterBottom sx={{ color: 'red' }}>
            {caption}
          </Typography>
        </div>
      </form>
    </div>
  );
}

type CSV = {
  question: string;
  answers: { answer: string; indicator: string }[];
};

/**
 * probably won't keep this but it's good for turning this kind of spreadsheet into JSON we can upload as questions
 * https://voltapeople.sharepoint.com/:x:/r/_layouts/15/Doc.aspx?sourcedoc=%7B556922DD-568B-4BFA-8CCB-1FCEAA36E62B%7D&file=2023%20Lateral%20SJTs%20-%20Final%20-%2012%205%202023.xlsx&fromShare=true&action=default&mobileredirect=true
 *
 * @param csv
 */
function csvAssessmentToJson(csv: string) {
  const rows = csv.split('\n'); // Split the input into rows
  const results: CSV[] = [];
  let currentQuestion: CSV;

  rows.forEach((row, index) => {
    if (index === 0) return; // Skip the header row

    const fields = [];
    let field = '';
    let inQuotes = false;

    for (let i = 0; i < row.length; i++) {
      const char = row[i];
      if (char === '"') {
        if (inQuotes && row[i + 1] === '"') {
          field += '"';
          i++;
        } else {
          inQuotes = !inQuotes;
        }
      } else if (char === ',' && !inQuotes) {
        fields.push(field);
        field = '';
      } else {
        field += char;
      }
    }
    fields.push(field); // Add the last field after the loop

    if (fields[0]) {
      // This row is a new question
      currentQuestion = { question: fields[0], answers: [] };
      results.push(currentQuestion);
    }

    // Add answers to the current question
    if (fields[1] && currentQuestion) {
      currentQuestion.answers.push({
        answer: fields[1],
        indicator: fields[2] || '', // Handle missing indicators safely
      });
    }
  });

  return results;
}
