import FooterIcon from './FooterIcon.svg';

type Props = {
  name: string;
  pageNum: number;
};

export const Footer = (props: Props) => {
  return (
    <footer className="footer">
      <span className="footer-text">
        <span>Accelerate</span>
        <span className="footer-text-bar"> | </span>
        <span>{props.name}</span>
      </span>
      <span className="pageNum">
        Page {props.pageNum}
        <img src={FooterIcon} alt="Footer icon" />
      </span>
    </footer>
  );
};
