import { ReputationManagement } from '../icons/ReputationManagement';
import { SelfManagement } from '../icons/SelfManagement';
import { RelationshipsAndTeamwork } from '../icons/RelationshipsAndTeamwork';
import { BusinessOrientation } from '../icons/BusinessOrientation';
import { Execution } from '../icons/Execution';
import { PursuitOfExcellence } from '../icons/PursuitOfExcellence';
import { DynamicApproach } from '../icons/DynamicApproach';

type SectionHeaderProps = {
  index: number;
  x: number;
  y: number;
  width: number;
  height: number;
  payload: {
    value: string;
  };
};

export function SectionHeader(props: SectionHeaderProps) {
  if (props.payload.value.startsWith('EMPTY:')) {
    return <></>;
  }
  if (!props.payload.value.startsWith('HEADER:')) {
    return (
      <text
        {...props}
        x={props.x - 4}
        y={props.y + 2}
        fill={'#4D4C4C'}
        fontFamily={'Roboto'}
        fontWeight={400}
        fontSize={'10px'}
      >
        {props.payload.value}
      </text>
    );
  }

  let Icon;

  const header = props.payload.value.replace('HEADER:', '');
  switch (header) {
    case 'Reputation Management':
      Icon = ReputationManagement;
      break;
    case 'Self-Management':
      Icon = SelfManagement;
      break;
    case 'Relationships + Teamwork':
      Icon = RelationshipsAndTeamwork;
      break;
    case 'Business Orientation':
      Icon = BusinessOrientation;
      break;
    case 'Execution':
      Icon = Execution;
      break;
    case 'Pursuit of Excellence':
      Icon = PursuitOfExcellence;
      break;
    case 'Dynamic Approach':
      Icon = DynamicApproach;
      break;
    default:
      Icon = ReputationManagement;
  }
  return (
    <g transform="translate(0,28)">
      <text
        x={40}
        y={props.y - 33}
        fontFamily={'Catamaran'}
        fontWeight={600}
        fontSize={'12px'}
        fill={'#3D3D3D'}
      >
        {header}
      </text>
      {Icon({ width: 32, height: 32, x: 0, y: props.y - 37 })}
      <rect x={32} y={props.y - 25} width={458} height={3} fill={'#BFD5D9'} />
    </g>
  );
}
