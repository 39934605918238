import logo from './icons/logo.svg';

export const Header = () => {
  return (
    <header className="header ">
      <img
        className="logo"
        src={logo}
        height={26}
        width={100}
        alt="VOLTA +THINE logo"
      />
    </header>
  );
};
