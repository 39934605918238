import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ElevatedPaper from '../../../Components/ElevatedPaper';
import SurveyCheckBox from './SurveyCheckbox';

const SurveyCompetencies = ({
  surveyData,
  checkedCount,
  setSurveyForm,
  surveyForm,
}) => (
  <Box sx={{ m: '5px' }}>
    <ElevatedPaper>
      {surveyData.map(survey => (
        <Box key={survey.indicator_id}>
          {survey.children.map(child => (
            <Box key={child.indicator_id}>
              <SurveyCheckBox
                checked={surveyForm[child.indicator_id] || false}
                onChange={e => {
                  if (e.target.checked && checkedCount.current >= 8) {
                    return;
                  }
                  setSurveyForm(sf => ({
                    ...sf,
                    [child.indicator_id]: e.target.checked,
                  }));

                  checkedCount.current += e.target.checked ? 1 : -1;
                }}
                meta={child && child.meta ? child.meta : {}}
                label={child.indicator_name}
                subtitle={child.indicator_description}
                icon={child.meta.fa_class}
                sx={{ mb: 2 }}
              />
              <Divider sx={{ mt: 2, mb: 4 }} />
            </Box>
          ))}
        </Box>
      ))}
    </ElevatedPaper>
  </Box>
);

export default SurveyCompetencies;
