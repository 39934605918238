import { useMemo, useState } from 'react';
import useEvent from '../../helper/useEvent';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation } from 'react-query';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { calculateAnswers } from './calculateAnswers';
import { TakeResult } from './types';
import { submitAnswers } from './submitAnswers';
import { parseCSV } from './parseCsv';

export type UserAnswerPicker = 'all ones' | 'all fives' | 'random' | 'file';

type Props = {
  take_result: TakeResult;
  assignment_id: number;
  on_start: () => void;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function TakeAssessmentForMe({
  take_result,
  assignment_id,
  on_start,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const [userAnswerPicker, setUserAnswerPicker] =
    useState<UserAnswerPicker>('random');

  const [file, setFile] = useState<File | null>(null);

  const isEnabled = useMemo(() => {
    return (
      localStorage.getItem('thine_take_for_me_enabled')?.toLowerCase() ===
      'true'
    );
  }, []);

  const submitAnswersMutation = useMutation({
    mutationFn: submitAnswers,
    onSuccess: () => {
      setIsOpen(false);
      on_start();
    },
  });

  /**
   * pop open modal when Ctl-T is pressed
   */
  useEvent(window, 'keydown', e => {
    if (isEnabled && e.key === 't' && e.ctrlKey) {
      setIsOpen(!isOpen);
      console.log('ctl-T');
    }
  });

  const handleClickGo = async () => {
    const fileText = await file?.text();
    const csv = fileText ? parseCSV(fileText) : [];
    const fileAnswers: Record<number, string> = {};
    csv.forEach((row: any) => {
      const item_id = parseInt(row['item_id']);
      const answer_title = row['answer_title'];
      fileAnswers[item_id] = answer_title;
    });

    console.log('CSV file', { csv, fileAnswers });
    const answers = calculateAnswers(
      assignment_id,
      take_result,
      userAnswerPicker,
      fileAnswers
    );
    await submitAnswersMutation.mutate(answers);
  };

  async function handleFile(files: FileList | null) {
    const file = files?.[0];
    if (!file) {
      return;
    }
    setFile(file);
    setUserAnswerPicker('file');
  }

  return isEnabled ? (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title">
          Take this assessment for me
        </Typography>
        <Typography
          id="modal-modal-description"
          variant="h6"
          sx={{ mt: 2, mb: 2 }}
        >
          {take_result.assessmentTitle} / {take_result.assessmentId}
        </Typography>
        <FormControl fullWidth>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Make my answers
            </InputLabel>
            <Select
              labelId="user-answers-select-label"
              id="user-answers-select"
              value={userAnswerPicker}
              label="User Answers"
              onChange={c => {
                setUserAnswerPicker(c.target.value as UserAnswerPicker);
                if (c.target.value !== 'file') {
                  setFile(null);
                }
              }}
            >
              <MenuItem value={'all ones'}>All Ones</MenuItem>
              <MenuItem value={'all fives'}>All Fives</MenuItem>
              <MenuItem value={'random'}>Random</MenuItem>
              <MenuItem value={'file'}>CSV File</MenuItem>
            </Select>
            OR
            <Tooltip
              title="CSV requires header row, and columns item_id answer_title"
              arrow
              placement={'top'}
            >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Upload CSV
                <VisuallyHiddenInput
                  type="file"
                  onChange={(event: any) => handleFile(event.target.files)}
                  accept=".csv"
                />
              </Button>
            </Tooltip>
          </Stack>

          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={handleClickGo}
            disabled={
              submitAnswersMutation.isLoading ||
              (userAnswerPicker === 'file' && !file)
            }
          >
            {submitAnswersMutation.isLoading
              ? 'Submitting...'
              : `GO ${file?.name ? file.name : ''}`}
          </Button>
        </FormControl>
      </Box>
    </Modal>
  ) : null;
}
