import React from 'react';

const DimensionsKey = ({ dimensions }) => {
  const items = dimensions.map((item, i) => {
    return (
      <div className="item" key={i + 1}>
        <div className={'bullet bg-' + item.color}></div>
        <div className="label">{item.name}</div>
      </div>
    );
  });

  return <div className="dimension-chart-key">{items}</div>;
};

export default DimensionsKey;
