export function BusinessOrientation({ width = 32, height = 32, x = 0, y = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="15.5" fill="#F3F6F9" stroke="#BFD5D9" />
      <path
        d="M15.6917 16.3229C15.689 16.3204 15.6855 16.3196 15.683 16.3171L14.261 14.8951C13.5331 14.1672 12.5458 13.7582 11.5163 13.7582C11.3972 13.7582 11.2833 13.711 11.1993 13.6269L8.47917 10.9367C8.35135 10.8088 8.31238 10.6157 8.38199 10.448C8.45116 10.2803 8.61494 10.1713 8.7962 10.1713C9.53908 10.1713 10.1413 9.56904 10.1413 8.82616L10.1413 8.79627C10.1413 8.61503 10.2503 8.45126 10.418 8.38209C10.5857 8.31247 10.7784 8.351 10.9067 8.4793L13.6268 11.1994C13.7109 11.2835 13.7581 11.3973 13.7581 11.5164C13.7581 12.5459 14.1671 13.5332 14.8951 14.2612L16.317 15.6831C16.3195 15.6856 16.3203 15.6891 16.3228 15.6918C16.4894 15.8663 16.4916 16.1315 16.3228 16.3084C16.145 16.4947 15.8673 16.4905 15.6917 16.3229Z"
        fill="#00629B"
      />
      <path
        d="M8.34784 16C8.34784 15.3296 8.44751 14.6805 8.62135 14.0618C8.7999 13.4264 9.58907 13.2846 10.0558 13.7513C10.3103 14.0059 10.3928 14.381 10.3134 14.7322C10.2211 15.1403 10.1712 15.5643 10.1712 16C10.1712 19.2139 12.7861 21.8288 16 21.8288C19.2139 21.8288 21.8288 19.2139 21.8288 16C21.8288 12.7861 19.2139 10.1712 16 10.1712C15.5642 10.1712 15.1403 10.2211 14.7321 10.3134C14.381 10.3928 14.0059 10.3103 13.7513 10.0558C13.2846 9.58907 13.4265 8.79989 14.0619 8.62135C14.6805 8.44752 15.3296 8.34784 16 8.34784C20.203 8.34784 23.6522 11.797 23.6522 16C23.6522 20.203 20.203 23.6522 16 23.6522C11.797 23.6522 8.34784 20.203 8.34784 16Z"
        fill="#6690B6"
      />
      <path
        d="M11.9647 16C11.9647 15.6454 12.019 15.3051 12.1096 14.9792C12.1642 14.7825 12.3488 14.6549 12.5529 14.6549C12.6809 14.6549 12.8037 14.7057 12.8942 14.7962L13.5698 15.4719C13.7081 15.6101 13.7581 15.8045 13.7581 16C13.7581 17.2361 14.7639 18.2418 16 18.2418C17.2361 18.2418 18.2419 17.2361 18.2419 16C18.2419 14.7639 17.2361 13.7581 16 13.7581C15.8045 13.7581 15.6102 13.7081 15.4719 13.5698L14.7962 12.8942C14.7057 12.8037 14.6549 12.6809 14.6549 12.5529C14.6549 12.3488 14.7825 12.1642 14.9792 12.1096C15.3051 12.019 15.6454 11.9647 16 11.9647C18.2252 11.9647 20.0353 13.7748 20.0353 16C20.0353 18.2252 18.2252 20.0353 16 20.0353C13.7748 20.0353 11.9647 18.2252 11.9647 16Z"
        fill="#6690B6"
      />
    </svg>
  );
}
