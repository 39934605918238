import React from 'react';
import { useQuery } from 'react-query';
import { ClientCandidateMapping } from './types';
import { ShowCompletedAssessment } from './ShowCompletedAssessment';

function debugAssignment(assignment_id: string) {
  // console.log("Debug assignment", assignment_id)
  return fetch(`/api/admin/debug/assignment/${assignment_id}`, {
    headers: {
      accepts: 'application/json',
    },
    method: 'get',
  }).then(res => res.json() as Promise<ClientCandidateMapping>);
}

export function DebugAssignment({
  match: {
    params: { assignment_id },
  },
}: any) {
  const query = useQuery({
    queryKey: ['assignment', assignment_id],
    queryFn: () => debugAssignment(assignment_id),
  });

  if (query.isLoading) return <p>Loading...</p>;
  if (!query.data) return <p>Error: {query.error}</p>;

  return <ShowCompletedAssessment data={query.data} />;
}
