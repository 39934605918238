const initialState = null;

const firm = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_FIRM_SESSION':
      return payload.firm;
    default:
      return state;
  }
};

export default firm;
