import { AssessmentQuestion, TakeResult } from './types';
import { AnswerPayload } from './submitAnswers';
import { UserAnswerPicker } from './TakeAssessmentForMe';

export function calculateAnswers(
  assignment_id: number,
  take_result: TakeResult,
  picker: UserAnswerPicker,
  fileAnswers: Record<number, string>
) {
  const questions = take_result.questions.filter(q => q.question_type !== 0);

  return questions.map(q => makeAnswer(q, picker, assignment_id, fileAnswers));
}

/**
 * @param question
 * @param picker
 * @param assignment_id
 * @param file
 */
function makeAnswer(
  question: AssessmentQuestion,
  picker: UserAnswerPicker,
  assignment_id: number,
  fileAnswers: Record<number, string>
): AnswerPayload {
  switch (picker) {
    case 'all ones':
      // return the first answer
      return {
        question_id: question.question_id,
        answer_id: question.answers[0].answer_id,
        question_type: question.question_type,
        assign_id: assignment_id.toString(),
      };
    case 'all fives':
      // return the last answer
      return {
        question_id: question.question_id,
        answer_id: question.answers[question.answers.length - 1].answer_id,
        question_type: question.question_type,
        assign_id: assignment_id.toString(),
      };
    case 'random':
      // return a randomly chosen answer
      return {
        question_id: question.question_id,
        answer_id:
          question.answers[Math.floor(Math.random() * question.answers.length)]
            .answer_id,
        question_type: question.question_type,
        assign_id: assignment_id.toString(),
      };
    case 'file':
      const answer_title = fileAnswers[question.item_id];
      const answer_id = question.answers.find(
        a => a.answer_title === answer_title
      )?.answer_id;
      if (!answer_id) {
        console.error('answer not found for', {
          answer_title,
          question,
        });
        throw new Error(
          'answer not found ' + JSON.stringify({ answer_title, question })
        );
      }
      return {
        question_id: question.question_id,
        answer_id,
        question_type: question.question_type,
        assign_id: assignment_id.toString(),
      };
  }
}
