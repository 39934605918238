export async function getUsers(params) {
  const response = await fetch(
    `/api/portal-users?` + new URLSearchParams(params)
  );
  const data = await response.json();
  return data;
}

export async function put(id, data = {}) {
  const response = await fetch(`/api/portal-users/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  return response;
}
