import React, { useState } from 'react';
import axios from 'axios';
import EditModal from '../../Components/EditModal';
import { Button, TextField, Alert } from '@mui/material';

const SurveyInviteModal = ({ clientId, close }) => {
  const [emails, setEmails] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = () => {
    axios({
      method: 'post',
      url: '/api/survey/invite',
      responseType: 'json',
      data: {
        clientId,
        emails,
      },
    })
      .then(response => {
        setSuccess(true);
        setTimeout(() => {
          close();
        }, 5000);
      })
      .catch(err => {
        setError(true);
      });
  };

  const handleChange = e => {
    const { value } = e.target;
    setCanSubmit(value.length > 9);

    setEmails(value);
  };

  return (
    <EditModal
      style={{ width: '500px', margin: '300px auto' }}
      handleCancel={close}
      title={'Invite to Survey'}
      isBlocking={false}
    >
      {error ? (
        <Alert severity="error" onClose={() => setError(false)}>
          There was an error processing the invitations. Please check if the
          targets have already been invited.
        </Alert>
      ) : null}
      {success ? (
        <Alert severity="success">
          Invites successfully generated and sent.
        </Alert>
      ) : null}
      <TextField
        style={{ width: '500px' }}
        placeholder="Enter comma-separated emails"
        onChange={handleChange}
      ></TextField>
      {canSubmit ? (
        <Button variant="contained" onClick={handleSubmit}>
          Invite
        </Button>
      ) : null}
    </EditModal>
  );
};

export default SurveyInviteModal;
