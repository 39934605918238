import { Assigned } from '../types';
import PlusIcon from '../icons/PlusIcon.svg';

export function Page03_About_Cont() {
  return (
    <div className="container">
      <div className="page-section">
        <div className="text-block">
          <span className="plus-icon">
            <img src={PlusIcon} alt="Plus Icon" />
            <h5>Charting Your Path Forward</h5>
          </span>
          <p style={{ fontSize: '10px' }}>
            Accelerate was developed to enhance your self-awareness and support
            you in achieving your unique vision of career success. Every lawyer
            embraces each competency to different extents and in different ways
            throughout their career. As you go through this report, consider
            your unique strengths and how you can best leverage them, as well as
            the areas providing the greatest potential for growth and what that
            could look like.
          </p>

          <h4 style={{ marginTop: '20px' }}>
            <span className="title-bar"></span>
            Collaborating with Mentors, Coaches, and Other Resources
          </h4>

          <p style={{ fontSize: '10px' }}>
            It may be helpful to talk through your results with someone who
            knows you personally, including a trusted colleague, mentor, coach,
            or someone else focused on your professional development.
          </p>
        </div>
      </div>
    </div>
  );
}
