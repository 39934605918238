import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function CandidateSteps({
  steps = ['Instructions', 'Demographics', 'Assessment', 'Results'],
  step,
}) {
  const isDesktop = window.innerWidth > 960;

  return (
    <Stepper
      sx={{ maxWidth: isDesktop ? '50%' : '96vw', paddingBottom: 2 }}
      activeStep={step}
      alternativeLabel={isDesktop ? false : true}
    >
      {steps &&
        steps.length &&
        steps.map((title, index) => {
          return (
            <Step key={index} completed={index < step}>
              <StepLabel>{title}</StepLabel>
            </Step>
          );
        })}
    </Stepper>
  );
}
