import { Bar, Cell, ComposedChart, LabelList, XAxis, YAxis } from 'recharts';
import React from 'react';

type Data = {
  name: string;
  value: number;
  color: string;
};

type Props = {
  data: Data[];
};

export function DimensionOverviewChart({ data }: Props) {
  return (
    // <ResponsiveContainer width={550} height={220}>
    <ComposedChart
      data={data}
      layout="vertical"
      margin={{ left: 10, right: 0 }}
      barCategoryGap={2}
      width={550}
      height={220}
    >
      <Bar dataKey="value" fill="#413ea0" yAxisId={'left'} barSize={25}>
        <LabelList
          dataKey="name"
          position="insideLeft"
          offset={16}
          fill={'#fff'}
          fontFamily={'Catamaran'}
          fontWeight={400}
          fontStyle={'normal'}
          fontSize={'12px'}
          letterSpacing={'0.12px'}
        />
        <LabelList
          dataKey="value"
          content={props => {
            return (
              <text
                fill={'#3D3D3D'}
                fontFamily={'Catamaran'}
                fontWeight={700}
                fontStyle={'normal'}
                letterSpacing={'0.12px'}
                fontSize={'12px'}
                x={Number(props.x) + Number(props.width) + 10}
                y={Number(props.y) + Number(props.height) - 8}
              >
                {props.value}%
              </text>
            );
          }}
        />
        {data.map((entry, index) => {
          return <Cell key={`cell-${index}`} fill={entry.color} />;
        })}
      </Bar>
      <XAxis
        dataKey="value"
        type={'number'}
        domain={[0, 100]}
        tickCount={2}
        axisLine={false}
        tickLine={false}
        tick={a => <CustomizedAxisTick {...a} />}
      />
      <YAxis
        hide={false}
        yAxisId={'left'}
        axisLine={true}
        tick={false}
        orientation={'left'}
        width={1}
        tickLine={false}
        padding={{ top: -1, bottom: -2 }}
        dataKey="name"
        type={'category'}
        stroke={'#888888'}
        // stroke={'red'}
      ></YAxis>
      <YAxis
        hide={false}
        yAxisId={'right'}
        axisLine={true}
        tick={false}
        orientation={'right'}
        width={40}
        tickLine={false}
        dataKey="name"
        type={'category'}
        stroke={'#888888'}
      />
    </ComposedChart>
    // </ResponsiveContainer>
  );
}

function CustomizedAxisTick(props: {
  x: number;
  y: number;
  stroke: string;
  payload: any;
}) {
  console.log(
    'CustomizedAxisTick',
    props.x,
    props.y,
    props.stroke,
    props.payload
  );
  const { x, y, stroke, payload } = props;

  return (
    <text
      x={x}
      y={y}
      dy={4}
      textAnchor="middle"
      fontStyle={'normal'}
      fontWeight={400}
      fontFamily={'Source Sans 3'}
      fontSize={'10px'}
      fill="#888"
    >
      {payload.value}%
    </text>
  );
}
