export function SelfManagement({ width = 32, height = 32, x = 0, y = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16Z"
        fill="#F3F6F9"
        stroke="#BFD5D9"
      />
      <path
        d="M22.1638 11.8495L23.3134 10.7254L22.1822 9.61892L20.9524 10.8215C19.829 10.0429 18.518 9.56313 17.1478 9.42903V7.82539H18.7478V6.26086H13.9478V7.82539H15.5478V9.42903C14.1777 9.56313 12.8667 10.0429 11.7432 10.8215L10.5134 9.61892L9.38224 10.7254L10.5318 11.8495C9.45876 12.9605 8.74196 14.3545 8.46995 15.8593C8.19794 17.3642 8.38265 18.9139 9.00125 20.3173C9.61986 21.7206 10.6453 22.9161 11.9509 23.756C13.2564 24.5959 14.785 25.0435 16.3478 25.0435C17.9106 25.0435 19.4392 24.5959 20.7448 23.756C22.0504 22.9161 23.0758 21.7206 23.6944 20.3173C24.313 18.9139 24.4977 17.3642 24.2257 15.8593C23.9537 14.3545 23.2369 12.9605 22.1638 11.8495ZM16.3478 23.4706C15.082 23.4706 13.8447 23.1036 12.7922 22.416C11.7397 21.7283 10.9194 20.7509 10.435 19.6074C9.95061 18.4639 9.82387 17.2056 10.0708 15.9916C10.3178 14.7777 10.9273 13.6626 11.8224 12.7874C12.7174 11.9122 13.8578 11.3162 15.0993 11.0747C16.3407 10.8332 17.6276 10.9571 18.797 11.4308C19.9665 11.9045 20.966 12.7066 21.6692 13.7357C22.3725 14.7649 22.7478 15.9748 22.7478 17.2125C22.7459 18.8717 22.071 20.4624 20.8712 21.6356C19.6714 22.8088 18.0446 23.4688 16.3478 23.4706Z"
        fill="#00629B"
      />
      <path
        d="M16.3477 12.2711V17.1545H11.3953C11.3953 18.1204 11.6858 19.0646 12.23 19.8676C12.7741 20.6707 13.5476 21.2967 14.4525 21.6663C15.3574 22.0359 16.3532 22.1326 17.3139 21.9442C18.2745 21.7557 19.157 21.2906 19.8496 20.6077C20.5422 19.9247 21.0138 19.0546 21.2049 18.1073C21.396 17.16 21.2979 16.178 20.9231 15.2857C20.5483 14.3934 19.9135 13.6307 19.0991 13.0941C18.2847 12.5575 17.3272 12.2711 16.3477 12.2711Z"
        fill="#BFD5D9"
      />
    </svg>
  );
}
