import { useEffect, useState } from 'react';
import { Typography, Box, Button, Popover, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import EditModal from '../../../Components/EditModal';
import BasicTextInput from '../../../Components/Inputs/BasicTextInput';
import request from './../../../RequestUtil';

const initialState = {
  indicator_identifier: '',
  indicator_id: '',
  indicator_name: '',
  likert_mean: '',
  likert_sd: '',
  updatedBy: null,
};

const UpdateNormModal = ({
  setIsEditModalOpen,
  norm,
  norms,
  setNorms,
  user,
}) => {
  const [state, setState] = useState(initialState);
  const [submiting, setSubmiting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [normUpdateBy, setNormUpdateBy] = useState(null);
  const [normCreatedBy, setNormCreatedBy] = useState(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setState({ ...norm });
  }, [norm]);

  const handlechange = e => {
    setState(p => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleCancel = () => {
    if (!submiting) handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setSubmiting(true);

      let updated;
      if (norm) {
        // Update an existing norm
        updated = {
          ...norms,
          jsonData: norms.jsonData.map((item, index) =>
            item.indicator_id === norm['indicator_id'] &&
            item.indicator_identifier === norm['indicator_identifier']
              ? {
                  ...item,
                  indicator_identifier: state['indicator_identifier'],
                  indicator_id: +state['indicator_id'],
                  indicator_name: state['indicator_name'],
                  likert_mean: +state['likert_mean'],
                  likert_sd: +state['likert_sd'],
                  updatedAt: moment(),
                  updatedBy: user?.user_id,
                }
              : item
          ),
        };
      } else {
        // Add a new norm
        updated = {
          ...norms,
          jsonData: [
            {
              indicator_identifier: state['indicator_identifier'],
              indicator_id: +state['indicator_id'],
              indicator_name: state['indicator_name'],
              likert_mean: +state['likert_mean'],
              likert_sd: +state['likert_sd'],
            },
            ...norms.jsonData,
          ],
        };
      }

      const res = await fetch(`/api/scoring-constants/${norms?.constId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updated),
      });

      if (!res.ok) {
        throw new Error('Error in submission');
      }

      setNorms(updated);
      setState(initialState);
      handleCloseModal();
    } catch (e) {
      console.log('error,', e);
    } finally {
      setSubmiting(false);
    }
  };

  useEffect(async () => {
    if (norm?.updatedBy) {
      const response = await request({
        method: 'get',
        url: `/api/users/${norm.updatedBy}`,
        responseType: 'json',
      });
      const updatedBy = response?.data?.[0];
      setNormUpdateBy(`${updatedBy['first_name']} ${updatedBy['last_name']}`);
    }
    if (norm?.createdBy) {
      const response = await request({
        method: 'get',
        url: `/api/users/${norm?.createdBy}`,
        responseType: 'json',
      });
      const createdBy = response?.data?.[0];

      setNormCreatedBy(`${createdBy['first_name']} ${createdBy['last_name']}`);
    }
  }, [norm]);

  const isFormChanged =
    norm.likert_mean != state.likert_mean || norm.likert_sd != state.likert_sd;

  return (
    <EditModal
      title={`${norm ? 'Update' : 'Add'} Norm`}
      handleCancel={handleCancel}
    >
      <BasicTextInput
        title={'Likert Mean'}
        placeholder={'Enter Likert Mean'}
        name={'likert_mean'}
        value={state.likert_mean}
        onChange={handlechange}
        style={{ mb: 1 }}
        type={'number'}
      />
      <BasicTextInput
        title={'Likert SD'}
        placeholder={'Enter Likert SD'}
        name={'likert_sd'}
        value={state.likert_sd}
        onChange={handlechange}
        style={{ mb: 1 }}
        type={'number'}
      />
      <Box sx={{ width: '100%' }}>
        <Typography
          id="modal-modal-sub-title"
          variant="subtitle1"
          sx={{
            pt: 0.5,
            color: 'rgba(17, 17, 17, 0.7)',
          }}
        >
          {norm?.updatedAt ? (
            <>
              Last edited on{' '}
              <b>{moment(norm?.updatedAt).format('MM/DD/yyyy')}</b>
              {normUpdateBy ? (
                <>
                  {' '}
                  by <b>{normUpdateBy}</b>
                </>
              ) : null}
            </>
          ) : null}
        </Typography>
        <Typography
          id="modal-modal-sub-title"
          variant="subtitle1"
          sx={{
            pt: 0.5,
            color: 'rgba(17, 17, 17, 0.7)',
          }}
        >
          {norm?.createdAt ? (
            <>
              Created at <b>{moment(norms?.createdAt).format('MM/DD/yyyy')}</b>
              {normCreatedBy ? (
                <>
                  {' '}
                  by <b>{normCreatedBy}</b>
                </>
              ) : null}
            </>
          ) : null}
        </Typography>
      </Box>

      <Box
        sx={{
          mb: 2,
          mt: 2,
          display: 'inline-flex',
          justifyContent: 'center',
          width: '100%',
          mb: 0,
        }}
      >
        <Button
          variant="outlined"
          onClick={handleCancel}
          sx={{
            mr: 2,
            width: '40%',
            maxWidth: '250px',
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!isFormChanged}
          variant="contained"
          // onClick={handleSubmit}
          onClick={handleClick}
          aria-describedby={id}
          sx={{
            mr: 2,
            width: '40%',
            maxWidth: '250px',
          }}
        >
          {submiting ? 'Loading...' : 'Update'}
        </Button>
        <ConfirmationPopup
          id={id}
          openPopover={openPopover}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      </Box>
    </EditModal>
  );
};

const ConfirmationPopup = ({
  id,
  openPopover,
  handleClose,
  handleSubmit,
  anchorEl,
}) => {
  return (
    <Popover
      id={id}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          pr: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ p: 2, fontWeight: 'bold' }}>Confirmation</Typography>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
      </Box>
      <Divider></Divider>
      <Typography sx={{ p: 2 }}>
        Are you sure you want to save your changes?
      </Typography>
      <Box
        sx={{
          pb: 2,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleClose}> Close </Button>
        <Button
          sx={{
            mr: 2,
            ml: 2,
            borderRadius: '20px',
          }}
          onClick={handleSubmit}
          variant="contained"
        >
          Yes, save changes
        </Button>
      </Box>
    </Popover>
  );
};
export default UpdateNormModal;
